import React, { Component } from 'react';
import routeMenuOption from '@/router/router.menu';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import * as AntdIcon from '@ant-design/icons';

const SubMenu = Menu.SubMenu;
let UN_LISTEN;
class Index extends Component {
  rootSubmenuKeys = [
    'source',
    'bag',
    'log',
    'category',
    'shop',
    'member',
    'notice',
    'engine',
    'navigation',
    'cool',
  ];

  state = {
    controller: '',
    action: '',
    defaultOpenKeys: [window.location.hash.split('/')[1]],
    openKeys: '',
    selectedKeys: '',
  };

  componentDidMount() {
    this.setSelectedKeys(this.props.history.location);
    UN_LISTEN = this.props.history.listen((location) => {
      this.setSelectedKeys(location);
    });
  }

  componentWillUnmount() {
    UN_LISTEN && UN_LISTEN();
  }

  setSelectedKeys = (location) => {
    routeMenuOption.forEach((item) => {
      item.route.forEach((rt) => {
        if (rt.reg) {
          if (rt.reg.test(location.pathname + location.search)) {
            // 正则模式
            this.setState({
              openKeys: [item.name],
              selectedKeys: [rt.path],
            });
          }
        } else {
            if (rt.Highlight) {
                if (rt.Highlight.includes(location.pathname)) {
                    this.setState({
                        openKeys: [item.name],
                        selectedKeys: [rt.path],
                      });
                }
            } else {
                if (`/${rt.path}` === location.pathname) {
                    this.setState({
                      openKeys: [item.name],
                      selectedKeys: [rt.path],
                    });
                  }
            }
        }
      });
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  getSubMenu = (sub_item) => {
    return sub_item.map((item) => {
      return (
        <Menu.Item key={item.path}>
          <Link to={`/` + item.path}>
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      );
    });
  };

  getSliderMenu = () => {
    return routeMenuOption.map((item) => {
      const icon = React.createElement(AntdIcon[item.icon]);
      return (
        <SubMenu
          key={item.name}
          title={
            <span>
              <span>{item.content}</span>
            </span>
          }
          icon={icon}
        >
          {this.getSubMenu(item.route)}
        </SubMenu>
      );
    });
  };

  render() {
    let defaultSelectedKeys =
      window.location.hash.split('/')[1] +
      '/' +
      window.location.hash.split('/')[2];
    if (window.location.hash.split('/')[3]) {
      defaultSelectedKeys += '/' + window.location.hash.split('/')[3];
    }

    return (
      <Menu
        defaultSelectedKeys={[defaultSelectedKeys]}
        defaultOpenKeys={this.state.defaultOpenKeys}
        mode="inline"
        theme="dark"
        openKeys={this.state.openKeys}
        selectedKeys={this.state.selectedKeys}
        onOpenChange={this.onOpenChange}
      >
        {this.getSliderMenu()}
      </Menu>
    );
  }
}

export default withRouter(Index);
