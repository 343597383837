import React, { Component } from "react";
import { Table, Button, Form, message, Modal, InputNumber } from "antd";
import {
    ExclamationCircleOutlined,
    FileTextOutlined,
    EditOutlined,
    SortAscendingOutlined,
    SettingOutlined,
    CheckOutlined,
} from "@ant-design/icons";
import {
    REMOVE_SPECIAL_BOOKMARK,
    GET_SPECIAL_BOOKMARK,
    SPECIAL_BOOKMARK_SORT,
    GET_SPECIAL_CATEGORY_MAP,
} from "@/api/cool";
import moduleCss from "@/pages/Source/style/index.module.css";
import coolCss from "./style/index.module.css";
import QueryString from "query-string";
import EditSpecialBookmarkModal from "@/pages/Cool/components/EditSpecialBookmarkModal";
import SpecialCategoryManageModal from "@/pages/Cool/components/SpecialCategoryManageModal";
import SpecialChooseSourceModal from "@/pages/Cool/components/SpecialChooseSourceModal";
import AddSource from "../Source/components/AddSource";
import IconAdd from "../Source/icon/Add";
const FormItem = Form.Item;
const { confirm } = Modal;

/**
 * 全部酷站网址列表
 */
class Index extends Component {
    state = {
        category: [],
        activeCategory: 0,
        sourceList: [],

        loading: false,
        hasUpdateSort: false,

        specialId: "",

        addRemarkVisible: false,
        addRemarkId: "",
        sourceAmount: 0,
        // icon
        editIconId: "",
        editIconVisible: false,
        // 分类管理
        manageCategoryVisible: false,
        // 选择网址
        chooseSourceVisible: false,
    };

    componentDidMount() {
        const queryParams = QueryString.parse(this.props.location.search);
        this.setState({ specialId: queryParams.id }, () => {
            this.getCategoryListData().then((list) => {
                if (!list.length) return;
                this.handleMenuChange(list[0].categoryId);
            });
        });
    }

    // 获取一级分类列表
    getCategoryListData = () => {
        this.setState({ loading: true });
        return new Promise((resolve) => {
            GET_SPECIAL_CATEGORY_MAP(this.state.specialId)
                .then((rsp) => {
                    this.setState(
                        {
                            category: rsp.data.list,
                        },
                        () => {
                            resolve(rsp.data.list);
                        }
                    );
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    };

    // 切换一级分类
    handleMenuChange = (cid) => {
        this.setState(
            {
                activeCategory: cid,
            },
            () => {
                this.getBookmarkListData();
            }
        );
    };

    // 从酷站中移除该网址
    handleRemove = (id) => {
        confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否从专题分类移除该网址？",
            onOk: () => {
                REMOVE_SPECIAL_BOOKMARK(id).then((response) => {
                    if (response.data.code === 1) {
                        this.getBookmarkListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    // 当用户修改sort时候，重新设置列表的sort
    handleSortChange = (sort, id) => {
        const sourceList = this.state.sourceList;
        const item = sourceList.find((f) => f.id === id);
        item.sort = sort;
        this.setState({
            sourceList,
            hasUpdateSort: true,
        });
    };

    // 获取分类下的网址数据
    getBookmarkListData = () => {
        this.setState({ loading: true });
        GET_SPECIAL_BOOKMARK(this.state.activeCategory)
            .then((rsp) => {
                this.setState({
                    sourceList: rsp.data.list,
                    sourceAmount: rsp.data.list.length,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 打开备注弹框
    handleRemarkModalOpen = (id) => {
        this.setState({ addRemarkVisible: true, addRemarkId: id });
    };

    // 关闭添加、编辑网址弹框
    handleRemarkModalClose = () => {
        this.setState({ addRemarkVisible: false });
    };

    showAddCategoryModal = () => {
        this.setState({
            addCategoryVisible: true,
        });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.sourceList.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SPECIAL_BOOKMARK_SORT(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getBookmarkListData();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    };

    // 打开编辑网址弹框
    handleAddSourceOpen = (sid) => {
        this.setState({ editVisible: true, editId: sid });
    };

    // 关闭添加、编辑网址弹框
    handleAddSourceClose = () => {
        this.setState({ editVisible: false });
    };

    // 编辑Icon
    handleIconEdit = (id) => {
        this.setState({ editIconVisible: true, editIconId: id });
    };
    // 关闭icon编辑弹框
    handleIconClose = () => {
        this.setState({ editIconVisible: false });
    };

    // 分类管理
    showManageCategoryModal = () => {
        this.setState({
            manageCategoryVisible: true,
        });
    };

    hideManageCategoryModal = () => {
        this.setState({
            manageCategoryVisible: false,
        });
    };

    handleManageCategoryReload = () => {
        this.getCategoryListData();
        // this.getBookmarkListData();
    };

    // 选择网址弹框
    showChooseSourceModal = () => {
        this.setState({
            chooseSourceVisible: true,
        });
    };

    hideChooseSourceModal = () => {
        this.setState({
            chooseSourceVisible: false,
        });
    };

    render() {
        const columns = [
            { title: "SID", dataIndex: "sid", width: 100 },
            {
                title: "图标",
                dataIndex: "icon",
                width: 70,
                render: (src, record) => {
                    return (
                        <div
                            className="table-cover"
                            title="更换图标"
                            onClick={() => this.handleIconEdit(record.iconId)}
                        >
                            <img width="32" src={src} alt="" />
                        </div>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                render: (name, record) => {
                    return (
                        <div>
                            <p>
                                {name || record.baseName}
                                <span
                                    className={moduleCss.nameEditBtn}
                                    style={{ paddingRight: "10px" }}
                                    onClick={() => this.handleAddSourceOpen(record.sid)}
                                >
                                    &nbsp;
                                    <EditOutlined />
                                </span>
                                <span
                                    className={moduleCss.nameEditBtn}
                                    onClick={() => this.handleRemarkModalOpen(record.id)}
                                >
                                    &nbsp;
                                    <FileTextOutlined />
                                </span>
                            </p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}

                            <p style={{ marginBottom: 0, color: "#666" }}>{record.createTime}</p>
                        </div>
                    );
                },
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.summary || record.baseSummary}</p>
                            <p style={{ marginBottom: 0 }}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            { title: "点击", dataIndex: "clickAmount", width: 120 },
            {
                title: "排序",
                dataIndex: "sort",
                render: (sort, record) => (
                    <InputNumber
                        key={record.id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.id)}
                    />
                ),
            },
            {
                title: "操作",
                width: 60,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleRemove(record.id)} danger>
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <SpecialChooseSourceModal
                    visible={this.state.chooseSourceVisible}
                    cid={this.state.activeCategory}
                    onReload={this.getBookmarkListData}
                    onClose={this.hideChooseSourceModal}
                />
                <EditSpecialBookmarkModal
                    visible={this.state.addRemarkVisible}
                    id={this.state.addRemarkId}
                    onClose={this.handleRemarkModalClose}
                    onReload={this.getBookmarkListData}
                />

                <SpecialCategoryManageModal
                    visible={this.state.manageCategoryVisible}
                    specialId={this.state.specialId}
                    onClose={this.hideManageCategoryModal}
                    onReload={this.handleManageCategoryReload}
                />

                <AddSource
                    visible={this.state.editVisible}
                    sid={this.state.editId}
                    onReload={this.getBookmarkListData}
                    onClose={this.handleAddSourceClose}
                />
                <IconAdd
                    id={this.state.editIconId}
                    visible={this.state.editIconVisible}
                    onReload={this.getBookmarkListData}
                    onClose={this.handleIconClose}
                />

                <Form layout="inline" className="mb-15 search-form-fixed">
                    <FormItem>
                        <Button type="primary" onClick={this.showManageCategoryModal} icon={<SettingOutlined />}>
                            管理分类
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button onClick={this.showChooseSourceModal} icon={<CheckOutlined />}>
                            选择网址
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button onClick={this.handleUpdateSort} icon={<SortAscendingOutlined />}>
                            {this.state.hasUpdateSort ? "更新排序" : "整理排序"}
                        </Button>
                    </FormItem>
                    <FormItem>
                        <span>共计 {this.state.sourceAmount} 条记录</span>
                    </FormItem>
                </Form>

                <div className={coolCss.specialDetailWrap}>
                    <div className={coolCss.specialDetailSidebar}>
                        <ul className={coolCss.specialDetailSidebarInner}>
                            {this.state.category.map((item) => {
                                return (
                                    <li
                                        className={`${
                                            item.categoryId === this.state.activeCategory
                                                ? coolCss.specialDetailSidebarItemActive
                                                : ""
                                        } ${coolCss.specialDetailSidebarItem}`}
                                        onClick={() => this.handleMenuChange(item.categoryId)}
                                    >
                                        {item.remark || item.title}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <Table
                        size="small"
                        className={coolCss.specialDetailMain}
                        rowKey={(record) => record.id}
                        pagination={false}
                        columns={columns}
                        dataSource={this.state.sourceList}
                        bordered
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default Index;
