import React from 'react';
import {Form, message, Input, Modal} from 'antd';
import {ADD_CATEGORY_BAG} from '@/api/category';

const FormItem = Form.Item;

class AddForm extends React.PureComponent {
    formRef = React.createRef();
    state = {
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) return false;
            this.formRef.current.resetFields();
            if (this.props.bid) {
                this.formRef.current.setFieldsValue({
                    name: this.props.bagName,
                });
            }
        }
    };

    handleCancel = () => {
        this.props.onClose();
    };

    handleOk = () => {
        this.formRef.current.submit();
    };

    handleSubmit = (values) => {
        ADD_CATEGORY_BAG(this.props.cid, values.name, this.props.bid).then(() => {
            message.success('保存成功').then(r => {
            });
            this.props.onReload();
            this.handleCancel();
        });
    };

    render() {

        const layout = {
            labelCol: {
                span: 4,
            }, wrapperCol: {
                span: 20,
            },
        };

        return (<Modal
            title="添加笔袋"
            open={this.props.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
        >
            <Form ref={this.formRef} onFinish={this.handleSubmit} {...layout}>
                <FormItem name="name" label="笔袋名称"
                    rules={[{required: true, message: '名称必须填写'}]}>
                    <Input placeholder="笔袋名称"/>
                </FormItem>
            </Form>
        </Modal>);
    }
}

export default AddForm;
