import React, { Component } from "react";
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table } from "antd";
import { GET_STOP_WORDS_LIST } from "@/api/homepage";
import AddStopWords from "@/pages/Homepage/components/AddStopWords";

const FormItem = Form.Item;

class StopWords extends Component {

  state = {
    list: [],
    pagination: {
      current: 1,
      total: 0,
      pageSize: 0,
      showQuickJumper: true
    },
    search: {
      word: ''
    },
    loading: false,
    addVisible: '', // 添加停用词弹框是否显示
    modifyData: {
      word: '',
      remark: ''
    }
  }

  componentDidMount() {
    this.getStopWordListData();
  }

  getStopWordListData = () => {
    GET_STOP_WORDS_LIST({
      word: this.state.search.word,
      page: this.state.pagination.current
    }).then(rsp => rsp.data).then(data => {
      const pagination = this.state.pagination;
      pagination.pageSize = data.size;
      pagination.total = data.total;
      this.setState({
        list: data.list,
        pagination
      });
    })
  }

  // 搜索停用词
  handleSearch = (values) => {
    const pager = this.state.pagination;
    pager.current = 1;
    this.setState({
      search: values,
      pagination: pager,
    }, () => this.getStopWordListData());
  }

  handleEdit = (record) => {
    this.setState({
      addVisible: true,
      modifyData: record ?? {}
    })
  }

  handleAddCancel = () => {
    this.setState({
      addVisible: false
    })
  }

  handleTableChange = (pagination) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, () => this.getStopWordListData());
  };

  render() {

    const columns = [
      { title: 'ID', dataIndex: 'id' },
      { title: '停用词', dataIndex: 'word' },
      { title: '备注', dataIndex: 'remark' },
      {
        title: '操作', render: (record) => {
          return (<div className="table-action-field">
            <Button type="link" onClick={() => this.handleEdit(record)}>编辑</Button>
          </div>);
        }
      }
    ];

    return (
      <div>
        <AddStopWords
          visible={this.state.addVisible}
          word={this.state.modifyData.word}
          remark={this.state.modifyData.remark}
          onCancel={this.handleAddCancel}
          onFinish={this.getStopWordListData}
        />
        <Form layout="inline" className="mb-15" onFinish={this.handleSearch}>
          <FormItem name="word">
            <Input placeholder="请输入停用词名称"/>
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined/>}
            >
              搜索
            </Button>
          </FormItem>
          <FormItem>
            <Button icon={<PlusOutlined/>} onClick={() => this.handleEdit(null)}>添加</Button>
          </FormItem>
        </Form>
        <div className="bg-white pd-10">
          <Table
            rowKey={record => record.id}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            columns={columns}
            dataSource={this.state.list}
            bordered
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

export default StopWords;
