import React, {Component} from 'react';
import {Table, Button, Tag, Form, Input} from 'antd';
import {QqOutlined, WeiboOutlined, GithubOutlined, MailOutlined, WechatOutlined, PlusOutlined} from '@ant-design/icons';
import SendNotice from '../Notice/Add';
import AddUser from "@/pages/Member/components/AddUser";
import {GET_MEMBER_LIST} from '@/api/member';
import {getQiNiuAvatar} from "@/utils";

const FormItem = Form.Item;

class Index extends Component {
    state = {
        lists: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            showQuickJumper: true,
        },
        iconLoading: false,
        search: {},
        loading: false,
        // 编辑
        editVisible: false,
        editUid: 0
    };

    componentDidMount() {
        this.getLists();
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    getLists = () => {
        this.setState({loading: true});
        const data1 = this.state.search;
        data1.page = this.state.pagination.current;
        GET_MEMBER_LIST(data1).then((rsp) => {
            const data = rsp.data;
            const pager = this.state.pagination;
            pager.total = data.total;
            pager.pageSize = data.size;
            const list = data.lists.map((item) => {
                item.user_headimg = getQiNiuAvatar(item.user_headimg);
                return item;
            });
            this.setState({
                lists: list,
                pagination: pager,
                loading: false,
            });
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys: [],
        });

        window.scrollTo(0, 0);
        this.getLists();
    };

    handleSubmit = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState(
            {
                search: values,
                pagination: pager,
                selectedRowKeys: [],
            },
            () => this.getLists()
        );
    };

    onNoticeRef = (ref) => {
        this.notice_ref = ref;
    };

    sendNotice(user_id) {
        this.notice_ref.showModal(user_id);
    }

    // 打开编辑弹框
    handleEditOpen = (uid) => {
        this.setState({editVisible: true, editId: uid});
    }

    // 打开编辑弹框
    handleEditClose = () => {
        this.setState({editVisible: false});
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            {title: '用户ID', dataIndex: 'user_id', width: 80},
            {
                title: '用户头像',
                dataIndex: 'user_headimg',
                render: (src) => <img width="32" src={src} alt=""/>,
            },
            {
                title: '用户昵称', dataIndex: 'user_nickname', render: (nickname, record) => {
                    switch (record.type) {
                        case 1:
                            return <Tag icon={<WeiboOutlined/>} color="red">{nickname}</Tag>;
                        case 2:
                            return <Tag icon={<GithubOutlined/>} color="purple">{nickname}</Tag>;
                        case 3:
                            return <Tag icon={<QqOutlined/>} color="blue">{nickname}</Tag>;
                        case 4:
                            return <Tag icon={<WechatOutlined/>} color="green">{nickname}</Tag>
                        default:
                            return <Tag icon={<MailOutlined/>} color="orange">{nickname}</Tag>;
                    }
                }
            },
            {title: '注册邮箱', dataIndex: 'user_email'},
            {
                title: '注册时间',
                dataIndex: 'register_time',
                render: (txt, record) => {
                    if (record.is_today) {
                        return <Tag color="red">{txt}</Tag>;
                    } else if (record.is_yesterday) {
                        return <Tag color="blue">{txt}</Tag>;
                    } else {
                        return txt;
                    }
                },
            },
            {title: '最后活跃', dataIndex: 'last_live_time'},
            {
                title: '操作',
                render: (txt, record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleEditOpen(record.user_id)}>编辑</Button>
                            <Button type="link" onClick={() => this.sendNotice(record.user_id)}>发消息</Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <SendNotice onRef={this.onNoticeRef}/>
                <AddUser id={this.state.editId} visible={this.state.editVisible} onClose={this.handleEditClose}
                         onReload={this.getLists}/>
                <Form layout="inline" className="mb-15" onFinish={this.handleSubmit}>
                    <FormItem name="user_id">
                        <Input placeholder="用户ID" allowClear/>
                    </FormItem>
                    <FormItem name="user_nickname">
                        <Input placeholder="用户昵称"/>
                    </FormItem>
                    <FormItem name="user_email">
                        <Input placeholder="注册邮箱"/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button icon={<PlusOutlined/>} onClick={() => this.handleEditOpen(null)}>
                            添加新用户
                        </Button>
                    </FormItem>
                </Form>
                <div className="bg-white pd-10">
                    <Table
                        rowKey={(record) => record.user_id}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={this.state.lists}
                        bordered
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default Index;
