import React, { Component } from "react";
import { Table, Button, Form, message, InputNumber, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { DELETE_SPECIAL, GET_SPECIAL_LIST, SPECIAL_SORT } from "@/api/cool";
import AddSpecialModal from "@/pages/Cool/components/AddSpecialModal";
import { Link } from "react-router-dom";

const FormItem = Form.Item;
const { confirm } = Modal;

/**
 * 发现 - 专题列表
 */
class Index extends Component {
    state = {
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
            size: "default",
        },
        search: {},
        loading: false,
        addModalVisible: false,
        editModelId: "",
        hasUpdateSort: false,
    };

    componentDidMount() {
        this.getSpecialListData();
    }

    // 获取专题列表
    getSpecialListData = () => {
        this.setState({ loading: true });
        const { current, pageSize } = this.state.pagination;
        GET_SPECIAL_LIST(current, pageSize)
            .then((rsp) => {
                this.setState({
                    list: rsp.data.list,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getSpecialListData();
    };

    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState(
            {
                search: values,
                pagination: pager,
            },
            () => this.getSpecialListData()
        );
    };

    handleEdit = (id) => {
        this.setState({
            editModelId: id,
            addModalVisible: true,
        });
    };

    // 删除专题
    handleDelete = (id) => {
        confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否删除当前专题？",
            onOk: () => {
                DELETE_SPECIAL(id).then((response) => {
                    if (response.data.code === 1) {
                        this.getSpecialListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    // 打开添加专题弹框
    showAddSpecialModal = () => {
        this.setState({
            editModelId: "",
            addModalVisible: true,
        });
    };

    handleAddModalClose = () => {
        this.setState({
            addModalVisible: false,
        });
    };

    // 处理修改sort输入框数字操作
    handleSortChange = (sort, id) => {
        const list = this.state.list;
        const item = list.find((f) => f.id === id);
        item.sort = sort;
        this.setState({
            list,
            hasUpdateSort: true,
        });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SPECIAL_SORT(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getSpecialListData();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            { title: "专题ID", dataIndex: "id", width: 100 },
            {
                title: "名称",
                dataIndex: "title",
                render: (title, record) => {
                    return (
                        <Link target="_blank" to={`/cool/special-detail?id=${record.id}`}>
                            {title}
                        </Link>
                    );
                },
            },
            {
                title: "domain",
                dataIndex: "domain",
            },
            {
                title: "图标",
                dataIndex: "icon",
            },
            {
                title: "配色",
                dataIndex: "color",
            },
            {
                title: "背景图",
                dataIndex: "backgroundImage",
                render: (src) => {
                    return src ? <img width="80" src={src} alt="" /> : null;
                },
            },
            {
                title: "创建时间",
                dataIndex: "createTime",
            },
            {
                title: "排序",
                dataIndex: "sort",
                render: (sort, record) => (
                    <InputNumber
                        key={record.id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.id)}
                    />
                ),
            },
            {
                title: "操作",
                width: 60,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleEdit(record.id)}>
                                编辑
                            </Button>
                            <Button type="link" onClick={() => this.handleDelete(record.id)} danger>
                                删除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <AddSpecialModal
                    visible={this.state.addModalVisible}
                    id={this.state.editModelId}
                    onReload={this.getSpecialListData}
                    onClose={this.handleAddModalClose}
                />
                <Form layout="inline" onFinish={this.handleSearch} className="mb-15 search-form-fixed">
                    {/*<FormItem name="wd">
                        <Input placeholder="请输入名称 / URL"/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                            搜索
                        </Button>
                    </FormItem>*/}
                    <FormItem>
                        <Button type="primary" onClick={this.showAddSpecialModal} icon={<PlusOutlined />}>
                            添加专题
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button onClick={this.handleUpdateSort} icon={<SortAscendingOutlined />}>
                            {this.state.hasUpdateSort ? "更新排序" : "整理排序"}
                        </Button>
                    </FormItem>
                </Form>

                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    rowSelection={rowSelection}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default Index;
