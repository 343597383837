import React, { Component } from "react";
import { Form, Input, Modal } from "antd";
import PropTypes from "prop-types";
import { CRAWLER_SOURCE_INFO } from "@/api/source";
import { SAVE_GROUP_DATA } from "@/api/common";

const FormItem = Form.Item;

/**
 * 编辑、添加网址
 * @class Add
 * @extends {Component}
 */
class AddSource extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        crawlerLoading: false,
        crawlerInfo: {
            name: "",
            summary: "",
        },
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                const { title, icon } = this.props.data;
                this.formRef.current.setFieldsValue({
                    title,
                    icon,
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    };

    // 提交保存数据
    handleSubmit = (values) => {
        const { title, icon } = values;
        this.setState({ loading: true });
        SAVE_GROUP_DATA(this.props.id, title, icon)
            .then((rsp) => {
                this.props.onReload();
                this.props.onClose();
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    handleEnterKeyUp = (e) => {
        // Enter
        if (e.keyCode === 13) {
            this.formRef.current.submit();
        }
    };

    crawlerWebInfo = () => {
        this.setState({ crawlerLoading: true });
        const url = this.formRef.current.getFieldValue("sour_url");
        CRAWLER_SOURCE_INFO(url)
            .then((rsp) => {
                this.setState({
                    crawlerInfo: {
                        name: rsp.data.name,
                        summary: rsp.data.description,
                    },
                });
            })
            .finally(() => {
                this.setState({ crawlerLoading: false });
            });
    };

    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        return (
            <div>
                <Modal
                    style={{ top: 20 }}
                    title="添加/编辑分组"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onKeyUp={this.handleEnterKeyUp} onFinish={this.handleSubmit}>
                        <FormItem
                            name="title"
                            label="标题"
                            rules={[
                                { required: true, message: "必须填写标题" },
                                { max: 20, message: "标题不能超过20个字" },
                            ]}
                        >
                            <Input placeholder="标题" allowClear />
                        </FormItem>
                        <FormItem
                            name="icon"
                            label="图标"
                            rules={[
                                { required: true, message: "必须填写图标" },
                                {
                                    max: 50,
                                    message: "图标不能超过50个字",
                                },
                            ]}
                        >
                            <Input placeholder="图标" allowClear />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

AddSource.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default AddSource;
