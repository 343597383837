import React from 'react';
import {Form, message, Modal, Row, Col, List, Button, Table, Tag} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {GET_CATEGORY, ADD_COLLECT} from '@/api/source';

const FormItem = Form.Item;

class Collect extends React.PureComponent {
    state = {
        activeModule: 'website',
        list: [],
        sour_id: '',
        loading: false,
        submitLoading: false,
        selectedRowKeys: [],
    };

    componentDidUpdate(prevPros) {
        if (prevPros.visible !== this.props.visible) {
            if (this.props.visible) {
                this.getCategoryData();
            }
        }
    }

    // 获取分类列表
    getCategoryData = () => {
        GET_CATEGORY(this.state.activeModule, this.props.sourceId).then((rsp) => {
            this.setState({
                loading: false,
                list: rsp.data.list,
            });
        });
    };

    handleSubmit = () => {
        if (this.state.selectedRowKeys.length <= 0) {
            message.error('请选择笔袋');
            return false;
        }
        this.setState({submitLoading: true});
        ADD_COLLECT(this.props.sourceId, this.state.selectedRowKeys).then((rsp) => {
            message.success('收藏成功');
            this.setState({
                selectedRowKeys: [],
                submitLoading: false,
            });
            this.props.onClose();
        });
    };

    handleCancel = () => {
        this.props.onClose();
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    // 切换模块，重新加载模块下的笔袋
    handleModuleChange(module) {
        this.setState(
            {
                loading: true,
                selectedRowKeys: [],
                activeModule: module,
            },
            () => {
                this.getCategoryData();
            }
        );
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {title: 'BagID', dataIndex: 'bag_id', width: 100},
            {title: '名称', dataIndex: 'bag_name'},
            {
                title: '是否收藏',
                dataIndex: 'collect',
                render(collect) {
                    return collect ? <Tag color="red">已收藏</Tag> : <Tag>否</Tag>;
                },
            },
        ];

        const moduleArr = [
            {name: '常用网址', module: 'website'},
            {name: '导航', module: 'nav'},
            {name: '其他', module: 'other'},
        ];

        return (
            <div>
                <Modal
                    title="收藏网址"
                    footer={null}
                    width="70%"
                    style={{top: 20}}
                    visible={this.props.visible}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.submitLoading}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <List
                                className="sub-category"
                                bordered
                                dataSource={moduleArr}
                                renderItem={(info) => {
                                    return (
                                        <List.Item
                                            onClick={() => this.handleModuleChange(info.module)}
                                            className={
                                                info.module === this.state.activeModule ? 'active' : ''
                                            }
                                        >
                                            {info.name}
                                        </List.Item>
                                    );
                                }}
                            />
                        </Col>
                        <Col span={20}>
                            <Form layout="inline" className="mb-15">
                                <FormItem>
                                    <Button
                                        loading={this.state.submitLoading}
                                        type="primary"
                                        onClick={this.handleSubmit}
                                        icon={<CheckCircleOutlined/>}
                                    >
                                        立即保存
                                    </Button>
                                </FormItem>
                            </Form>
                            <Table
                                rowKey="bag_id"
                                rowSelection={rowSelection}
                                columns={columns}
                                pagination={false}
                                dataSource={this.state.list}
                                bordered
                                loading={this.state.loading}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

Collect.propTypes = {
    onReload: PropTypes.func,
};

export default Collect;
