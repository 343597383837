import request from '@/api/request';

// 收集到酷站
export const GET_POST_LIST = ({page = 1, size = 30, status = ''}) => {
    return request.get('/post/list', {page, size, status});
};

// 收集到酷站
export const GET_POST_DETAIL = (postId) => {
    return request.get('/post/detail', {postId});
};

export const REVIEW_POST = (postId, status) => {
    return request.post('/post/review', {postId, status});
};

