import React, {Component} from 'react';
import {Table, Button, Form, message, InputNumber, Modal} from 'antd';
import {PlusOutlined, SortAscendingOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import styleCss from './style.module.css';
import { GET_CATEGORY_LIST} from "@/api/category";
import {DELETE_POST, GET_HELP_LIST, SAVE_SORT} from "@/api/help";
import {FRONTEND_HOST} from '@/config/config';
import QueryString from "query-string";

const FormItem = Form.Item;

/**
 * 帮助中心文章管理
 */
class Index extends Component {
    state = {
        category: [],
        subMenus: [],
        activeCategory: '',
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 50,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`
        },
        search: {
            name: '',
            page: 1,
            sour_id: '',
            sour_url: '',
            summary: ''
        },
        loading: false,
        hasUpdateSort: false
    };

    componentDidMount() {
        const queryParams = QueryString.parse(this.props.location.search);
        this.setState({
            activeCategory: queryParams.cid ? Number(queryParams.cid) : ''
        }, () => {
            this.getHelpCategoryList();
        })
    }

    getHelpCategoryList = () => {
        GET_CATEGORY_LIST({alias: 'help_center'}).then(rsp => rsp.data.list).then(data => {
            this.setState({
                category: data,
                activeCategory: this.state.activeCategory || data[0].cate_id
            }, () => {
                this.getListData();
            })
        })
    }

    handleMenuChange = (cid) => {
        this.setState({
            activeCategory: cid
        }, () => {
            this.getListData()
        })
    }

    // 获取帮助文章列表
    getListData = () => {
        this.setState({loading: true});
        const searchData = this.state.search;
        searchData.page = this.state.pagination.current;
        searchData.cid = this.state.activeCategory;

        GET_HELP_LIST(this.state.activeCategory).then((response) => {
            this.setState({
                list: response.data.list
            })
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys: [],
        });

        window.scrollTo(0, 0);
        this.getListData();
    };

    // 搜索
    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState({search: values, pagination: pager}, () => {
            this.getListData();
        });
    };

    // 添加新网址
    handleAdd = () => {
        window.open(`/#/help/write`, '_self');
    };

    handleEdit = (id) => {
        window.open(`/#/help/write?id=${id}`, '_self');
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    // 处理修改sort输入框数字操作
    handleSortChange = (sort, id) => {
        const list = this.state.list;
        list.forEach((item, index) => {
            if (parseInt(item.id, 10) === parseInt(id, 10)) {
                list[index].sort = sort;
                this.setState({
                    list,
                    hasUpdateSort: true,
                });
            }
        });
    };

    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SAVE_SORT(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                message.success('排序成功！');
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    }

    handleDelete = (id) => {
        Modal.confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '确认删除该文章？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                DELETE_POST(id).then(() => {
                    this.getListData();
                    message.success('删除成功');
                });
            },
        });
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {title: 'ID', dataIndex: 'id', width: 100},
            {
                title: '名称',
                dataIndex: 'title',
                render: (title, record) => {
                    return <a href={FRONTEND_HOST + '/help/detail/' + record.id} target="_blank">{title}</a>
                }
            },
            {
                title: '排序',
                dataIndex: 'sort',
                render: (sort, record) => (
                    <InputNumber
                        key={record.id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.id)}
                    />
                ),
            },
            {
                title: '操作',
                width: 190,
                render: (record) => {
                    return <div>
                        <Button type="link" onClick={() => this.handleEdit(record.id)}>
                            编辑
                        </Button>
                        <Button danger type="link" onClick={() => this.handleDelete(record.id)}>
                            删除
                        </Button>
                    </div>
                },
            },
        ];

        return (
            <div>
                <Form layout="inline" className="search-form-fixed" onFinish={this.handleSearch}>
                    <FormItem>
                        <Button icon={<PlusOutlined/>} type="primary" onClick={this.handleAdd}>
                            添加文章
                        </Button>
                    </FormItem>
                    <Form.Item>
                        <Button
                            onClick={this.handleUpdateSort}
                            icon={<SortAscendingOutlined/>}
                        >
                            {this.state.hasUpdateSort ? '更新排序' : '整理排序'}
                        </Button>
                    </Form.Item>
                </Form>
                <div className={styleCss['category-bar']}>
                    <ul className={styleCss['category-bar-content']}>
                        {
                            this.state.category.map(item => {
                                return <li
                                    key={item.cate_id}
                                    className={`${item.cate_id === this.state.activeCategory ? styleCss.categoryBarItemActive : ''} ${styleCss['category-bar-item']}`}
                                    onClick={() => this.handleMenuChange(item.cate_id, item.child)}>{item.name}</li>
                            })
                        }
                    </ul>
                </div>
                <div className="bg-white pd-10">
                    <Table
                        rowKey="id"
                        size="small"
                        rowSelection={rowSelection}
                        loading={this.state.loading}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                    />
                </div>
            </div>
        );
    }
}

export default Index;
