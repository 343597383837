import React, {Component} from "react";
import {Button, Form, Input, message, Modal, Upload} from "antd";
import {ADD_SPECIAL_BOOKMARK_REMARK, GET_SPECIAL_BOOKMARK_DETAIL} from "@/api/cool";

const FormItem = Form.Item;

class Index extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        key: '',
        icon: ''
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                GET_SPECIAL_BOOKMARK_DETAIL(this.props.id).then((rsp) => {
                    const {title, summary, key, icon} = rsp.data;

                    this.setState({
                        key,
                        icon
                    });
                    this.formRef.current.setFieldsValue({
                        title,
                        summary
                    });
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    };

    // 提交保存数据
    handleSubmit = (values) => {
        const {title, summary} = values;
        this.setState({loading: true});
        ADD_SPECIAL_BOOKMARK_REMARK(this.props.id, title, summary, this.state.key).then((rsp) => {
            if (rsp.data.code === 1) {
                this.props.onReload();
                this.props.onClose();
            } else {
                message.error(rsp.data.msg);
            }
        })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
        this.props.onReload();
    };

    render() {
        const layout = {
            labelCol: {
                span: 2,
            },
            wrapperCol: {
                span: 22,
            },
        };

        const upload_props = {
            name: 'UploadCommon[imageFiles]',
            action: '/api/upload/index',
            multiple: false,
            data: { module: 'special-bookmark' },
            showUploadList: false,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if (info.file.response.code === 1) {
                        this.setState({
                            icon: info.file.response.src,
                            key: info.file.response.key,
                        });
                        message.success(`上传成功`);
                    } else {
                        message.success(info.file.response.msg);
                    }
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <Modal
                    title="添加专题"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onFinish={this.handleSubmit}>
                        <FormItem name="title" label="备注" rules={[
                            {max: 30, message: "备注不能超过30个字"},
                        ]}>
                            <Input placeholder="备注"/>
                        </FormItem>
                        <FormItem name="summary" label="描述" rules={[
                            {max: 100, message: "描述不能超过100个字"},
                        ]}>
                            <Input.TextArea rows={4} placeholder="描述"/>
                        </FormItem>
                        <FormItem label="图标">
                            <div>
                                <Upload {...upload_props}>
                                    <Button>上传图标</Button>
                                </Upload>
                                {this.state.icon ? <div className="mt-15">
                                    <img width="40" src={this.state.icon} alt=""/>
                                </div> : ''}
                            </div>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Index;
