import React from 'react';
import {Modal, message, Select} from 'antd';
import PropTypes from 'prop-types';
import {ADD_COOL, ADD_TAGS} from '@/api/cool';
import {GET_TAG_LIST} from "@/api/tags";
import {GET_SOURCE_INFO} from "@/api/source";

const {Option} = Select;
let timeout;

/**
 * 添加网址到酷站模块
 * 目前每个网址只能放入一个酷站分类，所以就是等于给网址添加分类
 * @class AddTags
 * @extends {React.PureComponent}
 */
class AddTags extends React.PureComponent {
  state = {
    tags: [],
    selectOptions: [],
    submitLoading: false,
  };

  componentDidUpdate(prevPros) {
    if ((prevPros.visible !== this.props.visible) && this.props.visible) {
      this.getOriginTagsData(this.props.sourceId);
      this.getTagList({size: 100, selected: 1});
    }
  }

  // 获取原始的标签数据
  getOriginTagsData = (sid) => {
    GET_SOURCE_INFO(sid).then(rsp => {
      const tags = rsp.data.tags.map(item => item);
      this.setState({tags});
    })
  }

  // 获取精选标签
  getTagList = ({wd = '', size = 10, selected = null}) => {
    GET_TAG_LIST({wd, size, selected}).then(rsp => {
      const options = rsp.data.list.map(item => {
        return item.name;
      });
      this.setState({
        selectOptions: options
      })
    })
  }

  handleCancel = () => {
    this.props.onClose();
  };

  handleOk = () => {
    if (!this.state.tags.length) {
      message.error('必须输入标签');
      return false;
    }
    ADD_TAGS({sid: this.props.sourceId, tags: this.state.tags}).then(() => {
      message.success('添加成功');
      this.props.onClose();
      this.props.onReload();
    })
  }

  // 搜索标签
  handleSearch = (wd) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      this.getTagList({wd, size: 100});
    }, 300);
  }

  handleChange = (e) => {
    this.setState({tags: e});
  }

  render() {
    return (
      <div>
        <Modal
          title="添加到酷站..."
          width="500px"
          style={{top: 20}}
          onOk={this.handleOk}
          visible={this.props.visible}
          onCancel={this.handleCancel}
          confirmLoading={this.state.submitLoading}
        >
          <Select
            showSearch
            mode="tags"
            style={{width: '100%'}}
            value={this.state.tags}
            placeholder="添加标签"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
          >
            {
              this.state.selectOptions.map((item, idx) => {
                return <Option value={item} key={idx}>{item}</Option>;
              })
            }
          </Select>
        </Modal>
      </div>
    );
  }
}

AddTags.propTypes = {
  onReload: PropTypes.func,
};

export default AddTags;
