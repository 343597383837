import React, { Component } from "react";
import { Form, Input, message, Modal } from "antd";
import { GET_SPECIAL_CATEGORY_DETAIL,ADD_SPECIAL_CATEGORY } from "@/api/cool";

const FormItem = Form.Item;

class Index extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                GET_SPECIAL_CATEGORY_DETAIL(this.props.id).then((rsp) => {
                    const { title, icon } = rsp.data;

                    this.formRef.current.setFieldsValue({
                        title,
                        icon,
                    });
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    };

    // 提交保存数据
    handleSubmit = (values) => {
        const { title, icon } = values;
        this.setState({ loading: true });
        ADD_SPECIAL_CATEGORY(this.props.id, title, icon, this.props.specialId)
            .then((rsp) => {
                if (rsp.data.code === 1) {
                    this.props.onReload();
                    this.props.onClose();
                } else {
                    message.error(rsp.data.msg);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        return (
            <div>
                <Modal
                    title="添加分类"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onFinish={this.handleSubmit}>
                        <FormItem
                            name="title"
                            label="分类名称"
                            rules={[
                                { required: true, message: "必须填写名称" },
                                { max: 50, message: "名称不能超过50个字" },
                            ]}
                        >
                            <Input placeholder="分类名称" />
                        </FormItem>
                        <FormItem name="icon" label="remix图标">
                            <Input placeholder="remix图标" />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Index;
