import React, {Component} from 'react';
import {Table, Button, Form, message, Input, Tag, Modal, Dropdown, Tooltip} from 'antd';
import AddSource from './components/AddSource';
import Collect from './components/Collect';
import AddTags from './components/AddTags';
import IconAdd from './icon/Add';
import {
    SearchOutlined, EditOutlined,FileSearchOutlined, CheckCircleOutlined, PlusOutlined, TagOutlined, ExclamationCircleOutlined,
    CloseCircleOutlined, CheckOutlined, ClockCircleOutlined, StarOutlined
} from '@ant-design/icons';
import {GET_BASE_LIST, JOIN_SINGLE_BLACKLIST, TOGGLE_STAR} from '@/api/source';
import moduleCss from './style/index.module.css'
import {getQiNiuIconUrl} from "@/utils";
import {REVIEW_HOST} from "@/api/icon";

const FormItem = Form.Item;

/**
 * 基础网址管理列表
 */
class Index extends Component {
    formRef = React.createRef();

    state = {
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 10,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`,
            size: 'default'
        },
        search: {
            name: '',
            page: 1,
            sour_id: '',
            sour_url: '',
            summary: ''
        },
        endRecordId: null,
        sortMethod: '',
        loading: false,
        // 收藏网址
        collectVisible: false,
        collectSourceId: '',
        // 编辑网址
        editSourceId: '', // 编辑网址id
        editSourceVisible: false, // 编辑网址弹框是否显示
        // icon
        editIconId: '',
        editIconVisible: false,
        // 编辑标签
        tagsVisible: false,
        tagsSourceId: '',
        batchBlackListLoading: false, // 批量添加黑名单是否loading
        collectToSpecialVisible: false, // 收录到专题导航
        collectToSpecialId: ''
    };

    componentDidMount() {
        this.getListData();
    }

    // 获取网址列表
    getListData = () => {
        this.setState({loading: true});
        const searchData = this.state.search;
        searchData.page = this.state.pagination.current;
        searchData.sort = this.state.sortMethod;
        searchData.endRecordId = this.state.endRecordId;

        GET_BASE_LIST(searchData).then((response) => {
            let data = response.data;
            let pagination = this.state.pagination;
            pagination.total = data.total;
            pagination.pageSize = data.size;
            this.setState({
                list: data.list.map(item => {
                    item.icon = getQiNiuIconUrl(item.icon);
                    return item;
                }),
                endRecordId: data.list.length ? data.list[data.list.length - 1].sid : null,
                pagination,
                loading: false,
            });
        });
    };

    // 批量加入黑名单
    handleBatchJoinBlacklist = () => {
        Modal.confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '是否将此域名加入黑名单？加入后，此域名下的所有网址均无法公开展示！',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                const iconId = this.state.selectedRowKeys.map(item => {
                    const sourceItem = this.state.list.filter(f => f.sid === item);
                    if (sourceItem.length) {
                        return sourceItem[0].iconId || '';
                    }
                    return 0;
                });
                this.handleJoinBlackList(iconId)
            },
        });
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        const pager = this.state.pagination;
        pager.current = extra.action === 'sort' ? 1 : pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys: [],
            sortMethod: sorter.order
        }, () => {
            this.getListData();
        })

        window.scrollTo(0, 0);
    };

    // 搜索
    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState({search: values, pagination: pager}, () => {
            this.getListData();
        });
    };

    // 单个网址加入黑名单
    handleJoinSingleBlacklist = (sourceId) => {
        JOIN_SINGLE_BLACKLIST(sourceId).then(rsp => {
            if (rsp.data.code === 1) {
                this.getListData();
                message.success('操作成功').then(r => {
                });
            } else {
                message.error(rsp.data.msg).then(r => {
                });
            }
        })
    }

    // 加入、取消黑名单
    handleJoinBlackList = (iconId) => {
        if (!Array.isArray(iconId)) {
            iconId = [iconId];
        }
        this.setState({batchBlackListLoading: true});
        REVIEW_HOST(iconId,'blacklist').then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                message.success('操作成功');
            } else {
                message.error(rsp.data.msg);
            }
        }).finally(() => {
            this.setState({batchBlackListLoading: false, selectedRowKeys: []})
        })
    }

    // 收藏网址（常用网址、导航）
    handleCollect = (sour_id) => {
        this.setState({collectVisible: true, collectSourceId: sour_id});
    };

    // 关闭收藏弹框
    handleCollectClose = () => {
        this.setState({collectVisible: false});
    };

    handleTagsOpen = (sourceId) => {
        this.setState({tagsVisible: true, tagsSourceId: sourceId});
    }

    // 关闭酷站弹框
    handleTagsClose = () => {
        this.setState({tagsVisible: false});
    };

    // 添加新网址
    handleAdd = () => {
        this.handleAddSourceOpen('');
    };
    // 打开添加、编辑网址弹框
    handleAddSourceOpen = (sourceId) => {
        this.setState({editSourceVisible: true, editSourceId: sourceId});
    };
    // 关闭添加、编辑网址弹框
    handleAddSourceClose = () => {
        this.setState({editSourceVisible: false});
    };

    // 编辑Icon
    handleIconEdit = (id) => {
        this.setState({editIconVisible: true, editIconId: id});
    };
    // 关闭icon编辑弹框
    handleIconClose = () => {
        this.setState({editIconVisible: false});
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    submitCoolList = (sid) => {
        TOGGLE_STAR(sid).then(() => {
            this.getListData();
        })
    }

    getSourceStatusLabel = (record) => {
        if (record.blacklist) {
            return <Tooltip title="host黑名单"><CloseCircleOutlined
                style={{color: '#ff4d4f', paddingRight: '5px'}}/></Tooltip>
        } else if (record.oneBlacklist) {
            return <Tooltip title="单个黑名单"><CloseCircleOutlined
                style={{color: '#ff4d4f', paddingRight: '5px'}}/></Tooltip>
        }

        if (!record.review) return <Tooltip title="待审核"><ClockCircleOutlined
            style={{color: '#666', paddingRight: '5px'}}/></Tooltip>
        // 审核中
        if (record.hostReview === 2 || record.hostReview === 4) return <Tooltip
            title="出错了"><ExclamationCircleOutlined
            style={{color: '#faad14', paddingRight: '5px'}}/></Tooltip>
        if (record.hostReview === 3) return <Tooltip title="空白图片"><ExclamationCircleOutlined
            style={{color: '#faad14', paddingRight: '5px'}}/></Tooltip>
        if (record.hostReview === 5) return <Tooltip title="链接失效"><ExclamationCircleOutlined
            style={{color: 'magenta', paddingRight: '5px'}}/></Tooltip>
        if (record.hostReview === 6) return <Tooltip title="已抓取"><ExclamationCircleOutlined
            style={{color: '#666', paddingRight: '5px'}}/></Tooltip>
        // 已审核
        return <CheckCircleOutlined title="已审核" style={{color: '#52c41a', paddingRight: '5px'}}/>
    }

    handleSearchHost=(url)=>{
        const search = this.state.search;
        const arr = new URL(url);
        search.sour_url = arr.host;
        const pager = this.state.pagination;
        pager.current = 1;
        this.formRef.current.setFieldsValue({
            sour_url: arr.host,
        });
        this.setState({
            search,
            pagination: pager
        },()=>{
            this.getListData();
        })
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {title: 'ID', dataIndex: 'sid', width: 100},
            {
                title: '图标',
                dataIndex: 'icon',
                width: 70,
                render: (src, record) => {
                    return (
                        <div className="table-cover" title="更换图标"
                             onClick={() => this.handleIconEdit(record.iconId)}>
                            <img width="32" src={src} alt=""/>
                        </div>
                    );
                },
            },
            {
                title: '名称',
                dataIndex: 'name',
                width: 300,
                render: (name, record) => {
                    return (
                        <div>
                            <p>
                                <span>
                                    {
                                        this.getSourceStatusLabel(record)
                                    }
                                </span>
                                {name} <span className={moduleCss.nameEditBtn}
                                             onClick={() => this.handleAddSourceOpen(record.sid)}><EditOutlined/></span>
                            </p>
                            {
                                record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null
                            }
                            <p>
                                {record.tags.map((item, idx) => (
                                    <Tag color="blue" key={idx}>{item}</Tag>
                                ))}
                                <TagOutlined onClick={() => this.handleTagsOpen(record.sid)}
                                             className={moduleCss.tagAddBtn}/>
                            </p>
                            <p style={{color: '#999', marginBottom: 0}}>{record.createTime}</p>
                        </div>
                    );
                },
            },
            {
                title: 'URL',
                dataIndex: 'url',
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.summary}</p>
                            <p>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                                <span className={moduleCss.searchHost} onClick={() => {
                                    this.handleSearchHost(record.url)
                                }}><FileSearchOutlined/></span>
                            </p>
                            {record.referral ? <p>{record.referral}</p> : ''}
                        </div>
                    );
                },
            },
            {
                title: '精选',
                width: 50,
                render: (record) => {
                    return record.cool.length ? <Tooltip
                            title={record.cool[0].parentName ? `${record.cool[0].parentName}-${record.cool[0].subName}` : '无酷站分类'}><CheckCircleOutlined
                            style={{color: '#52c41a'}}/></Tooltip> :
                        <Button type="link" onClick={() => this.submitCoolList(record.sid)}>
                            <StarOutlined/>
                        </Button>;
                },
            },
            {title: '点击', sorter: true, dataIndex: 'clickAmount', width: 120},
            {
                title: '操作',
                width: 80,
                render: (record) => {
                    const items = [
                        {label: '收藏', key: 'collect'},
                    ];
                    items.push({label: '加入黑名单', key: 'join-blacklist'});
                    items.push({
                        type: 'divider',
                    });
                    if (record.oneBlacklist) {
                        items.push({label: '取消单个黑名单', key: 'join-single-blacklist'})
                    } else {
                        items.push({label: '标记单个黑名单', key: 'join-single-blacklist'})
                    }

                    const onClick = ({key}) => {
                        switch (key) {
                            case 'collect':
                                this.handleCollect(record.sid);
                                break;
                            case 'join-blacklist':
                                this.handleJoinBlackList(record.iconId);
                                break;
                            case 'join-single-blacklist':
                                this.handleJoinSingleBlacklist(record.sid);
                                break;
                            default:
                                break;
                        }
                    };

                    return (
                        <Dropdown menu={{
                            items, onClick
                        }} placement="bottom">
                            <a onClick={(e) => e.preventDefault()}>
                                操作
                            </a>
                        </Dropdown>
                    );
                },
            },
        ];

        return (
            <div>
                <IconAdd
                    id={this.state.editIconId}
                    visible={this.state.editIconVisible}
                    onReload={this.getListData}
                    onClose={this.handleIconClose}
                />
                <AddSource
                    visible={this.state.editSourceVisible}
                    sid={this.state.editSourceId}
                    onReload={this.getListData}
                    onClose={this.handleAddSourceClose}
                />
                <Collect
                    visible={this.state.collectVisible}
                    sourceId={this.state.collectSourceId}
                    onClose={this.handleCollectClose}
                    onReload={this.getListData}
                />
                <AddTags
                    visible={this.state.tagsVisible}
                    sourceId={this.state.tagsSourceId}
                    onClose={this.handleTagsClose}
                    onReload={this.getListData}
                />
                <Form layout="inline" ref={this.formRef} className="search-form-fixed" onFinish={this.handleSearch}>
                    <FormItem name="sour_id">
                        <Input placeholder="网站ID" style={{width: 100}} allowClear/>
                    </FormItem>
                    <FormItem name="name">
                        <Input placeholder="网站名称" style={{width: 120}} allowClear/>
                    </FormItem>
                    <FormItem name="sour_url">
                        <Input placeholder="网站URL" allowClear style={{width: 200}}/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                            搜索
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button icon={<PlusOutlined/>} onClick={this.handleAdd}>
                            添加新网址
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button type="danger" loading={this.state.batchBlackListLoading}
                                onClick={this.handleBatchJoinBlacklist}
                                icon={<CheckOutlined/>}>
                            加入黑名单
                        </Button>
                    </FormItem>
                </Form>
                <Table
                    rowKey="sid"
                    size="small"
                    rowSelection={rowSelection}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                />
            </div>
        );
    }
}

export default Index;
