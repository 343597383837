import React, {Component} from 'react';
import {Button, Form, Table, InputNumber, Modal, message, Breadcrumb} from 'antd';
import AddForm from './AddForm';
import {
    HomeOutlined,
    PlusOutlined,
    SortAscendingOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
    GET_CATEGORY_LIST,
    SORT_CATEGORY,
    DELETE_CATEGORY,
} from '@/api/category';
import QueryString from 'query-string';
import {Link} from 'react-router-dom';
import {IMG_HOST} from "@/config/config";
import styleModule from './style.module.css';

class Category extends Component {
    state = {
        list: [],
        loading: false,
        editCid: '',
        editParentId: '',
        editFormVisible: false,
        hasUpdateSort: false,
        requestData: {},
        currentRoutePath: '',
        breadcrumb: [],
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
        }
    };

    componentDidMount() {
        const queryParams = QueryString.parse(this.props.location.search);
        this.setState({requestData: queryParams, currentRoutePath: this.props.location.pathname}, () => {
            this.getLists();
        });

        this.props.history.listen((route) => {
            const requestData = QueryString.parse(route.search);
            this.setState({requestData, currentRoutePath: route.pathname}, () => {
                this.getLists();
            });
        });
    };

    // 获取分类列表
    getLists = () => {
        this.setState({loading: true});
        GET_CATEGORY_LIST(this.state.requestData).then((rsp) => {
            this.setState({
                list: rsp.data.list,
                loading: false,
                breadcrumb: rsp.data.breadcrumb
            });
        });
    };

    // 编辑分类
    editCategory = (record) => {
        this.setState({
            editCid: record.cate_id,
            editFormVisible: true,
        });
    };

    // 删除分类
    handleDelete = (cid) => {
        Modal.confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '确认删除该分类？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                DELETE_CATEGORY(cid).then(() => {
                    this.getLists();
                    message.success('删除成功');
                });
            },
        });
    };

    handleAdd = (parentId) => {
        if (!parentId) {
            const queryParentId = this.state.requestData.parentId;
            parentId = queryParentId ? queryParentId : 0;
        }
        this.setState({
            editFormVisible: true,
            editCid: '',
            editParentId: parentId,
        });
    };

    handleViewBag=(record)=>{
        window.open(`/#/category/bag?cid=${record.cate_id}`);
    }

    handleEditCancel = () => {
        this.setState({
            editFormVisible: false,
        });
    };

    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.cate_id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SORT_CATEGORY(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getLists();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    }
    // 处理修改sort输入框数字操作
    handleSortChange = (sort, cid) => {
        const list = this.state.list;
        list.forEach((item, index) => {
            if (parseInt(item.cate_id, 10) === parseInt(cid, 10)) {
                list[index].sort = sort;
                this.setState({
                    list,
                    hasUpdateSort: true,
                });
            }
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
    };

    render() {
        const columns = [
            {title: '分类ID', dataIndex: 'cate_id', width: 80},
            {
                title: '名称',
                dataIndex: 'name',
                render: (h, record) => {
                    return record.child.length ? (
                        <Link to={`${this.state.currentRoutePath}?parentId=${record.cate_id}`}>{h}</Link>
                    ) : (
                        h
                    );
                },
            },
            {title: '别名', dataIndex: 'alias_name'},
            {title: '搜索标签', dataIndex: 'tags'},
            {title: '父级ID', dataIndex: 'parent_id'},
            {
                title: 'ICON图标', dataIndex: 'icon', render: (icon) => {
                    return <img src={IMG_HOST + '/' + icon} width='32' alt=""/>;
                }
            },
            {
                title: '图标',
                dataIndex: 'iconfont',
                render: (iconfont, record) => {
                    if (iconfont) {
                        return <i className={`iconfont ${iconfont}`}></i>;
                    } else {
                        return <img src={record.icon} alt=""/>;
                    }
                },
            },
            {
                title: '排序',
                dataIndex: 'sort',
                render: (sort, record) => (
                    <InputNumber
                        key={record.cate_id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.cate_id)}
                    />
                ),
            },
            {
                title: '操作',
                render: (txt, record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.editCategory(record)}>
                                编辑
                            </Button>
                            <Button
                                type="link"
                                onClick={() => this.handleDelete(record.cate_id)}
                            >
                                删除
                            </Button>
                            <Button
                                type="link"
                                onClick={() => this.handleAdd(record.cate_id)}
                            >
                                添加子分类
                            </Button>
                            <Button type="link" onClick={() => this.handleViewBag(record)}>
                                查看笔袋
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <Form layout="inline" className="mb-15" onFinish={this.handleSearch}>
                    <Form.Item>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            onClick={() => this.handleAdd(null)}
                        >
                            添加新分类
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            onClick={this.handleUpdateSort}
                            icon={<SortAscendingOutlined/>}
                        >
                            {this.state.hasUpdateSort ? '更新排序' : '整理排序'}
                        </Button>
                    </Form.Item>
                </Form>
                <div className="bg-white pd-10">
                    <Breadcrumb className={styleModule.breadcrumb}>
                        <Breadcrumb.Item>
                            <Link to='/category/parent'><HomeOutlined /></Link>
                        </Breadcrumb.Item>
                        {
                            this.state.breadcrumb.map(item => {
                                return <Breadcrumb.Item>
                                    {
                                        this.state.requestData.parentId === item.id ? <span>{item.name}</span> :
                                            <Link to={`/category/parent?parentId=${item.id}`}>{item.name}</Link>
                                    }
                                </Breadcrumb.Item>
                            })
                        }
                    </Breadcrumb>
                    <Table
                        rowKey="cate_id"
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                        loading={this.state.loading}
                    />
                </div>
                <AddForm
                    visible={this.state.editFormVisible}
                    cid={this.state.editCid}
                    pid={this.state.editParentId}
                    onCancel={this.handleEditCancel}
                    reloadLists={this.getLists}
                />
            </div>
        );
    }
}

export default Category;
