import request from '@/api/request';

export const GET_ENGINE_SHORTCUT = (page = 1, pageSize = 10) => {
  return request.get('/engine/get-shortcut', { page, pageSize });
};

// 新增、编辑搜索引擎
export const CREATE_ENGINE = (data) => {
  return request.post('/engine/add', data);
};

// 标记、取消标记为快捷Engine
export const ADD_SHORTCUT = (data) => {
  return request.post('/engine/add-shortcut', data);
};

// 从快捷engine中移除选项
export const REMOVE_SHORTCUT = (data) => {
  return request.post('/engine/remove-shortcut', data);
};

// 快捷方式排序
export const SHORTCUT_SORT = (data) => {
  return request.post('/engine/sort-shortcut', data);
};

// 给engine添加备注
export const REMARK_ENGINE = (unId, remark) => {
  return request.post('/engine/remark', {unId, remark});
};
