import React from 'react';
import ReactDOM from 'react-dom';

import '@wangeditor/editor/dist/css/style.css' // 引入 css
import 'antd/dist/antd.css';
import '@/assets/css/style.css';
import Layout from '@/layout';
import {ConfigProvider} from "antd";
import zhCN from 'antd/lib/locale/zh_CN';

import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Layout />
  </ConfigProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
