import React, {Component} from 'react';
import {Button, Form, Table, InputNumber, Modal, message, Breadcrumb} from 'antd';
import AddForm from './AddForm';
import {
    HomeOutlined,
    PlusOutlined,
    SortAscendingOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
    GET_CATEGORY_LIST,
    SORT_CATEGORY,
    DELETE_CATEGORY, GET_CATEGORY_BAG_ALIAS,
} from '@/api/category';
import QueryString from 'query-string';
import {Link} from 'react-router-dom';
import {IMG_HOST} from "@/config/config";
import styleModule from './style.module.css';
import AddCategoryBag from "@/pages/Category/AddCategoryBag";
import BookmarkInBagModal from "@/pages/Source/components/BookmarkInBagModal";

class Index extends Component {
    state = {
        cid: '',
        list: [],
        loading: false,
        editCid: '',
        editParentId: '',
        editFormVisible: false,
        hasUpdateSort: false,
        requestData: {},
        currentRoutePath: '',
        breadcrumb: [],
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
        },

        addCategoryBagVisible: false,
        editCategoryBagId: '',
        editCategoryBagName: '',
    };

    componentDidMount() {
        const queryParams = QueryString.parse(this.props.location.search);
        this.setState({cid: queryParams.cid}, () => {
            this.getCategoryBagListData();
        });
    };

    // 获取分类下的笔袋列表
    getCategoryBagListData = () => {
        this.setState({loading: true});
        GET_CATEGORY_BAG_ALIAS('', '', this.state.cid).then((rsp) => {
            this.setState({
                list: rsp.data.list,
                loading: false,
            });
        });
    };

    // 编辑分类
    editCategory = (record) => {
        this.setState({
            editCid: record.cate_id,
            editFormVisible: true,
        });
    };

    // 删除分类
    handleDelete = (cid) => {
        Modal.confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '确认删除该分类？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                DELETE_CATEGORY(cid).then(() => {
                    this.getLists();
                    message.success('删除成功');
                });
            },
        });
    };

    handleViewBookmark = (bid) => {
        window.open(`/#/category/bookmarkInBag?bid=${bid}`);

    }

    handleAdd = () => {
        this.setState({
            addCategoryBagVisible: true
        })
    }

    handleEditCancel = () => {
        this.setState({
            editFormVisible: false,
        });
    };

    // 关闭添加笔袋弹框
    handleAddModalClose = () => {
        this.setState({
            addCategoryBagVisible: false,
        });
    }

    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.cate_id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SORT_CATEGORY(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getLists();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    }
    // 处理修改sort输入框数字操作
    handleSortChange = (sort, cid) => {
        const list = this.state.list;
        list.forEach((item, index) => {
            if (parseInt(item.cate_id, 10) === parseInt(cid, 10)) {
                list[index].sort = sort;
                this.setState({
                    list,
                    hasUpdateSort: true,
                });
            }
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
    };

    render() {
        const columns = [
            {title: '笔袋ID', dataIndex: 'bid', width: 100},
            {
                title: '图标', dataIndex: 'icon', render: (icon) => {
                    return <img src={icon} width='32' alt=""/>;
                }
            },
            {
                title: '名称',
                dataIndex: 'name'
            },

            {
                title: '排序',
                dataIndex: 'sort',
                render: (sort, record) => (
                    <InputNumber
                        key={record.cate_id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.cate_id)}
                    />
                ),
            },
            {
                title: '操作',
                render: (txt, record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.editCategory(record)}>
                                编辑
                            </Button>
                            <Button
                                type="link"
                                onClick={() => this.handleDelete(record.cate_id)}
                            >
                                删除
                            </Button>
                            <Button type="link" onClick={() => this.handleViewBookmark(record.bid)}>
                                查看书签
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <AddCategoryBag
                    visible={this.state.addCategoryBagVisible}
                    bid={this.state.editCategoryBagId}
                    bagName={this.state.editCategoryBagName}
                    cid={this.state.cid}
                    onReload={this.getCategoryBagListData}
                    onClose={this.handleAddModalClose}
                />
                <Form layout="inline" className="mb-15" onFinish={this.handleSearch}>
                    <Form.Item>
                        <Button type="primary" icon={<PlusOutlined/>} onClick={() => this.handleAdd(null)}>
                            添加新笔袋
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={this.handleUpdateSort} icon={<SortAscendingOutlined/>}>
                            {this.state.hasUpdateSort ? '更新排序' : '整理排序'}
                        </Button>
                    </Form.Item>
                </Form>
                <div className="bg-white pd-10">
                    <Table
                        rowKey="cate_id"
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default Index;
