import React from 'react';
import QueryString from 'query-string';
import {
    GET_CATEGORY,
    GET_CATEGORY_SOURCE,
    SORT_SOURCE,
    REMOVE_CATEGORY_SOURCE,
} from '@/api/source';
import {
    Form,
    Input,
    Table,
    Button,
    Row,
    Col,
    List,
    InputNumber,
    Modal,
    message,
} from 'antd';
import {
    SortAscendingOutlined,
    SearchOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import CategorySourceRemark from './components/CategorySourceRemark';
import Children from './Children';
import Collect from './components/Collect';

const FormItem = Form.Item;

let UN_LISTEN;

class Category extends React.Component {
    state = {
        module: '',
        host: '',
        loading: false,
        activeCid: '',
        category: [],
        list: [],
        pagination: {
            current: 1,
            pageSize: 100,
        },
        searchData: {
            name: '',
            url: '',
        },
        hasUpdateSort: false,
        // 备注
        remarkVisible: false,
        remarkId: '',
        remarkContent: '',
        remarkDesc: '',
        childrenManageVisible: false,
        childrenManageId: '',
        // 收藏网址
        collectVisible: false,
        collectSourceId: '',
    };

    componentDidMount() {
        const module = QueryString.parse(this.props.history.location.search);
        this.setState({module: module.module}, () => {
            this.getCategoryData();
        });
        UN_LISTEN = this.props.history.listen((route) => {
            const param = QueryString.parse(route.search);
            this.setState({module: param.module, activeCid: ''}, () => {
                this.getCategoryData();
            });
        });
    }

    componentWillUnmount() {
        UN_LISTEN && UN_LISTEN();
    }

    // 获取侧边栏分类
    getCategoryData = () => {
        GET_CATEGORY(this.state.module).then((rsp) => {
            const data = {category: rsp.data.list};
            if (!this.state.activeCid) {
                data.activeCid = rsp.data.list[0].bag_id;
            }
            this.setState(data, () => {
                this.getSourceData();
            });
        });
    };

    // 切换分类操作
    handleCategoryChange = (cid) => {
        this.setState({activeCid: cid}, () => {
            this.getSourceData();
        });
    };

    // 根据分类获取分类下的网址
    getSourceData = () => {
        this.setState({loading: true});
        GET_CATEGORY_SOURCE(
            this.state.activeCid,
            this.state.searchData.name,
            this.state.searchData.url
        )
            .then((rsp) => {
                this.setState({
                    host: rsp.data.host,
                    list: rsp.data.list,
                });
            })
            .finally(() => {
                this.setState({loading: false});
            });
    };

    // 处理修改sort输入框数字操作
    handleSortChange = (sort, cid) => {
        const list = this.state.list;
        list.forEach((item, index) => {
            if (parseInt(item.bs_id, 10) === parseInt(cid, 10)) {
                list[index].sort = sort;
                this.setState({
                    list,
                    hasUpdateSort: true,
                });
            }
        });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.bs_id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SORT_SOURCE(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getSourceData();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    };

    // 添加网址
    handleAdd = () => {
    };

    // 收藏网址（常用网址、导航）
    handleCollect = (sour_id) => {
        this.setState({collectVisible: true, collectSourceId: sour_id});
    };

    // 关闭收藏弹框
    handleCollectClose = () => {
        this.setState({collectVisible: false});
    };

    // 将网址从当前分类移除
    handleRemove = (id) => {
        Modal.confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '是否要移除该网址？移除后如需要，请重新添加！',
            onOk: () => {
                REMOVE_CATEGORY_SOURCE(id).then((rsp) => {
                    if (rsp.data.code === 1) {
                        message.success('移除成功');
                        this.getSourceData();
                    } else {
                        message.error(rsp.data.msg);
                    }
                });
            },
        });
    };

    handleRemark = (id, content, desc) => {
        this.setState({
            remarkVisible: true,
            remarkId: id,
            remarkContent: content,
            remarkDesc: desc
        });
    };

    handleManageChildren = (id) => {
        this.setState({
            childrenManageVisible: true,
            childrenManageId: id
        });
    }

    // 关闭备注弹框
    handleRemarkCancel = () => {
        this.setState({remarkVisible: false});
    };

    // 关闭管理子网址弹框
    handleChildrenClose = () => {
        this.setState({childrenManageVisible: false})
    }

    // 搜索网址
    handleSearch = (values) => {
        const searchData = values;
        this.setState({searchData}, () => {
            this.getSourceData();
        });
    };

    render() {
        const columns = [
            {title: 'ID', dataIndex: 'bs_id'},
            {
                title: 'ICON',
                dataIndex: 'icon_file',
                render: (icon) => (
                    <img width="32" src={this.state.host + icon} alt=""/>
                ),
            },
            {
                title: '标题',
                dataIndex: 'name',
                render: (name, record) => {
                    return record.remark ? `${name}（备注：${record.remark}）` : name;
                },
            },
            {
                title: '网址',
                dataIndex: 'sour_url',
                render(url,record) {
                    return (
                        <div>
                            {record.note ? <p>描述：{record.note}</p>:null}
                            <p><a href={url} target="_blank" rel="noreferrer">
                                {url}
                            </a></p>
                        </div>
                    );
                },
            },
            {title: '点击', dataIndex: 'click_amount'},
            {title: '子网址', dataIndex: 'children_amount'},
            {
                title: '排序',
                dataIndex: 'sort',
                render: (sort, record) => (
                    <InputNumber
                        key={record.bs_id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.bs_id)}
                    />
                ),
            },
            {
                title: '操作',
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleRemark(record.bs_id, record.remark, record.note)}>
                                备注
                            </Button>
                            <Button type="link" onClick={() => this.handleManageChildren(record.bs_id)}>
                                管理子网址
                            </Button>
                            <Button type="link" onClick={() => this.handleCollect(record.sour_id)}>
                                收藏
                            </Button>
                            <Button type="link" danger onClick={() => this.handleRemove(record.bs_id)}>
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <Children
                    visible={this.state.childrenManageVisible}
                    parentId={this.state.childrenManageId}
                    onClose={this.handleChildrenClose}
                    onReload={this.getSourceData}
                />
                <CategorySourceRemark
                    visible={this.state.remarkVisible}
                    id={this.state.remarkId}
                    remark={this.state.remarkContent}
                    desc={this.state.remarkDesc}
                    onReload={this.getSourceData}
                    onCancel={this.handleRemarkCancel}
                />
                <Collect
                    visible={this.state.collectVisible}
                    sourceId={this.state.collectSourceId}
                    onClose={this.handleCollectClose}
                    onReload={this.getSourceData}
                />
                <Row gutter={16}>
                    <Col span={3}>
                        <div className="bg-white pd-10">
                            <List
                                size="small"
                                className="sub-category"
                                bordered
                                dataSource={this.state.category}
                                renderItem={(item) => {
                                    return (
                                        <List.Item
                                            onClick={() => this.handleCategoryChange(item.bag_id)}
                                            className={
                                                item.bag_id === this.state.activeCid ? 'active' : ''
                                            }
                                        >
                                            {item.bag_name}
                                        </List.Item>
                                    );
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={21}>
                        <Form
                            layout="inline"
                            className="mb-15"
                            onFinish={this.handleSearch}
                        >
                            <FormItem name="name">
                                <Input placeholder="网址名称"/>
                            </FormItem>
                            <FormItem name="url">
                                <Input placeholder="网址URL"/>
                            </FormItem>
                            <FormItem>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SearchOutlined/>}
                                >
                                    搜索
                                </Button>
                            </FormItem>
                            {/* <FormItem>
                <Button icon={<PlusOutlined />} onClick={this.handleAdd}>
                  添加新网址
                </Button>
              </FormItem> */}
                            <Form.Item>
                                <Button
                                    onClick={this.handleUpdateSort}
                                    icon={<SortAscendingOutlined/>}
                                >
                                    {this.state.hasUpdateSort ? '更新排序' : '整理排序'}
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="bg-white pd-10">
                            <Table
                                rowKey="bs_id"
                                columns={columns}
                                dataSource={this.state.list}
                                bordered
                                pagination={this.state.pagination}
                                loading={this.state.loading}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Category;
