import React, { useEffect } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { REMARK_ENGINE } from '@/api/engine';

const CreateFormModal = ({ visible, unId, remark, onCancel, reload }) => {
  const [form] = Form.useForm();
  const onCreate = (values) => {
    REMARK_ENGINE(unId, values.remark).then((rsp) => {
      if (rsp.data.code === 1) {
        form.resetFields();
        reload();
        onCancel();
      } else {
        message.error(rsp.data.msg);
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      remark: remark ? remark : '',
    });
  }, [form, remark]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <Modal
      visible={visible}
      title="搜索备注"
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name="remark"
          label="备注"
          rules={[{ max: 20, message: '备注不能超过20个字' }]}
        >
          <Input placeholder="请输入备注名称" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFormModal;
