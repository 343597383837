import React, { Component } from "react";
import { Table, Button, Form, message, Modal, InputNumber } from "antd";
import { ExclamationCircleOutlined, FileTextOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { REMOVE_SPECIAL_BOOKMARK, GET_SPECIAL_BOOKMARK, SPECIAL_BOOKMARK_SORT } from "@/api/cool";
import moduleCss from "@/pages/Source/style/index.module.css";
const FormItem = Form.Item;
const { confirm } = Modal;

/**
 * 全部酷站网址列表
 */
class Index extends Component {
    state = {
        sourceList: [],
        loading: false,
        hasUpdateSort: false,
        addRemarkVisible: false,
        addRemarkId: "",
    };

    componentDidUpdate(prevPros) {
        if (prevPros.visible !== this.props.visible) {
            if (this.props.visible) {
                this.getBookmarkListData();
            }
        }
    }

    // 从酷站中移除该网址
    handleRemove = (id) => {
        confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否从专题分类移除该网址？",
            onOk: () => {
                REMOVE_SPECIAL_BOOKMARK(id).then((response) => {
                    if (response.data.code === 1) {
                        this.getBookmarkListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    // 当用户修改sort时候，重新设置列表的sort
    handleSortChange = (sort, id) => {
        const sourceList = this.state.sourceList;
        const item = sourceList.find((f) => f.id === id);
        item.sort = sort;
        this.setState({
            sourceList,
            hasUpdateSort: true,
        });
    };

    // 获取分类下的网址数据
    getBookmarkListData = () => {
        this.setState({ loading: true });
        GET_SPECIAL_BOOKMARK(this.props.id)
            .then((rsp) => {
                this.setState({
                    sourceList: rsp.data.list,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 打开备注弹框
    handleRemarkModalOpen = (id) => {
        this.setState({ addRemarkVisible: true, addRemarkId: id });
    };

    // 关闭添加、编辑网址弹框
    handleRemarkModalClose = () => {
        this.setState({ addRemarkVisible: false });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.sourceList.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SPECIAL_BOOKMARK_SORT(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getBookmarkListData();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    };

    handleCancel = () => {
        this.props.onClose();
    };

    render() {
        const columns = [
            { title: "SID", dataIndex: "sid", width: 100 },
            {
                title: "图标",
                dataIndex: "icon",
                width: 70,
                render: (src) => {
                    return (
                        <div className="table-cover">
                            <img width="32" src={src} alt="" />
                        </div>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                render: (name, record) => {
                    return (
                        <div>
                            <p>
                                {name || record.baseName}
                                <span
                                    className={moduleCss.nameEditBtn}
                                    onClick={() => this.handleRemarkModalOpen(record.id)}
                                >
                                    &nbsp;
                                    <FileTextOutlined />
                                </span>
                            </p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}

                            <p style={{ marginBottom: 0, color: "#666" }}>{record.createTime}</p>
                        </div>
                    );
                },
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.summary || record.baseSummary}</p>
                            <p style={{ marginBottom: 0 }}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            { title: "点击", dataIndex: "clickAmount", width: 120 },
            {
                title: "排序",
                dataIndex: "sort",
                render: (sort, record) => (
                    <InputNumber
                        key={record.id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.id)}
                    />
                ),
            },
            {
                title: "操作",
                width: 60,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleRemove(record.id)} danger>
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <Modal
                style={{ top: 0 }}
                width="95%"
                title="查看分类书签"
                cancelText="关闭"
                okText="立即保存"
                open={this.props.visible}
                onCancel={this.handleCancel}
                footer={null}
                confirmLoading={this.state.loading}
            >
                <Form layout="inline" className="mb-15 search-form-fixed">
                    <FormItem>
                        <Button onClick={this.handleUpdateSort} icon={<SortAscendingOutlined />}>
                            {this.state.hasUpdateSort ? "更新排序" : "整理排序"}
                        </Button>
                    </FormItem>
                </Form>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    pagination={false}
                    columns={columns}
                    dataSource={this.state.sourceList}
                    bordered
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

export default Index;
