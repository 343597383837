import request from "./request";

// 获取用户列表
export const GET_MEMBER_LIST = (data) => {
  return request.get('/member/index', data);
}

// 获取用户信息
export const GET_USER_INFO = ({uid}) => {
  return request.get('/member/detail', {uid});
}

// 添加、编辑用户信息
export const ADD_USER = ({uid,nickname,avatar,email}) => {
  return request.post('/member/add', {uid,nickname,avatar,email});
}

// 给用户发消息
export const SEND_MESSAGE = (data) => {
  return request.post('/notice/send', data);
}
