import React, { Component } from "react";
import { Table, Radio, Tag, Button, Form, Input, Checkbox, Modal } from "antd";
import AddForm from "./Add";
import Repeat from "./Repeat";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { GET_LIST, REVIEW_HOST } from "@/api/icon";
import { getQiNiuIconUrl } from "@/utils";

const FormItem = Form.Item;

/**
 * 全站ICON管理列表
 * @class Index
 */
class Index extends Component {
    state = {
        lists: [],
        pagination: {
            current: 1,
            pageSize: 20,
            showQuickJumper: true,
            showTotal: (total) => `共计 ${total} 条`,
        },
        search: {},
        noIconState: false,
        // 添加、编辑
        editVisible: false,
        editId: "",
        // 复用
        repeatVisible: false,
        repeatId: "",
        // 审核
        reviewId: "",
        reviewStatus: "pass",
        reviewModalVisible: false,
        reviewLoading: false,
    };

    componentDidMount() {
        this.getListsData();
    }

    // 获取列表
    getListsData = () => {
        let data = this.state.search;
        data.page = this.state.pagination.current;
        if (this.state.noIconState) {
            data.noIconState = 1;
        }

        GET_LIST(data).then((response) => {
            let data = response.data;
            let pager = this.state.pagination;
            pager.total = data.total;
            pager.pageSize = data.page_size;
            this.setState({
                lists: response.data.lists.map((item) => {
                    item.icon_file = getQiNiuIconUrl(item.icon_file);
                    return item;
                }),
                pagination: pager,
            });
        });
    };

    // 搜索
    handleSearch = (values) => {
        const pagination = this.state.pagination;
        pagination.current = 1;
        this.setState({ search: values, pagination }, () => {
            this.getListsData();
        });
    };

    // 分页变化
    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({ pagination: pager }, () => {
            window.scrollTo(0, 0);
            this.getListsData();
        });
    };

    // 添加icon
    handleAdd = () => {
        this.handelEdit("");
    };

    // 编辑icon
    handelEdit = (id) => {
        this.setState({ editVisible: true, editId: id });
    };

    // 关闭添加、编辑弹框
    handleAddClose = () => {
        this.setState({ editVisible: false });
    };

    // 网址复用
    handleRepeat = (id) => {
        this.setState({ repeatVisible: true, repeatId: id });
    };

    // 网址复用弹框关闭
    handleRepeatClose = () => {
        this.setState({ repeatVisible: false });
    };

    // 选择无图标icon
    onNoIconChange = (e) => {
        this.setState({
            noIconState: e.target.checked,
        });
    };

    onReviewStatusChange = (e) => {
        this.setState({
            reviewStatus: e.target.value,
        });
    };

    showReviewModal = (id) => {
        this.setState({
            reviewId: id,
            reviewStatus: "pass",
            reviewModalVisible: true,
        });
    };

    handleReviewOk = () => {
        REVIEW_HOST(this.state.reviewId, this.state.reviewStatus).then(() => {
            this.handleReviewCancel();
            this.getListsData();
        });
    };

    handleReviewCancel = () => {
        this.setState({
            reviewModalVisible: false,
        });
    };

    render() {
        const columns = [
            { title: "ID", dataIndex: "icon_id", width: 120 },
            {
                title: "Icon",
                dataIndex: "icon_file",
                render: (src, record) => {
                    return (
                        <div className="table-cover" title="更换图标" onClick={() => this.handelEdit(record.icon_id)}>
                            <img width="32" src={src} alt="" />
                        </div>
                    );
                },
            },
            {
                title: "Host",
                dataIndex: "host",
                render: (txt, record) => (
                    <a target="_blank" href={record.sour_url} rel="noreferrer">
                        {txt}
                    </a>
                ),
            },
            {
                title: "状态",
                dataIndex: "review",
                render: (review, record) => {
                    if (record.blacklist) return <Tag color="red">黑名单</Tag>;
                    if (review === 5) return <Tag color="magenta">失效链接</Tag>;
                    if (review === 4) return <Tag color="volcano">抓取出错</Tag>;
                    if (review === 6) return <Tag color="cyan">已抓取未审核</Tag>;
                    if (review === 1) return <Tag color="green">正常</Tag>;
                    return <Tag>未审核</Tag>;
                },
            },
            {
                title: "操作",
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handelEdit(record.icon_id)}>
                                编辑
                            </Button>
                            <Button type="link" onClick={() => this.handleRepeat(record.icon_id)}>
                                复用
                            </Button>
                            <Button type="link" onClick={() => this.showReviewModal(record.icon_id)}>
                                审核
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <Modal
                    title="审核"
                    cancelText="关闭"
                    okText="审核"
                    open={this.state.reviewModalVisible}
                    onOk={this.handleReviewOk}
                    onCancel={this.handleReviewCancel}
                    confirmLoading={this.state.reviewLoading}
                >
                    <Radio.Group onChange={this.onReviewStatusChange} value={this.state.reviewStatus}>
                        <Radio value="pass">审核通过</Radio>
                        <Radio value="blacklist">黑名单</Radio>
                        <Radio value="lose">失效</Radio>
                    </Radio.Group>
                </Modal>
                <AddForm
                    id={this.state.editId}
                    visible={this.state.editVisible}
                    onReload={this.getListsData}
                    onClose={this.handleAddClose}
                />
                <Repeat
                    id={this.state.repeatId}
                    visible={this.state.repeatVisible}
                    onReload={this.getListsData}
                    onClose={this.handleRepeatClose}
                />
                <Form layout="inline" className="mb-15" onFinish={this.handleSearch}>
                    <FormItem name="host">
                        <Input placeholder="输入host" allowClear />
                    </FormItem>
                    <FormItem name="iconUrl">
                        <Input placeholder="图标路径" style={{ width: "300px" }} allowClear />
                    </FormItem>
                    <FormItem>
                        <Checkbox onChange={this.onNoIconChange}>无图标</Checkbox>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                            搜索
                        </Button>
                    </FormItem>
                </Form>
                <div className="bg-white pd-10">
                    <Table
                        rowKey="icon_id"
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.lists}
                        bordered
                    />
                </div>
            </div>
        );
    }
}

export default Index;
