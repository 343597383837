import React, {Component} from 'react';
import {Button, Form, message, Upload, Input, Modal} from 'antd';
import PropTypes from 'prop-types';
import {ADD_ICON, GET_INFO} from '@/api/icon';

const FormItem = Form.Item;

/**
 * 添加、编辑ICON
 * @class Add
 * @extends {Component}
 */
class Add extends Component {
    formRef = React.createRef();

    state = {
        host: '',
        icon: '',
        loading: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                GET_INFO(this.props.id).then((rsp) => {
                    this.setState({host: rsp.data.host, icon: rsp.data.icon});
                    this.formRef.current.setFieldsValue(rsp.data.info);
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    handleSubmit = (values) => {
        values.icon_file = this.state.icon;
        this.setState({loading: true});

        ADD_ICON(values).then(() => {
            this.props.onReload();
            this.props.onClose();
            this.setState({loading: false});
        });
    };

    // 点击弹框OK
    handleOk = () => {
        this.formRef.current.submit();
    };
    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    render() {
        const upload_props = {
            name: 'UploadIcon[imageFiles]',
            action: '/api/upload/icon',
            multiple: false,
            data: {module: 'website'},
            showUploadList: false,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if (info.file.response.code === 1) {
                        this.setState({
                            icon: info.file.response.save,
                            host: info.file.response.host,
                        });
                        message.success(`上传成功`);
                    } else {
                        message.success(info.file.response.msg);
                    }
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        return (
            <div>
                <Modal
                    title="添加"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.loading}
                >
                    <Form ref={this.formRef} {...layout} onFinish={this.handleSubmit}>
                        <FormItem
                            name="host"
                            label="Host"
                            rules={[
                                {required: true, message: '必须填写host'},
                                {max: 255, message: 'host长度不能超过255个字符'},
                            ]}
                        >
                            <Input disabled={this.props.id} placeholder="输入host"/>
                        </FormItem>
                        {/*<FormItem
              name="title"
              label="标题"
              rules={[
                { max: 50, message: '标题长度不能超过50个字符' },
              ]}
            >
              <Input placeholder="输入标题" />
            </FormItem>*/}
                        {/*<FormItem name="sprite" label="Sprite">
              <Input placeholder="请输入sprite class类名" />
            </FormItem>
            <FormItem name="sprite_position" label="图标位置">
              <Input placeholder="请输入sprite图标坐标" />
            </FormItem>*/}
                        <FormItem label="上传ICON">
                            <div>
                                <Upload {...upload_props}>
                                    <Button>上传ICON</Button>
                                </Upload>
                                {this.state.icon ? (
                                    <div className="mt-15">
                                        <img
                                            width="32"
                                            src={this.state.host + this.state.icon}
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

Add.propTypes = {
    onReload: PropTypes.func,
};

export default Add;
