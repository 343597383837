const QI_NIU_HOST = 'https://img.bidianer.com/';

// 获取七牛的图标icon地址
export function getQiNiuIconUrl(path) {
  const emptyUrl = QI_NIU_HOST + 'image/icon/empty.svg';
  if (!path) return emptyUrl;
  const extArr = path.split('.');
  const ext = extArr[extArr.length - 1];
  if (['jpg', 'jpeg', 'png'].includes(ext)) {
    return QI_NIU_HOST + path + '?imageView2/2/w/40/h/40/q/100';
  }
  return QI_NIU_HOST + path;
}

export function getQiNiuAvatar(path) {
  const defaultAvatar = QI_NIU_HOST + 'image/user/avatar.svg';
  if (!path) return defaultAvatar;
  const extArr = path.split('.');
  const ext = extArr[extArr.length - 1];
  if (['jpg', 'jpeg', 'png'].includes(ext)) {
    return QI_NIU_HOST + path + '?imageView2/2/w/32/h/32/q/100';
  }
  return QI_NIU_HOST + path;
}
