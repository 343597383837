import React, {Component} from 'react';
import {Table, Button, message, Tag, Form} from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import Add from './Add';
import {PlusOutlined,DeleteOutlined} from '@ant-design/icons'

const FormItem = Form.Item;

class Content extends Component {
  state = {
    lists: [],
    selectedRowKeys: [],
    pagination: {
      current: 1,
      showQuickJumper: true
    },
    search: {},
    loading: false,
    visible: false
  };

  componentDidMount() {
    this.getLists();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({selectedRowKeys});
  };

  getLists = () => {
    let data = this.state.search;
    data.page = this.state.pagination.current;
    this.setState({loading: true});
    axios.post('/notice/content', qs.stringify(data))
      .then((response) => {
        let data = response.data;
        let pager = this.state.pagination;
        pager.total = data.total;
        pager.pageSize = data.page_size;
        this.setState({
          lists: response.data.lists,
          pagination: pager,
          loading: false
        });
      });
  };

  handleTableChange = (pagination) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedRowKeys: []
    });

    window.scrollTo(0, 0);
    this.getLists();
  };

  onRef = (ref) => {
    this.child = ref;
  };

  handleAdd = () => {
    this.child.showModal(null);
  };

  deleteItems = () => {
    let content_ids = this.state.selectedRowKeys;
    axios.post('/notice/delete', qs.stringify({content_ids: JSON.stringify(content_ids)}))
      .then((response) => {
        if (response.data.code === 1) {
          message.success('删除成功');
          this.getLists();
          this.setState({
            selectedRowKeys: []
          })
        } else {
          message.error(response.data.msg);
        }
      });
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {title: '消息ID', dataIndex: 'content_id', width: 100},
      {
        title: '头像', dataIndex: 'user_headimg', render: (avatar) => {
          return <img src={avatar} width={32} alt=""/>
        }
      },
      {
        title: '发送者', dataIndex: 'user_nickname', render: (nickname) => {
          return nickname ? nickname : '未登录'
        }
      },
      {
        title: '内容', width: 400, dataIndex: 'content', render: (content) => {
          return <p dangerouslySetInnerHTML={{__html: content}}/>
        }
      },
      {
        title: '类型', dataIndex: 'type', render: (type) => {
          return parseInt(type, 10) === 1 ? <Tag color="magenta">系统消息</Tag> : <Tag>吐个槽</Tag>
        }
      },
      {
        title: '是否发送', dataIndex: 'is_send', render: (is_send) => {
          return parseInt(is_send, 10) === 1 ? <Tag>已发送</Tag> : <Tag color="blue">未发送</Tag>
        }
      },
      {title: '发送时间', dataIndex: 'create_time'},
    ];

    return (
      <div>
        <Add reloadLists={this.getLists} onRef={this.onRef}/>
        <div className="lists-toolbar">
          <Form layout="inline" onFinish={this.handleSubmit}>
            <FormItem>
              <Button icon={<PlusOutlined />} onClick={this.handleAdd}>群发消息</Button>
            </FormItem>
            <FormItem>
              <Button icon={<DeleteOutlined />}
                      disabled={this.state.selectedRowKeys.length <= 0}
                      onClick={this.deleteItems} type="danger">删除</Button>
            </FormItem>
          </Form>
        </div>
        <Table
          rowKey={record => record.content_id}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={this.state.lists}
          bordered
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default Content