import request from '@/api/request';

// 获取分类下的帮助中心文章列表
export const GET_HELP_LIST = (cid) => {
    return request.get('/help/list', {cid});
};

export const SUBMIT_HELP_POST = (id, cid, title, content) => {
    return request.post('/help/submit', {id, cid, title, content});
};

export const GET_POST_DETAIL = (id) => {
    return request.get('/help/detail', {id});
}

// 保存文章列表排序
export const SAVE_SORT = (sort) => {
    return request.post('/help/sort', {sort});
};

// 删除文章
export const DELETE_POST = (id) => {
    return request.post('/help/delete', {id});
};
