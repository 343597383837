import React, { Component } from "react";
import { Form, Input, Modal } from "antd";
import PropTypes from "prop-types";
import { CRAWLER_SOURCE_INFO } from "@/api/source";
import { SAVE_ITEM_DATA } from "@/api/common";

const FormItem = Form.Item;

/**
 * 编辑、添加分组选项
 * @class Add
 * @extends {Component}
 */
class AddSource extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                const { title, url, color } = this.props.data;
                this.formRef.current.setFieldsValue({
                    title,
                    url,
                    color,
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    };

    // 提交保存数据
    handleSubmit = (values) => {
        const { title, url, color } = values;
        this.setState({ loading: true });
        SAVE_ITEM_DATA(this.props.id, this.props.groupId, title, url, color)
            .then(() => {
                this.props.onReload();
                this.props.onClose();
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    handleEnterKeyUp = (e) => {
        // Enter
        if (e.keyCode === 13) {
            this.formRef.current.submit();
        }
    };

    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        return (
            <div>
                <Modal
                    style={{ top: 20 }}
                    title="添加/编辑选项"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onKeyUp={this.handleEnterKeyUp} onFinish={this.handleSubmit}>
                        <FormItem
                            name="title"
                            label="标题"
                            rules={[
                                { required: true, message: "必须填写标题" },
                                { max: 10, message: "标题不能超过10个字" },
                            ]}
                        >
                            <Input placeholder="标题" allowClear />
                        </FormItem>
                        <FormItem name="color" label="颜色">
                            <Input placeholder="标题" allowClear />
                        </FormItem>
                        <FormItem name="url" label="URL" rules={[{ required: true, message: "必须填写URL" }]}>
                            <Input placeholder="URL" allowClear />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

AddSource.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default AddSource;
