import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CREATE_ENGINE } from '@/api/engine';

const { Option } = Select;

const CreateFormModal = ({
  visible,
  data,
  onCancel,
  categoryOption,
  activeCid,
  reload,
}) => {
  const [icon, setIcon] = useState('');
  const [category, setCategory] = useState('');
  const [form] = Form.useForm();
  const upload_props = {
    name: 'UploadIcon[imageFiles]',
    action: '/api/upload/icon',
    multiple: false,
    data: { module: 'engine' },
    showUploadList: false,
    onChange: (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        setIcon(info.file.response.save);
        message.success(`上传成功`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const onCreate = (values) => {
    if (data) {
      values.engine_id = data.engine_id;
    }
    values.icon = icon;
    values.category_id = category;
    CREATE_ENGINE(values).then((rsp) => {
      if (rsp.data.code === 1) {
        form.resetFields();
        reload();
        onCancel();
      } else {
        message.error(rsp.data.msg);
      }
    });
  };

  const handleCategoryChange = (cid) => {
    setCategory(cid);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data ? data.name : '',
      url: data ? data.url : ''
    });
    setIcon(data ? data.icon : '');
  }, [data, form]);

  useEffect(() => {
    setCategory(activeCid);
  }, [activeCid]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <Modal
      visible={visible}
      title="添加搜索引擎"
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name="name"
          label="名称"
          rules={[
            { required: true, message: '必须填写名称' },
            { max: 50, message: '网站名称不能超过50个字' },
          ]}
        >
          <Input placeholder="请输入搜索引擎名称" />
        </Form.Item>
        <Form.Item
          name="url"
          label="URL"
          rules={[
            { required: true, message: '请输入URL' },
            { type: 'url', message: '请输入正确的URL' },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="请输入搜索引擎URL，使用%s替换关键词"
          />
        </Form.Item>
        <Form.Item label="类别">
          <Select value={category} onChange={handleCategoryChange}>
            {categoryOption.map((item) => (
              <Option value={item.cate_id} key={item.cate_id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="上传ICON">
          <div>
            <Upload {...upload_props}>
              <Button>
                <UploadOutlined /> 上传ICON
              </Button>
            </Upload>
            {icon ? (
              <div className="mt-15">
                <img
                  width="32"
                  src={'https://img.bidianer.com/' + icon}
                  alt=""
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFormModal;
