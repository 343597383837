import React, {Component} from 'react';
import {Table} from 'antd';
import {GET_CATEGORY_RANK} from '@/api/cool';
import coolCss from './style/index.module.css';

/**
 * 酷站榜单
 */
class Index extends Component {
    state = {
        list: [],
        pagination: {
            current: 1,
            pageSize: 100,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
        },
        loading: false,
    };

    componentDidMount() {
        this.getCoolRankListData();
    }

    // 获取表格数据列表
    getCoolRankListData = () => {
        this.setState({loading: true})
        GET_CATEGORY_RANK().then((rsp) => {
            this.setState({
                list: rsp.data.list.map((item,idx)=>{
                    item.idx=idx+1;
                    return item;
                }),
            });
        }).finally(() => {
            this.setState({loading: false})
        });
    };

    render() {
        const columns = [
            {
                title: '排名',
                dataIndex: 'idx',
                width: 80,
            },
            {
                title: '父级名称',
                dataIndex: 'parentName',
                width: 150,
                render:(parentName,record)=>{
                    return <p><i className={ `iconfont ${ record.iconfont }` }></i> {parentName}</p>
                }
            },
            {
                title: '名称',
                dataIndex: 'name',
            },
            {
                title: '访问量',
                dataIndex: 'visitAmount',
            },
        ];

        return (
            <div className={`flex ${coolCss.main}`}>
                <div className={coolCss.tableList}>
                    <div className="bg-white pd-10">
                        <Table
                            rowKey={(record) => record.id}
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                            columns={columns}
                            dataSource={this.state.list}
                            bordered
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
