import React from 'react';
import {message, Modal} from 'antd';
import PropTypes from 'prop-types';
import {GET_CATEGORY_LIST} from '@/api/category';
import {RECOMMEND_COOL} from '@/api/cool';
import coolCss from '@/pages/Cool/style/index.module.css';
import AddForm from "@/pages/Category/AddForm";
import {PlusCircleOutlined} from '@ant-design/icons';

class RecommendCool extends React.PureComponent {
    state = {
        activeCid: '',
        category: [],
        list: [],
        sour_id: '',
        loading: false,
        addCategoryVisible: false, // 添加分类弹框是否显示
        addCategoryPid: 0
    };

    componentDidUpdate(prevPros) {
        if (prevPros.visible !== this.props.visible) {
            if (this.props.visible) {
                this.getCategoryData();
            }
        }
    }

    // 获取分类列表
    getCategoryData = () => {
        GET_CATEGORY_LIST({alias: 'cool-common'}).then((rsp) => {
            let firstMenu = [];
            rsp.data.list.forEach(item => {
                if (item.child && item.child.length) {
                    firstMenu = [...firstMenu, ...item.child];
                }
            })
            this.setState({
                loading: false,
                category: firstMenu,
            });
        });
    };

    handleSubmit = (cid) => {
        let ids = this.props.ids;
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        RECOMMEND_COOL(ids, cid).then(rsp => {

            if (rsp.data.code === -1) {
                message.success('收藏成功：' + rsp.data.msg);
            } else {
                message.success('收藏成功');
            }
            this.props.onClose();
            this.props.onReload();
        })
    };

    handleCancel = () => {
        this.props.onClose();
    };

    handleAddCategoryShow = (pid) => {
        this.setState({
            addCategoryVisible: true,
            addCategoryPid: pid
        })
    }

    handleCloseAddCategory = () => {
        this.setState({
            addCategoryVisible: false
        })
    }

    render() {
        return (
            <div>
                <AddForm
                    visible={this.state.addCategoryVisible}
                    pid={this.state.addCategoryPid}
                    onCancel={this.handleCloseAddCategory}
                    reloadLists={this.getCategoryData}
                />
                <Modal
                    title="收藏网址"
                    footer={null}
                    width="1100px"
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                >
                    <div className={coolCss.recommendWrapper}>
                        {
                            this.state.category.map(item => {
                                return <div key={item.cate_id} className={coolCss.recommendItem}>
                                    <dt><i className={`iconfont ${item.iconfont}`}/>{item.name}
                                        <PlusCircleOutlined className={coolCss.addSubBtn}
                                                            onClick={() => this.handleAddCategoryShow(item.cate_id)}/>
                                    </dt>
                                    <dd className={`flex flex-wrap`}>
                                        {
                                            item.child.map(child => {
                                                return <div key={child.cate_id} className={coolCss.thirdWrapper}>
                                                    <span onClick={() => this.handleSubmit(child.cate_id)}
                                                          className={coolCss.thirdItem}>{child.name}</span>
                                                </div>;
                                            })
                                        }
                                    </dd>
                                </div>
                            })
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

RecommendCool.propTypes = {
    onReload: PropTypes.func,
};

export default RecommendCool;
