import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Input, message, Button } from 'antd';
import PropTypes from 'prop-types';
import Logo from '@/assets/img/logo.svg';

import LoginStyle from '@/assets/css/login.module.css';
import { LOGIN_IN } from "@/api/login";

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18, offset: 6 },
};

class LoginForm extends Component {
    state = {
        loading: false
    }

    handleSubmit = (values) => {
        const { email, password } = values;
        this.setState({ loading: true });
        LOGIN_IN(email, password).then((response) => {
            if (response.data.code === 1) {
                message.success('登录成功');
                localStorage.setItem('isGuest', 'user');
                window.location.reload();
            } else {
                message.error(response.data.msg);
            }
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    render() {
        return (
            <div className={ LoginStyle.login }>
                <div className={ LoginStyle.title }>
                    <div className="logo">
                        <img src={ Logo } width="64" alt=""/>
                    </div>
                    <h1 className="text-darker">笔点后台管理系统</h1>
                </div>
                <Form onFinish={ this.handleSubmit } className="normal-form">
                    <FormItem { ...formItemLayout } name="email" label="用户名"
                              rules={ [ { required: true, message: '请输入用户名' } ] }>
                        <Input placeholder="请输入用户名"/>
                    </FormItem>
                    <FormItem { ...formItemLayout } name="password" label="密码" rules={ [
                        { required: true, message: '请输入密码' }
                    ] }>
                        <Input type="password" placeholder="请输入密码"/>
                    </FormItem>
                    <FormItem { ...formTailLayout }>
                        <Button type="primary" loading={ this.state.loading } htmlType="submit"
                                className="login-form-button">
                            立即登录
                        </Button>
                    </FormItem>
                </Form>
            </div>
        )
    }
}

LoginForm.propTypes = {
    history: PropTypes.object
}

const WrappedNormalLoginForm = LoginForm;

export default withRouter(WrappedNormalLoginForm)
