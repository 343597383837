import React, { Component, Fragment } from "react";
import { Button, Form, Input, message, Modal, Table } from "antd";
import PropTypes from "prop-types";
import {
    GET_REVIEW_LIST,
    JOIN_SINGLE_BLACKLIST,
    REVIEW_SOURCE,
    TOGGLE_STAR,
} from "@/api/source";
import {REVIEW_HOST} from '@/api/icon';
import {
    SearchOutlined,
    CloseOutlined,
    StarOutlined,
    CheckOutlined,
    FolderOpenOutlined,
    ExclamationCircleOutlined,
    EditOutlined,
    FileSearchOutlined,
    CheckCircleFilled
} from "@ant-design/icons";
import { getQiNiuIconUrl } from "@/utils";
import moduleCss from "./style/index.module.css";
import RecommendCool from "@/pages/Source/components/RecommendCool";
import AddSource from "@/pages/Source/components/AddSource";
import IconAdd from "@/pages/Source/icon/Add";
import ReviewModal from "@/pages/Source/components/ReviewModal";

const FormItem = Form.Item;

/**
 * 审核网址
 * @class Add
 * @extends {Component}
 */
class Index extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `共计 ${total} 条`,
            size: "default",
        },
        search: {
            page: 1,
            size: 100,
        },
        // 酷站精选
        coolVisible: false,
        coolSourceId: "",
        // 编辑网址
        editSourceId: "", // 编辑网址id
        editSourceVisible: false, // 编辑网址弹框是否显示
        // icon
        editIconId: "",
        editIconVisible: false,
        hostReviewModalVisible: false, // 根据host审核弹框
        hotsReviewIconId: 0,
    };

    componentDidMount() {
        this.getListData();
    }

    // 获取网址列表
    getListData = () => {
        this.setState({ loading: true });
        const searchData = this.state.search;
        searchData.page = this.state.pagination.current;
        searchData.size = this.state.pagination.pageSize;

        GET_REVIEW_LIST(searchData)
            .then((response) => {
                let data = response.data;
                let pagination = this.state.pagination;
                pagination.total = data.total;
                this.setState({
                    list: data.list.map((item) => {
                        item.icon = getQiNiuIconUrl(item.icon);
                        return item;
                    }),
                    pagination,
                    loading: false,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    // 打开酷站收藏弹框
    handleCoolOpen = (sourceId) => {
        this.setState({ coolVisible: true, coolSourceId: sourceId });
    };

    // 关闭酷站弹框
    handleCoolClose = () => {
        this.setState({ coolVisible: false });
    };

    // 搜索
    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;

        this.setState({ search: values, pagination: pager }, () => {
            this.getListData();
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys: [],
        });

        window.scrollTo(0, 0);
        this.getListData();
    };

    // 网址审核通过（忽略）
    handleReviewPass = (sid) => {
        this.setState({ loading: true });
        REVIEW_SOURCE(sid).then(() => {
            this.handleFilterList(sid);
        });
    };

    // 批量忽略
    handleBatchReviewPass = () => {
        if (!this.state.selectedRowKeys.length) {
            message.error("请选择需要忽略的网址").then((r) => {});
            return;
        }

        this.handleReviewPass(this.state.selectedRowKeys);
    };

    // 过滤列表
    handleFilterList = (sid) => {
        if (!Array.isArray(sid)) {
            sid = [sid];
        }
        const list = this.state.list.filter((f) => !sid.includes(f.sid));
        this.setState({ selectedRowKeys: [] });
        if (!list.length) {
            this.getListData();
            return;
        }
        const pagination = this.state.pagination;
        pagination.total = pagination.total - sid.length;
        this.setState({ list, pagination, loading: false });
    };

    // 链接失效
    handleReviewHostFail = (iconId) => {
        this.setState({ loading: true });
        REVIEW_HOST(iconId, 'lose').then(() => {
            this.handleFilterListByIconId(iconId);
        });
    };

    // 通过icon过滤
    handleFilterListByIconId = (iconId) => {
        if (!Array.isArray(iconId)) {
            iconId = [iconId];
        }
        let deleteCount = 0;
        const list = this.state.list.filter((f) => {
            if (!iconId.includes(f.iconId)) {
                return true;
            }
            deleteCount++;
            return false;
        });
        this.setState({ selectedRowKeys: [] });
        if (!list.length) {
            this.getListData();
            return;
        }
        const pagination = this.state.pagination;
        pagination.total = pagination.total - deleteCount;
        this.setState({ list, pagination, loading: false });
    };

    handleBatchReviewFail = () => {
        if (!this.state.selectedRowKeys.length) {
            message.error("请选择需要批量通过审核的记录").then((r) => {});
            return;
        }

        const iconId = this.state.selectedRowKeys.map((item) => {
            const sourceItem = this.state.list.filter((f) => f.sid === item);
            if (sourceItem.length) {
                return sourceItem[0].iconId || "";
            }
            return 0;
        });
        this.handleReviewHostFail(iconId);
    };

    toggleStar = (sourceId) => {
        this.setState({ loading: true });
        TOGGLE_STAR(sourceId).then(() => {
            this.handleFilterList(sourceId);
        });
    };

    batchToggleStar = () => {
        this.setState({ loading: true });
        this.toggleStar(this.state.selectedRowKeys);
    };

    // 加入、取消黑名单
    handleJoinBlackList = (iconId) => {
        if (!Array.isArray(iconId)) {
            iconId = [iconId];
        }
        this.setState({ loading: true });
        REVIEW_HOST(iconId,'blacklist').then((rsp) => {
                if (rsp.data.code === 1) {
                    this.getListData();
                    message.success("操作成功").then((r) => {});
                } else {
                    message.error(rsp.data.msg).then((r) => {});
                }
            })
            .finally(() => {
                this.setState({ selectedRowKeys: [] });
            });
    };

    // 单个网址加入黑名单
    handleJoinSingleBlacklist = (sourceId) => {
        JOIN_SINGLE_BLACKLIST(sourceId).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                message.success("操作成功").then((r) => {});
            } else {
                message.error(rsp.data.msg).then((r) => {});
            }
        });
    };

    handleOpenAllLink = () => {
        this.state.list.forEach((item) => {
            window.open(item.url);
        });
    };

    // 批量加入黑名单
    handleBatchJoinBlacklist = () => {
        if (!this.state.selectedRowKeys.length) {
            message.error("请选择需要加入黑名单的网址");
            return;
        }
        Modal.confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否将此域名加入黑名单？加入后，此域名下的所有网址均无法公开展示！",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                const iconId = this.state.selectedRowKeys.map((item) => {
                    const sourceItem = this.state.list.filter((f) => f.sid === item);
                    if (sourceItem.length) {
                        return sourceItem[0].iconId || "";
                    }
                    return 0;
                });
                this.handleJoinBlackList(iconId);
            },
        });
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    // 编辑网址
    // 打开添加、编辑网址弹框
    handleAddSourceOpen = (sourceId) => {
        this.setState({ editSourceVisible: true, editSourceId: sourceId });
    };

    // 关闭添加、编辑网址弹框
    handleAddSourceClose = () => {
        this.setState({ editSourceVisible: false });
    };

    // 编辑Icon
    handleIconEdit = (id) => {
        this.setState({ editIconVisible: true, editIconId: id });
    };
    // 关闭icon编辑弹框
    handleIconClose = () => {
        this.setState({ editIconVisible: false });
    };

    // 打开host审核弹框
    handleHostReviewModalShow = (record) => {
        this.setState({
            hostReviewModalVisible: true,
            hotsReviewIconId: record.iconId,
        });
    };

    // 关闭host审核弹框
    handleHostReviewModalClose = () => {
        this.setState({
            hostReviewModalVisible: false,
        });
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            { title: "ID", dataIndex: "sid", width: 100 },
            {
                title: "图标",
                dataIndex: "icon",
                width: 70,
                render: (src, record) => {
                    return (
                        <div
                            className="table-cover"
                            title="更换图标"
                            onClick={() => this.handleIconEdit(record.iconId)}
                        >
                            <img width="32" src={src} alt="" />
                        </div>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                width: 300,
                render: (name, record) => {
                    return (
                        <Fragment>
                            <p>
                                <span>{name}</span>
                                <span
                                    className={moduleCss.nameEditBtn}
                                    onClick={() => this.handleAddSourceOpen(record.sid)}
                                >
                                    &nbsp;
                                    <EditOutlined />
                                </span>
                            </p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}
                            <p style={{ color: "#999", marginBottom: 0 }}>{record.createTime}</p>
                        </Fragment>
                    );
                },
            },
            /*{
                title: "操作",
                render: (record) => {
                    return (
                        <div>
                            {/!*<Button className={moduleCss.actionBtnPass} onClick={() => this.toggleStar(record.sid)}>
                                <StarOutlined />
                                精选
                            </Button>
                            <Button
                                className={moduleCss.actionBtnPass}
                                type="primary"
                                onClick={() => this.handleReviewPass(record.sid)}
                            >
                                <CheckOutlined />
                                审核通过
                            </Button>*!/}
                            <Button type="primary" className={moduleCss.actionBtnPass} onClick={() => this.handleHostReviewModalShow(record)}>
                                <FileSearchOutlined />
                                审核
                            </Button>
                        </div>
                    );
                },
            },*/
            {
                title: '酷站',
                dataIndex: 'inCool',
                render:(val)=>{
                    if (val) {
                        return <CheckCircleFilled style={{color: 'green'}} />
                    }
                }
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.summary}</p>
                            <p style={{ marginBottom: 0 }}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                                <span
                                    className={moduleCss.searchHost}
                                    onClick={() => {
                                        this.handleHostReviewModalShow(record);
                                    }}
                                >
                                    <FileSearchOutlined />
                                </span>
                            </p>
                        </div>
                    );
                },
            },
            {
                title: "点击",
                width: 120,
                dataIndex: "clickAmount",
            },
            {
                title: "操作",
                width: 320,
                render: (record) => {
                    return (
                        <div>
                            <Button
                                className={moduleCss.actionBtn}
                                onClick={() => this.handleReviewHostFail(record.iconId)}
                            >
                                <CloseOutlined />
                                链接失效
                            </Button>
                            <Button
                                className={moduleCss.actionBtn}
                                danger
                                onClick={() => this.handleJoinBlackList(record.iconId)}
                            >
                                黑名单
                            </Button>
                            <Button
                                type="dashed"
                                danger
                                className={moduleCss.actionBtn}
                                onClick={() => this.handleJoinSingleBlacklist(record.sid)}
                            >
                                单个黑名单
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <ReviewModal
                    visible={this.state.hostReviewModalVisible}
                    iconId={this.state.hotsReviewIconId}
                    onClose={this.handleHostReviewModalClose}
                    onReload={this.getListData}
                />
                <IconAdd
                    id={this.state.editIconId}
                    visible={this.state.editIconVisible}
                    onReload={this.getListData}
                    onClose={this.handleIconClose}
                />
                <AddSource
                    visible={this.state.editSourceVisible}
                    sid={this.state.editSourceId}
                    onReload={this.getListData}
                    onClose={this.handleAddSourceClose}
                />
                <RecommendCool
                    visible={this.state.coolVisible}
                    ids={this.state.coolSourceId}
                    onClose={this.handleCoolClose}
                    onReload={this.getListData}
                />
                <Form layout="inline" className="search-form-fixed" onFinish={this.handleSearch}>
                    <FormItem name="sour_id">
                        <Input placeholder="网站ID" style={{ width: 120 }} />
                    </FormItem>
                    <FormItem name="name">
                        <Input placeholder="网站名称" style={{ width: 120 }} allowClear />
                    </FormItem>
                    <FormItem name="sour_url">
                        <Input placeholder="网站URL" allowClear style={{ width: 200 }} />
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                            搜索
                        </Button>
                    </FormItem>
                    {/*<FormItem>
                        <Button
                            type="primary"
                            loading={this.state.loading}
                            onClick={this.handleBatchReviewPass}
                            disabled={!this.state.selectedRowKeys.length}
                            icon={<CheckOutlined />}
                        >
                            审核通过
                        </Button>
                    </FormItem>*/}
                    <FormItem>
                        <Button
                            type="primary"
                            danger
                            disabled={!this.state.selectedRowKeys.length}
                            loading={this.state.loading}
                            onClick={this.handleBatchJoinBlacklist}
                        >
                            加入黑名单
                        </Button>
                    </FormItem>
                    {/*<FormItem>
                        <Button loading={this.state.loading} onClick={this.batchToggleStar} icon={<StarOutlined />}>
                            精选
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button loading={this.state.loading} onClick={this.handleBatchReviewFail}>
                            <CloseOutlined />
                            失效
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button onClick={this.handleOpenAllLink}>
                            <FolderOpenOutlined />
                            批量打开
                        </Button>
                    </FormItem>*/}
                    <FormItem>
                        还剩<span className={moduleCss.surplusTotal}>{this.state.pagination.total}</span>条未审核
                    </FormItem>
                </Form>
                <Table
                    size="small"
                    rowKey="sid"
                    rowSelection={rowSelection}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                />
            </div>
        );
    }
}

Index.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default Index;
