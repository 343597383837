import React, {Component} from 'react';
import {Button, Table, Tag, Form, message, Upload, Input, Modal} from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import PropTypes from 'prop-types'
import {UploadOutlined} from '@ant-design/icons'

const FormItem = Form.Item;
const {TextArea} = Input;

class ViewReply extends Component {
  componentDidMount() {
    this.props.onRef(this)
  }

  state = {
    visible: false,
    user_id: '',
    thumb_pic: '',
    save_pic: '',
    selectedRowKeys: [],
    lists: []
  };

  showModal(user_id) {
    this.setState({
      user_id: user_id,
      visible: true
    }, () => this.loadLists());
  }

  loadLists = () => {
    let data = {user_id: this.state.user_id};
    axios.post('/log/feed-back', qs.stringify(data))
      .then((response) => {
        let data = response.data;
        this.setState({
          lists: data.lists,
        });
      })
      .catch(() => {
        //self.content.loading = false;
      });
  };

  handleSubmit = (values) => {
    values.img_url = this.state.save_pic;
    values.user_id = this.state.user_id;
    axios.post('/log/feed-back-reply', qs.stringify(values))
      .then((response) => {
        if (response.data.code === 1) {
          this.loadLists();
          this.setState({
            save_pic: '',
            thumb_pic: '',
          });
          //this.props.reloadLists();
        } else {
          message.error(response.data.msg);
        }
      })
      .catch(() => {
        //self.content.loading = false;
      });
  }

  handleCancel = () => {
    this.setState({visible: false});
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({selectedRowKeys});
  };

  afterClose = () => {
    this.props.reloadLists();
  };

  render() {
    const upload_props = {
      name: 'UploadForm[imageFiles]',
      action: '/upload/feedback',
      multiple: false,
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          this.setState({
            thumb_pic: info.file.response.thumb_url,
            save_pic: info.file.response.save_dir,
          });
          message.success(`上传成功`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 0},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 24},
      },
    };

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {title: 'ID', dataIndex: 'feed_id', width: 80},
      {title: '内容', dataIndex: 'content', width: 300},
      {
        title: '是否查看', dataIndex: 'is_view', render: (txt) => {
          if (parseInt(txt, 10) === 1) {
            return <Tag color="green">已查看</Tag>
          } else {
            return <Tag>未读</Tag>
          }
        }
      },
      {
        title: '类别', dataIndex: 'is_reply', render: (txt) => {
          if (parseInt(txt, 10) === 1) {
            return <Tag color="blue">官方回复</Tag>
          } else {
            return <Tag color="red">用户提问</Tag>
          }
        }
      },
      {title: '头像', dataIndex: 'user_headimg', render: (src) => <img width="32" src={src} alt=""/>},
      {title: '用户', dataIndex: 'user_nickname'},
      {title: '创建时间', dataIndex: 'create_time'},
    ];

    return (
      <div>
        <Modal
          title="添加"
          cancelText="关闭"
          okText="立即保存"
          width="1200px"
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          afterClose={this.afterClose}
        >
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} name="content" rules={[
              {required: true, message: '请输入回复内容'}
            ]}>
              <TextArea rows={4} placeholder="请输入回复内容"/>
            </FormItem>
            <FormItem {...formItemLayout}>
              <Upload {...upload_props}>
                <Button>
                  <UploadOutlined /> 上传ICON
                </Button>
              </Upload>
              <div className="mt-15" style={{display: this.state.thumb_pic.length > 0 ? 'block' : 'none'}}>
                <img width="120" src={this.state.thumb_pic} alt=""/>
              </div>
            </FormItem>
            <FormItem {...formItemLayout}>
              <Button
                htmlType="submit"
                type="primary">提交</Button>
            </FormItem>
          </Form>
          <Table
            pagination={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={this.state.lists}
            bordered
          />
        </Modal>
      </div>
    );
  }
}

ViewReply.propTypes = {
  onRef: PropTypes.func,
  reloadLists: PropTypes.func
}

export default ViewReply