import React, {Component} from 'react';
import {Button, Table, Form, Input, Modal, InputNumber, Tabs, message} from 'antd';
import PropTypes from 'prop-types'
import {SearchOutlined} from "@ant-design/icons";
import {
    CHOOSE_SOURCE_CHILDREN,
    GET_BASE_LIST,
    GET_SOURCE_CHILDREN,
    REMOVE_SOURCE_CHILDREN
} from "@/api/source";
import {getQiNiuIconUrl} from "@/utils";
import CategorySourceRemark from '../components/CategorySourceRemark';

const FormItem = Form.Item;
const {TabPane} = Tabs;

class Details extends Component {
    formRef = React.createRef();

    state = {
        list: [],
        loading: false,
        remarkVisible: false,
        remarkId: '',
        remarkContent: '',
        remarkDesc: '',
        searchData: {},
        pagination: {
            current: 1,
            pageSize: 10,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`
        },
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && (prevProps.visible !== this.props.visible)) {
            this.getChildrenList()
        }
    }

    // 搜索网址
    handleSearch = (values) => {
        this.setState({loading: true, searchData: values});
        this.getSourceList();
    }

    // 获取基础网址用于添加到子网址
    getSourceList = () => {
        GET_BASE_LIST({page: this.state.pagination.current, ...this.state.searchData}).then((response) => {
            let data = response.data;
            let pagination = this.state.pagination;
            pagination.total = data.total;
            pagination.pageSize = data.size;
            this.setState({
                list: data.list.map(item => {
                    item.icon = getQiNiuIconUrl(item.icon);
                    return item;
                }),
                pagination
            });
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    // 表格点击分页
    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        this.getSourceList();
    };

    // 获取子网址列表
    getChildrenList = () => {
        this.setState({loading: true});
        GET_SOURCE_CHILDREN({parentId: this.props.parentId}).then(rsp => {
            this.setState({
                list: rsp.data.list
            });
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    handleCancel = () => {
        this.props.onClose();
    };

    handleReload = () => {
        this.props.onReload();
    };

    // tab切换操作
    handleTabChange = (value) => {
        if (value === 'manage') {
            this.getChildrenList();
        } else {
            this.setState({list: []});
            if (!this.formRef.current) return false;
            this.formRef.current.resetFields();
        }
    }

    handleChildrenAdd = (sid) => {
        CHOOSE_SOURCE_CHILDREN({parentId: this.props.parentId, sourceId: sid}).then(rsp => {
            if (rsp.data.code === 1) {
                message.success('选择、添加成功');
            } else {
                message.error(rsp.data.msg);
            }
        });
    }

    // 移除子网址
    handleChildrenRemove = (childrenId) => {
        REMOVE_SOURCE_CHILDREN({childrenId}).then(rsp => {
            if (rsp.data.code === 1) {
                message.success('移除成功');
                this.getChildrenList()
            } else {
                message.error(rsp.data.msg);
            }
        })
    }

    handleRemark = (id, content, desc) => {
        this.setState({
            remarkVisible: true,
            remarkId: id,
            remarkContent: content,
            remarkDesc: desc
        });
    };

    // 关闭备注弹框
    handleRemarkCancel = () => {
        this.setState({remarkVisible: false});
    };

    render() {
        const columns = [
            {title: 'BS ID', dataIndex: 'id', width: 100},
            {
                title: '图标',
                dataIndex: 'icon',
                width: 70,
                render: (src) => <img width="32" src={src} alt=""/>,
            },
            {
                title: '名称',
                dataIndex: 'name',
                render: (name, record) => {
                    return <p><span>{name}</span>{record.remark ? <span>（备注：{record.remark}）</span> : ''}</p>
                }
            },
            {
                title: 'URL',
                dataIndex: 'url',
                width: 250
            },
            {title: '点击次数', dataIndex: 'clickAmount'},
            {title: '点击时间', dataIndex: 'clickTime'},
            {
                title: '操作',
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button
                                type="link"
                                onClick={() => this.handleRemark(record.id, record.remark, record.note)}
                            >
                                备注
                            </Button>
                            <Button
                                type="link"
                                danger
                                onClick={() => this.handleChildrenRemove(record.id)}
                            >
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        const columnsAdd = [
            {title: 'source id', dataIndex: 'sid', width: 100},
            {
                title: '图标',
                dataIndex: 'icon',
                width: 70,
                render: (src) => <img width="32" src={src} alt=""/>,
            },
            {
                title: '名称',
                dataIndex: 'name',
            },
            {
                title: 'URL',
                dataIndex: 'url',
            },
            {title: '点击次数', dataIndex: 'clickAmount'},
            {title: '创建时间', dataIndex: 'createTime'},
            {
                title: '操作',
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button
                                type="link"
                                onClick={() => this.handleChildrenAdd(record.sid)}
                            >
                                添加
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <CategorySourceRemark
                    visible={this.state.remarkVisible}
                    id={this.state.remarkId}
                    remark={this.state.remarkContent}
                    desc={this.state.remarkDesc}
                    onReload={this.getChildrenList}
                    onCancel={this.handleRemarkCancel}
                />
                <Modal
                    title="添加"
                    cancelText="关闭"
                    okText="立即保存"
                    width="1100px"
                    footer={null}
                    visible={this.props.visible}
                    onOk={this.handleSubmit}
                    afterClose={this.handleReload}
                    onCancel={this.handleCancel}
                >
                    <Tabs defaultActiveKey="manage" onChange={this.handleTabChange}>
                        <TabPane tab="管理" key="manage">
                            <Table
                                rowKey={record => record.id}
                                pagination={false}
                                columns={columns}
                                dataSource={this.state.list}
                                bordered
                                loading={this.state.loading}
                            />
                        </TabPane>
                        <TabPane tab="添加" key="add">
                            <Form layout="inline" ref={this.formRef} className="mb-15" onFinish={this.handleSearch}>
                                <FormItem name="sour_id">
                                    <InputNumber placeholder="网站ID"/>
                                </FormItem>
                                <FormItem name="name">
                                    <Input placeholder="网站名称"/>
                                </FormItem>
                                <FormItem name="summary">
                                    <Input placeholder="网站简介"/>
                                </FormItem>
                                <FormItem name="sour_url">
                                    <Input placeholder="网站URL"/>
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                                        搜索
                                    </Button>
                                </FormItem>
                            </Form>

                            <Table
                                rowKey={record => record.sid}
                                pagination={this.state.pagination}
                                onChange={this.handleTableChange}
                                columns={columnsAdd}
                                dataSource={this.state.list}
                                bordered
                                loading={this.state.loading}
                            />
                        </TabPane>
                    </Tabs>

                </Modal>
            </div>
        );
    }
}

Details.propTypes = {
    visible: PropTypes.bool,
    parentId: PropTypes.string,
    onClose: PropTypes.func,
    onReload: PropTypes.func,
}

export default Details
