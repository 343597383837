import React, { Component } from 'react';
import { Form, message, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { SEND_MESSAGE } from '@/api/member';

const FormItem = Form.Item;
const { TextArea } = Input;

class Add extends Component {
  formRef = React.createRef();

  componentDidMount() {
    this.props.onRef(this)
  }

  state = {
    visible: false,
    user_id: '',
    loading: false
  };

  showModal(user_id) {
    this.setState({
      visible: true,
      user_id: user_id
    });
  }

  handleSubmit = (values) => {
    values.user_id = this.state.user_id;
    this.setState({ loading: true })
    SEND_MESSAGE(values).then((response) => {
      if (response.data.code === 1) {
        if (!this.state.user_id) {
          this.props.reloadLists();
        }
        message.success('发送成功~');
      } else {
        message.error(response.data.msg);
      }
    }).finally(() => {
      this.setState({ visible: false, loading: false });
    })
  };

  // 点击弹框确定按钮
  handleOk = () => {
    this.formRef.current.submit();
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    return (
      <div>
        <Modal
          title="群发消息"
          cancelText="关闭"
          okText="立即发送"
          visible={this.state.visible}
          confirmLoading={this.state.loading}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            <FormItem {...formItemLayout} name="content" label="消息内容" rules={[
              { required: true, message: '请输入消息内容' }
            ]}>
              <TextArea rows={10} placeholder="请输入消息内容"/>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

Add.propTypes = {
  onRef: PropTypes.func,
  reloadLists: PropTypes.func
}
export default Add
