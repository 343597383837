import React, {Component} from 'react';
import {Table, Button, message, Tag, Modal, Popover, Input, Form} from 'antd';
import {
    ExclamationCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    EditOutlined,
    StarOutlined,
    CloseOutlined, FileSearchOutlined, FolderOpenOutlined, SearchOutlined
} from '@ant-design/icons';
import {
    CLEAR_VALUE,
    COVER_BASE_ICON,
    COVER_BASE_NAME, COVER_BASE_NOTE,
    GET_SOURCE_LIST,
    REMOVE_COOL_SOURCE, REVIEW_COOL_SIMPLE,
} from '@/api/cool';
import moduleCss from "@/pages/Source/style/index.module.css";
import {getQiNiuAvatar, getQiNiuIconUrl} from "@/utils";
import {CRAWLER_SOURCE_INFO} from "@/api/source";
import RecommendCool from "@/pages/Source/components/RecommendCool";
import RepeatModal from "@/pages/Cool/RepeatModal";
import {REVIEW_HOST} from "@/api/icon";

const {confirm} = Modal;
const FormItem = Form.Item;

/**
 * 待审核的酷站网址列表
 */
class Index extends Component {
    state = {
        loading: false, // 页面加载状态
        selectedRowKeys: [],
        sourceList: [],
        pagination: {
            current: 1,
            pageSize: 5,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
        },
        search: {},
        editNamePopoverValue: '',
        editNamePopoverId: '',
        editNamePopoverSlogan: false,
        editNoteValue: '',
        crawlerLoading: false,

        coolVisible: false,
        coolSourceId: '',
        repeatVisible: false,
        repeatUrl: ''
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && (prevProps.visible !== this.props.visible)) {
            this.getReviewListData();
        }
    }

    getReviewListData = () => {
        this.setState({loading: true});
        const data = {
            wd:  this.state.search.wd,
            page: this.state.pagination.current,
            size: this.state.pagination.pageSize,
            cid: '',
            status: 0,
        };

        GET_SOURCE_LIST(data).then((rsp) => {
            const pagination = this.state.pagination;
            pagination.total = rsp.data.total;
            const list = rsp.data.list.map((item, index) => {
                item.idx = (pagination.current - 1) * pagination.pageSize + 1 + index;
                item.renderIcon = getQiNiuIconUrl(item.renderIcon);
                item.avatar = getQiNiuAvatar(item.avatar);
                return item;
            });

            this.setState({
                sourceList: list,
                pagination,
            });
        }).finally(() => {
            this.setState({loading: false, selectedRowKeys: []});
        });
    }

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getReviewListData();
    };

    // 网址失效
    handleLoseEfficacy = (iconId) => {
        REVIEW_HOST(iconId, 'lose').then(() => {
            this.getReviewListData();
        });
    }

    // 加入黑名单
    handleJoinBlacklist = (iconId) => {
        REVIEW_HOST(iconId, 'blacklist').then(() => {
            this.getReviewListData();
        })
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleCoverName = (csId, name) => {
        COVER_BASE_NAME(csId, name).then(() => {
            this.getReviewListData();
        })
    }

    handleCoverNote = (csId, note) => {
        COVER_BASE_NOTE(csId, note).then(() => {
            this.getReviewListData();
        })
    }

    handleUpdateInputChange = (e) => {
        this.setState({editNamePopoverValue: e.target.value});
    }

    handleUpdateSloganChange = (e) => {
        this.setState({editNamePopoverSlogan: e.target.value});
    }

    handleEditNoteChange = (e) => {
        this.setState({editNoteValue: e.target.value});
    }

    handleEditNoteClick = (csId) => {
        COVER_BASE_NOTE(csId, this.state.editNoteValue).then(() => {
            this.getReviewListData();
            this.setState({
                editNoteValue: ''
            })
        })
    }

    handleEditNameClick = () => {
        COVER_BASE_NAME(this.state.editNamePopoverId, this.state.editNamePopoverValue, this.state.editNamePopoverSlogan).then(() => {
            this.setState({
                editNamePopoverValue: '',
                editNamePopoverSlogan: ''
            })
            this.getReviewListData();
        })
    }

    handleEditNamePopoverShow = (open, csId, value, slogan) => {
        if (open) {
            this.setState({
                editNamePopoverId: csId,
                editNamePopoverValue: value,
                editNamePopoverSlogan: slogan
            });
        }
    }

    handleEditNotePopoverShow = (open, note) => {
        if (!open) return;
        this.setState({
            editNoteValue: note
        })
    }

    // 抓取信息
    crawlerWebInfo = (url) => {
        this.setState({crawlerLoading: true});
        CRAWLER_SOURCE_INFO(url).then(rsp => {
            this.setState({
                editNoteValue: rsp.data.description
            })
        }).finally(() => {
            this.setState({crawlerLoading: false})
        })
    }

    // 酷站快审
    handleReviewSimple = (csId) => {
        const item = this.state.sourceList.find(f => f.csId === csId);
        if (!item.parentCategoryName) {
            message.error('请先选择分类');
            return;
        }
        REVIEW_COOL_SIMPLE(csId).then(() => {
            this.getReviewListData();
        })
    }

    // 打开酷站收藏弹框
    handleCoolOpen = (sourceId) => {
        this.setState({coolVisible: true, coolSourceId: sourceId});
    };

    // 关闭酷站弹框
    handleCoolClose = () => {
        this.setState({coolVisible: false});
    };

    handleCoverIcon = (csId) => {
        COVER_BASE_ICON(csId).then(() => {
            this.getReviewListData();
        })
    }

    handleRemarkClear = (csId, type) => {
        CLEAR_VALUE(csId, type).then(() => {
            this.getReviewListData();
        })
    }

    showRepeatModal = (url) => {
        this.setState({
            repeatUrl: url,
            repeatVisible: true
        })
    }

    hideRepeatModal = () => {
        this.setState({
            repeatVisible: false
        })
    }

    // 从酷站中移除该网址
    handleRemove = (id) => {
        confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '是否从酷站中移除该网址？此操作不会删除基础网址！',
            onOk: () => {
                REMOVE_COOL_SOURCE({id}).then((response) => {
                    if (response.data.code === 1) {
                        this.getReviewListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    handleOpenAllLink = () => {
        this.state.sourceList.forEach(item => {
            window.open(item.url);
        })
    }

    // 批量快审
    handleBatchReview = () => {
        let selectedRowKeys = this.state.selectedRowKeys;
        if (!selectedRowKeys.length) {
            selectedRowKeys = this.state.sourceList.map(item => item.csId);
        }
        REVIEW_COOL_SIMPLE(selectedRowKeys).then(() => {
            this.getReviewListData();
        })
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;

        this.setState({search: values, pagination: pager}, () => {
            this.getReviewListData();
        });
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const popoverContent = <div>
            <p><Input style={{width: '240px'}} placeholder="请输入网址标题" value={this.state.editNamePopoverValue}
                      onChange={this.handleUpdateInputChange}/></p>
            <p><Input style={{width: '240px'}} placeholder="请输入Slogan" value={this.state.editNamePopoverSlogan}
                      onChange={this.handleUpdateSloganChange}/></p>
            <p><Button type="primary" onClick={this.handleEditNameClick}>保存</Button></p>
        </div>

        const popoverNoteContent = (csId, url) => {
            return <div>
                <p><Button className={moduleCss.popoverCrawlerBtn} loading={this.state.crawlerLoading} type="primary"
                           onClick={() => this.crawlerWebInfo(url)}>抓取</Button></p>
                <p>
                    <Input style={{width: '300px'}} placeholder="简介"
                           value={this.state.editNoteValue}
                           onChange={this.handleEditNoteChange}/>
                    <Button className={moduleCss.popoverContentBtn} type="primary"
                            onClick={() => this.handleEditNoteClick(csId)}>保存</Button>
                </p>
            </div>
        }

        const columns = [
            {title: 'SID', dataIndex: 'sid', width: 100},
            {
                title: '图标',
                dataIndex: 'baseIcon',
                width: 70,
                render: (src) => {
                    return (
                        <div className="table-cover">
                            <img width="32" src={src} alt=""/>
                        </div>
                    );
                },
            },
            {
                title: '图标',
                dataIndex: 'userUploadIcon',
                width: 70,
                render: (src, record) => {
                    return (
                        <div className="table-cover" onClick={() => this.handleCoverIcon(record.csId)}>
                            <img width="32" src={src} alt=""/>
                        </div>
                    );
                },
            },
            {
                title: '名称',
                dataIndex: 'baseName',
                render: (name, record) => {
                    return (
                        <div>
                            <p>{name} <Popover content={popoverContent}
                                               onOpenChange={(open) => this.handleEditNamePopoverShow(open, record.csId, name, record.slogan)}
                                               title="修改名称"
                                               trigger="click">
                                <EditOutlined/>
                            </Popover></p>
                            {
                                record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null
                            }
                            {
                                record.remark ? <p>备注：{record.remark} <span className={moduleCss.remarkCoverName}
                                                                              onClick={() => this.handleCoverName(record.csId, record.remark)}><CheckOutlined/></span>
                                    <span onClick={() => this.handleRemarkClear(record.csId, 'remark')}
                                          className={moduleCss.clearBtn}><CloseOutlined/></span>
                                </p> : ''
                            }
                        </div>
                    );
                },
            },
            {
                title: '快审',
                width: 50,
                dataIndex: 'csId',
                render: (csId) => {
                    return <p className={moduleCss.simpleReviewBtn} onClick={() => this.handleReviewSimple(csId)}>
                        <CheckOutlined/></p>
                }
            },
            {
                title: 'URL',
                dataIndex: 'url',
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.originSummary} <Popover content={popoverNoteContent(record.csId, txt)}
                                                               onOpenChange={(open) => this.handleEditNotePopoverShow(open, record.originSummary)}
                                                               title="抓取信息" trigger="click">
                                <EditOutlined/>
                            </Popover></p>
                            {
                                record.note ? <p>用户备注：{record.note} <span className={moduleCss.remarkCoverName}
                                                                              onClick={() => this.handleCoverNote(record.csId, record.note)}><CheckOutlined/></span>
                                    <span onClick={() => this.handleRemarkClear(record.csId, 'note')}
                                          className={moduleCss.clearBtn}><CloseOutlined/></span>
                                </p> : null
                            }

                            <p>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                                <span style={{paddingLeft: '5px'}} className={moduleCss.repeatBtn}
                                      onClick={() => this.showRepeatModal(record.url)}>
                                    <FileSearchOutlined/>
                                </span>
                            </p>
                        </div>
                    );
                },
            },
            {
                title: '酷站',
                render: (record) => {
                    return record.parentCategoryName ?
                        <Button type="text" onClick={() => this.handleCoolOpen(record.sid)}>
                            <i className={`${moduleCss.coolCategoryIcon} iconfont ${record.parentCategoryIcon}`}/> {record.parentCategoryName} - {record.categoryName}
                        </Button> : <Button type="link" onClick={() => this.handleCoolOpen(record.sid)}>
                            <StarOutlined/>收藏
                        </Button>;
                },
            },
            {
                title: 'HOST状态', dataIndex: 'blacklist', render: (blacklist, record) => {
                    if (!record.review) return <Tag>未审核</Tag>
                    // 审核中
                    if (record.review === 4) return <Tag color='orange'>出错了</Tag>
                    if (record.review === 5) return <Tag color='magenta'>链接失效</Tag>
                    // 已审核
                    if (blacklist) {
                        return <Tag icon={<CloseCircleOutlined/>} color="error">黑名单</Tag>
                    } else if (record.oneBlacklist) {
                        return <Tag icon={<CloseCircleOutlined/>} color="error">黑名单 (单)</Tag>
                    } else {
                        return <Tag icon={<CheckCircleOutlined/>} color="success">正常</Tag>
                    }
                }
            },
            {
                title: '操作',
                width: 180,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleJoinBlacklist(record.iconId)}>黑名单</Button>
                            <Button type="link" onClick={() => this.handleLoseEfficacy(record.iconId)}>失效</Button>
                            <Button type="link" onClick={() => this.handleRemove(record.csId)} danger>
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <RepeatModal
                    visible={this.state.repeatVisible}
                    url={this.state.repeatUrl}
                    onClose={this.hideRepeatModal}
                    onReload={this.getReviewListData}
                />
                <RecommendCool
                    visible={this.state.coolVisible}
                    ids={this.state.coolSourceId}
                    onClose={this.handleCoolClose}
                    onReload={this.getReviewListData}
                />
                <Modal style={{top: 0}}
                       width='100%'
                       title="审核酷站"
                       cancelText="关闭"
                       okText="立即保存"
                       open={this.props.visible}
                       onCancel={this.handleCancel}
                       footer={null}
                       maskClosable={false}
                       confirmLoading={this.state.loading}
                >
                    <Form layout="inline" className="search-form-fixed" onFinish={this.handleSearch}>
                        <FormItem name="wd">
                            <Input placeholder="搜索名称 / URL" style={{width: 300}}/>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                                搜索
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" onClick={this.handleBatchReview}>
                                <CheckOutlined/>已审核
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button onClick={this.handleOpenAllLink}>
                                <FolderOpenOutlined/>批量打开
                            </Button>
                        </FormItem>
                    </Form>
                    <Table
                        size="small"
                        rowKey={(record) => record.csId}
                        rowSelection={rowSelection}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.sourceList}
                        bordered
                        loading={this.state.loading}
                    />
                </Modal>
            </div>
        );
    }
}

export default Index;
