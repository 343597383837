import request from "@/api/request";

// 提交酷站审核结果
export const GET_NAV_GROUP = () => {
    return request.get("/nav/nav-group", {});
};

// 提交酷站审核结果
export const GET_NAV_ITEM = (id) => {
    return request.get("/nav/nav-item", { id });
};

// 添加、保存首页导航条分组数据
export const SAVE_GROUP_DATA = (id, title, icon) => {
    return request.post("/nav/add-group", { id, title, icon });
};

// 删除首页导航条分组记录
export const DELETE_GROUP = (id) => {
    return request.post("/nav/delete-group", { id });
};

// 添加、保存首页导航条分组数据
export const SAVE_ITEM_DATA = (id, groupId, title, url, color) => {
    return request.post("/nav/add-item", { id, groupId, title, url, color });
};

// 删除首页导航条分组选项记录
export const DELETE_ITEM = (id) => {
    return request.post("/nav/delete-item", { id });
};

// 分组下的选项排序
export const SORT_ITEM = (sort) => {
    return request.post("/nav/sort-item", { sort });
};

// 分组下的选项排序
export const SORT_GROUP = (sort) => {
    return request.post("/nav/sort-group", { sort });
};
