import React, {useState, useEffect} from 'react'
import {Editor, Toolbar} from '@wangeditor/editor-for-react';
import {Button, Input, Radio, message} from 'antd';
import {GET_CATEGORY_LIST} from "@/api/category";
import QueryString from "query-string";
import {useLocation} from 'react-router-dom';
import {SUBMIT_HELP_POST, GET_POST_DETAIL} from "@/api/help";

// import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'

function MyEditor() {
    // editor 实例
    const [id, setId] = useState(0);
    // const [editor, setEditor] = useState<IDomEditor | null>(null)   // TS 语法
    const [editor, setEditor] = useState(null)                   // JS 语法
    const [title, setTitle] = useState('');
    // 编辑器内容
    const [html, setHtml] = useState('')
    const [loading, setLoading] = useState(false);
    const [postType, setPostType] = useState('privacy');

    const [category, setCategory] = useState([]);
    const params = useLocation();

    // 模拟 ajax 请求，异步设置 html
    useEffect(() => {
        // getPostContent(postType)
        getCategoryList()
    }, []);

    function getCategoryList() {
        const queryParams = QueryString.parse(params.search);
        GET_CATEGORY_LIST({alias: 'help_center'}).then(rsp => rsp.data.list).then(data => {
            setCategory(data);
            if (queryParams.id) {
                setId(queryParams.id);
            } else {
                setPostType(data[0].cate_id);
            }
        })
    }

    useEffect(() => {
        getPostContent()
    }, [id]);

    function getPostContent() {
        if (!id) return;
        GET_POST_DETAIL(id).then(rsp => {
            const {cid, title, content} = rsp.data;
            setPostType(cid);
            setTitle(title);
            setHtml(content);
        });
    }

    const onSubmit = () => {
        setLoading(true)
        SUBMIT_HELP_POST(id, postType, title, html).then((rsp) => {
            if (rsp.data.code === -1) {
                message.error(rsp.data.msg);
            } else {
                message.success('保存成功');
                window.open(`/#/help?cid=${postType}`, '_self');
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    // 工具栏配置
    // const toolbarConfig: Partial<IToolbarConfig> = { }  // TS 语法
    const toolbarConfig = {}                        // JS 语法

    toolbarConfig.excludeKeys = [
        'fontSize',
        'fontFamily',
        'lineHeight',
        'emotion',
        'fullScreen',
        'group-video',
        'undo',
        'redo',
        'divider'
    ]

    // 编辑器配置
    // const editorConfig: Partial<IEditorConfig> = {    // TS 语法
    const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }

    editorConfig.MENU_CONF['uploadImage'] = {
        server: '/api/help/upload',
        fieldName: 'UploadHelpImage[imageFiles]'
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    function handleTitleChange(val) {
        setTitle(val.target.value);
    }

    function onPostTypeChange(val) {
        setPostType(val.target.value);
    }

    return (
        <>
            <div>
                <Radio.Group onChange={onPostTypeChange} value={postType} style={{marginBottom: 10}}>
                    {
                        category.map(item => {
                            return <Radio value={item.cate_id} key={item.cate_id}>{item.name}</Radio>
                        })
                    }
                </Radio.Group>
            </div>
            <div>
                <Input placeholder="请输入标题" value={title} onChange={handleTitleChange}
                       style={{marginBottom: 10, width: '900px'}}/>
            </div>
            <div style={{border: '1px solid #ccc', width: '900px', zIndex: 100}}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{borderBottom: '1px solid #ccc'}}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => setHtml(editor.getHtml())}
                    mode="default"
                    style={{height: '800px', overflowY: 'hidden'}}
                />
            </div>
            <Button type="primary" style={{marginTop: 10}} loading={loading} onClick={onSubmit}>保存</Button>
        </>
    )
}

export default MyEditor
