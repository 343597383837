import React, { Component } from 'react';
import { Table, Button, Checkbox ,Tag, Form, Input } from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import ViewReply from './ViewReply'

const FormItem = Form.Item;

class FeedBack extends Component{
    state = {
        lists:[],
        selectedRowKeys: [],
        pagination: {
            current:1,
            showQuickJumper:true
        },
        iconLoading:false,
        search:{},
        only_user_ask:false
    };

    componentDidMount(){
        this.getLists();
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    getLists = () => {
        let data = this.state.search;
        data.only_user = this.state.only_user_ask ? 1 : 0;
        data.page = this.state.pagination.current;
        axios.post('/log/feed-back',qs.stringify(data))
            .then( (response) => {
                let data = response.data;
                let pager = this.state.pagination;
                pager.total =  data.total;
                pager.pageSize = data.page_size;
                this.setState({
                    lists:response.data.lists,
                    pagination : pager
                });
            });
    }

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys:[]
        });

        window.scrollTo(0,0);
        this.getLists();
    }

    handleSubmit = (values) => {
      const pager = this.state.pagination;
      pager.current = 1;
      this.setState({
        search:values,
        pagination:pager,
        selectedRowKeys:[]
      }, () => this.getLists());
    };

    choseOnlyUserAsk = (e) => {
        let checked = e.target.checked;
        this.setState({
            only_user_ask:checked
        });
    };

    onRef = (ref) => {
        this.child = ref;
    }

    ViewReply = (user_id) => {
        this.child.showModal(user_id);
    };

    render(){
        const rowSelection = {
            selectedRowKeys : this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            {title: 'ID', dataIndex: 'feed_id', width : 80},
            {title: '内容', dataIndex: 'content',width:400,render:(txt,record)=>{
                return (<span>{txt}（<a href="Javascript:void(0);" onClick={()=>this.ViewReply(record.user_id)}>查看对话</a>）</span>)
                }},
            {title: '是否查看', dataIndex: 'is_view',render:(txt) => {
                if (parseInt(txt,10) === 1) {
                    return <Tag color="green">已查看</Tag>
                } else {
                    return <Tag>未读</Tag>
                }
            }},
            {title: '类别', dataIndex: 'is_reply', render:(txt) => {
                if (parseInt(txt,10)===1) {
                    return <Tag color="blue">官方回复</Tag>
                } else {
                    return <Tag color="red">用户提问</Tag>
                }
            }},
            {title: '头像', dataIndex: 'user_headimg', render: (src) => <img width="32" src={src} alt="" />},
            {title: '用户', dataIndex: 'user_nickname'},
            {title: '创建时间', dataIndex: 'create_time'}
        ];

        return (
            <div>
                <ViewReply reloadLists={this.getLists} onRef={this.onRef} />
                <div className="lists-toolbar">
                    <Form layout="inline" onSubmit={this.handleSubmit}>
                        <FormItem name="content">
                          <Input placeholder="搜索内容" />
                        </FormItem>
                        <FormItem>
                            <Checkbox
                                checked={this.state.only_user_ask}
                                onChange={this.choseOnlyUserAsk}>仅看用户提问</Checkbox>
                        </FormItem>
                        <FormItem>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon="search"
                            >
                                搜索
                            </Button>
                        </FormItem>
                    </Form>
                </div>
                <Table
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.state.lists}
                    bordered
                />
            </div>
        );
    }
}

export default FeedBack