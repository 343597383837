import React, { Component } from "react";
import { Button, Form, Table, Modal, InputNumber } from "antd";
import PropTypes from "prop-types";
import { GET_NAV_ITEM, DELETE_ITEM, SORT_ITEM } from "@/api/common";
import { PlusOutlined, ExclamationCircleOutlined, SortAscendingOutlined } from "@ant-design/icons";
import AddNavItemModal from "@/pages/Other/components/AddNavItemModal";
import QueryString from "query-string";

const FormItem = Form.Item;

/**
 * 审核网址
 * @class Add
 * @extends {Component}
 */
class Index extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        list: [],
        editItemId: 0,
        editItemVisible: false,
        editItemData: {},
        groupId: 0,
        hasUpdateSort: false,
    };

    componentDidMount() {
        const queryParams = QueryString.parse(this.props.location.search);
        this.setState({ groupId: queryParams.id }, () => {
            this.getListData();
        });
    }

    // 获取网址列表
    getListData = () => {
        this.setState({ loading: true });
        GET_NAV_ITEM(this.state.groupId)
            .then((response) => {
                let data = response.data;
                this.setState({
                    list: data.list,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    showAddItemModal = (id) => {
        let data = {};
        if (id) {
            data = this.state.list.find((f) => f.id === id);
        }
        this.setState({
            editItemVisible: true,
            editItemId: id,
            editItemData: data,
        });
    };

    hideAddItemModal = () => {
        this.setState({
            editItemVisible: false,
        });
    };

    deleteItem = (id) => {
        Modal.confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否删除当前选项？删除后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                DELETE_ITEM(id).then(() => {
                    this.getListData();
                });
            },
        });
    };

    // 处理修改sort输入框数字操作
    handleSortChange = (sort, id) => {
        const list = this.state.list;
        list.forEach((item, index) => {
            if (parseInt(item.id, 10) === parseInt(id, 10)) {
                list[index].sort = sort;
                this.setState({
                    list,
                    hasUpdateSort: true,
                });
            }
        });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SORT_ITEM(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    };

    render() {
        const columns = [
            { title: "ID", dataIndex: "id", width: 100 },
            {
                title: "标题",
                dataIndex: "title",
            },
            {
                title: "color",
                dataIndex: "color",
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (url) => {
                    return (
                        <a href={url} target="_blank">
                            {url}
                        </a>
                    );
                },
            },
            {
                title: "排序",
                dataIndex: "sort",
                render: (sort, record) => (
                    <InputNumber
                        key={record.id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.id)}
                    />
                ),
            },
            {
                title: "操作",
                width: 320,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.showAddItemModal(record.id)}>
                                编辑
                            </Button>
                            <Button danger type="link" onClick={() => this.deleteItem(record.id)}>
                                删除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <AddNavItemModal
                    visible={this.state.editItemVisible}
                    groupId={this.state.groupId}
                    id={this.state.editItemId}
                    data={this.state.editItemData}
                    onClose={this.hideAddItemModal}
                    onReload={this.getListData}
                />
                <Form layout="inline" className="search-form-fixed">
                    <FormItem>
                        <Button
                            type="primary"
                            loading={this.state.loading}
                            onClick={() => {
                                this.showAddItemModal(null);
                            }}
                            icon={<PlusOutlined />}
                        >
                            添加新选项
                        </Button>
                    </FormItem>
                    <Form.Item>
                        <Button onClick={this.handleUpdateSort} icon={<SortAscendingOutlined />}>
                            {this.state.hasUpdateSort ? "更新排序" : "整理排序"}
                        </Button>
                    </Form.Item>
                </Form>
                <Table
                    rowKey="id"
                    loading={this.state.loading}
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                />
            </div>
        );
    }
}

Index.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default Index;
