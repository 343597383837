import React from 'react';

import {
  GET_ENGINE_SHORTCUT,
  REMOVE_SHORTCUT,
  SHORTCUT_SORT,
} from '@/api/engine';
import { InputNumber, Table, message, Modal, Button, Form } from 'antd';
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import RemarkEngine from './components/RemarkEngine';

/**
 * 获取网站默认的常用搜索引擎选项
 */
class Shortcut extends React.Component {
  state = {
    selectedRowKeys: [],
    pagination: {
      current: 1,
      pageSize: 30,
      total: 0,
      showQuickJumper: true,
    },
    loading: false,
    list: [],
    hasUpdateSort: false,
    remarkVisible: false,
    remarkItem: {
      unId: '',
      remark: '',
    },
  };

  componentDidMount() {
    this.initListData();
  }

  // 获取初始化列表数据
  initListData = () => {
    this.setState({ loading: true });
    const pagination = this.state.pagination;
    GET_ENGINE_SHORTCUT(pagination.current, pagination.pageSize).then((rsp) => {
      const pagination = this.state.pagination;
      pagination.total = rsp.data.amount;
      this.setState({
        list: rsp.data.list,
        pagination,
        loading: false,
      });
    });
  };

  removeEngine = (data) => {
    const removeAction = () => {
      REMOVE_SHORTCUT({ un_id: data.un_id }).then((rsp) => {
        if (rsp.data.code === 1) {
          this.initListData();
        } else {
          message.error(rsp.data.msg);
        }
      });
    };
    Modal.confirm({
      title: '确认移除该快捷Engine？',
      icon: <ExclamationCircleOutlined />,
      content: '移除后此engine不会被删除',
      onOk() {
        removeAction();
      },
    });
  };

  handleTableChange = ({ current, pageSize }) => {
    const pagination = this.state.pagination;
    pagination.current = current;
    pagination.pageSize = pageSize;
    this.setState({ pagination }, () => {
      this.initListData();
    });
  };

  updateSort = () => {
    const data = this.state.list.map((item, index) => {
      return {
        un_id: item.un_id,
        sort: this.state.hasUpdateSort ? item.sort : index,
      };
    });

    SHORTCUT_SORT(data).then((rsp) => {
      if (rsp.data.code === 1) {
        this.initListData();
        this.setState({
          hasUpdateSort: false,
        });
      }
    });
  };

  handleAdd = () => {
    this.props.history.push('/engine/index');
  };

  remarkEngine = (item) => {
    this.setState({
      remarkVisible: true,
      remarkItem: {
        unId: item.un_id,
        remark: item.remark,
      },
    });
  };

  handleCloseRemarkDialog = () => {
    this.setState({
      remarkVisible: false,
    });
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: 'ICON',
        dataIndex: 'icon',
        render: (icon) => {
          return <img width={32} src={icon} alt="" />;
        },
      },
      {
        title: '名称',
        dataIndex: 'name',
        render: (name, record) => {
          return (
            <span>
              {name}
              {record.remark ? `(${record.remark})` : ''}
            </span>
          );
        },
      },
      { title: 'URL', dataIndex: 'url', width: '30%' },
      { title: '分类', dataIndex: 'cateName' },
      {
        title: '排序',
        dataIndex: 'sort',
        render: (txt, record) => (
          <InputNumber
            key={record.engine_id}
            min={0}
            value={txt}
            onChange={(e) => {
              const lists = this.state.list;
              lists.map((item, index) => {
                if (
                  parseInt(item.engine_id, 10) ===
                  parseInt(record.engine_id, 10)
                ) {
                  lists[index].sort = e;
                  this.setState({
                    list: lists,
                    hasUpdateSort: true,
                  });
                }
                return true;
              });
            }}
          />
        ),
      },
      {
        title: '操作',
        render: (txt, record) => {
          return (
            <div className="table-action-field">
              <Button type="link" onClick={() => this.remarkEngine(record)}>
                备注
              </Button>
              <Button type="link" onClick={() => this.removeEngine(record)}>
                移除
              </Button>
            </div>
          );
        },
      },
    ];

    const { remarkVisible, remarkItem } = this.state;
    return (
      <div>
        <RemarkEngine
          visible={remarkVisible}
          unId={remarkItem.unId}
          remark={remarkItem.remark}
          onCancel={this.handleCloseRemarkDialog}
          reload={this.initListData}
        />
        <Form
          layout="inline"
          ref={this.searchForm}
          className="mb-15"
          onFinish={this.handleSearch}
        >
          <Form.Item>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.handleAdd}
            >
              添加快捷选项
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={this.updateSort}
              icon={<SortAscendingOutlined />}
            >
              {this.state.hasUpdateSort ? '更新排序' : '整理排序'}
            </Button>
          </Form.Item>
        </Form>
        <div className="bg-white pd-10">
          <Table
            rowKey={(record) => record.engine_id}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={this.state.list}
            bordered
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

export default Shortcut;
