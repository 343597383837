import request from "@/api/request";

// 收集到酷站
export const ADD_TAGS = ({ sid, tags = [] }) => {
    return request.post("/cool/add-tags", { sid, tags });
};

// 推荐到酷站
export const RECOMMEND_COOL = (ids, cid) => {
    return request.post("/cool/recommend", { ids, cid });
};

// 获取酷站笔袋下的网址数据
export const GET_SOURCE_LIST = (data) => {
    return request.get("/cool/get-source", data);
};

// 从酷站中删除网址
export const REMOVE_COOL_SOURCE = ({ id }) => {
    return request.post("/cool/remove", { id });
};

// 获取酷站分类访问榜单
export const GET_CATEGORY_RANK = () => {
    return request.get("/cool/get-category-rank");
};

// 从酷站精选池中移除网址
export const POST_SAVE = (id, title, content) => {
    return request.post("/help/post-save", { id, title, content });
};

// 获取酷站分类访问榜单
export const GET_POST_DETAIL = (alias) => {
    return request.get("/help/get-post-detail", { alias });
};

// 获取同host的酷站网址
export const GET_SAME_HOST = (url) => {
    return request.get("/cool/get-same-host", { url });
};

// 更新名称
export const COVER_BASE_NAME = (csId, name, slogan) => {
    return request.post("/cool/cover-base-name", { csId, name, slogan });
};

// 用户备注覆盖基础网址备注
export const COVER_BASE_NOTE = (csId, note) => {
    return request.post("/cool/cover-base-note", { csId, note });
};

// 酷站网址快审
export const REVIEW_COOL_SIMPLE = (csId) => {
    return request.post("/cool/review-simple", { csId });
};

// 覆盖基础网址图标
export const COVER_BASE_ICON = (csId) => {
    return request.post("/cool/cover-base-icon", { csId });
};

// 清除属性
export const CLEAR_VALUE = (csId, type) => {
    return request.post("/cool/clear-value", { csId, type });
};

// 改变酷站网址状态
export const TOGGLE_VISIBLE = (csId) => {
    return request.post("/cool/toggle-visible", { csId });
};

// 获取专题列表
export const GET_SPECIAL_LIST = (page, size, sid) => {
    return request.get("/special/list", { page, size, sid });
};

// 添加专题
export const ADD_SPECIAL = (id, title, icon, color, image, domain) => {
    return request.post("/special/add", { id, title, icon, color, image, domain });
};

// 删除专题
export const DELETE_SPECIAL = (id) => {
    return request.post("/special/delete", { id });
};

// 获取专题详情
export const GET_SPECIAL_DETAIL = (id) => {
    return request.get("/special/detail", { id });
};

// 获取专题下分类列表映射
export const GET_SPECIAL_CATEGORY_MAP = (id, sid) => {
    return request.get("/special/category-map", { id, sid });
};

// 获取专题下书签列表
export const GET_SPECIAL_BOOKMARK = (cid) => {
    return request.get("/special/bookmark", { cid });
};

// 在专题下添加分类
export const ADD_SPECIAL_CATEGORY = (id, title, icon, specialId) => {
    return request.post("/special/add-category", { id, title, icon,  specialId});
};

// 获取分类详细信息
export const GET_SPECIAL_CATEGORY_DETAIL = (id) => {
    return request.get("/special/category-detail", { id });
};

// 获取专题下分类汇总
export const GET_SPECIAL_CATEGORY = (page, size) => {
    return request.get("/special/category", { page, size });
};

// 删除分类
export const DELETE_SPECIAL_CATEGORY = (id) => {
    return request.post("/special/category-delete", { id });
};

// 从专题中移除分类
export const REMOVE_SPECIAL_CATEGORY_MAP = (id) => {
    return request.post("/special/remove-category-map", { id });
};

// 获取专题分类映射详细信息
export const GET_CATEGORY_MAP_DETAIL = (id) => {
    return request.get("/special/category-map-detail", { id });
};

// 编辑专题分类映射备注
export const EDIT_SPECIAL_CATEGORY_MAP = (id, remark) => {
    return request.post("/special/save-category-remark", { id, remark });
};

// 收藏基础网址到专题分类
export const COLLECT_SPECIAL_BOOKMARK = (sid, cid) => {
    return request.post("/special/collect", { sid, cid });
};

// 书签添加备注
export const ADD_SPECIAL_BOOKMARK_REMARK = (id, title, summary, icon) => {
    return request.post("/special/add-bookmark-remark", { id, title, summary, icon });
};

// 获取书签详细信息
export const GET_SPECIAL_BOOKMARK_DETAIL = (id) => {
    return request.get("/special/bookmark-detail", { id });
};

// 专题列表排序
export const SPECIAL_SORT = (data) => {
    return request.post("/special/special-sort", { data });
};

// 专题分类排序
export const SPECIAL_CATEGORY_SORT = (data) => {
    return request.post("/special/category-map-sort", { data });
};

// 专题书签排序
export const SPECIAL_BOOKMARK_SORT = (data) => {
    return request.post("/special/bookmark-sort", { data });
};

// 移除专题书签
export const REMOVE_SPECIAL_BOOKMARK = (id) => {
    return request.post("/special/bookmark-remove", { id });
};

// 选择分类到当前专题主页
export const CHOOSE_CATEGORY = (categoryId, specialId) => {
    return request.post("/special/category-choose", { categoryId, specialId });
};
