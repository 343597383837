import { Route, Switch } from 'react-router-dom';
import HomepageStopWords from "@/pages/Homepage/StopWords";

const Homepage = () => {
  return <Switch>
    <Route path="/homepage/stop-words" key="HomepageStopWords" component={HomepageStopWords} />
  </Switch>
}

export default Homepage;
