import axios from '@/plugins/axios';

const request = {
  get(url, params) {
    return axios.get(url, { params: params });
  },
  post(url, data) {
    return axios.post(url, { ...data });
  },
};

export default request;
