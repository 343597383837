import React, {Component} from 'react';
import {Form, message, Input, Modal, Upload} from 'antd';
import {ADD_USER, GET_USER_INFO} from "@/api/member";
import PropTypes from 'prop-types';
import styleCss from '../style.module.css';
import {getQiNiuAvatar} from "@/utils";

const FormItem = Form.Item;

/**
 * 添加、编辑用户
 */
class AddUser extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    avatar: getQiNiuAvatar(''),
    avatarKey: '',
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible && prevProps.visible !== this.props.visible) {
      if (this.props.id) {
        GET_USER_INFO({uid: this.props.id}).then(rsp => {
          const data = rsp.data.info;
          this.setState({
            avatar: getQiNiuAvatar(data.user_headimg),
            avatarKey: data.user_headimg
          })
          this.formRef.current.setFieldsValue({
            nickname: data.user_nickname,
            email: data.user_email
          });
        })
      } else {
        this.formRef.current.resetFields();
      }
    }
  }

  handleSubmitForm = () => {
    this.formRef.current.submit();
  };

  // 提交编辑
  handleSubmit = () => {
    this.formRef.current.validateFields().then((values) => {
      values.avatar = this.state.avatarKey;
      values.uid = this.props.id;
      this.setState({loading: true})
      ADD_USER(values).then(response => {
        if (response.data.code === 1) {
          this.props.onReload();
          this.props.onClose();
        } else {
          message.error(response.data.msg);
        }
      }).finally(() => {
        this.setState({loading: false})
      })
    });
  }

  // 关闭弹可
  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
      },
    };

    const upload_props = {
      name: 'UploadAvatar[imageFiles]',
      action: '/api/member/upload',
      multiple: false,
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          if (info.file.response.code === 1) {
            this.setState({
              avatarKey: info.file.response.save,
              avatar: info.file.response.image
            });
            message.success(`上传成功`);
          } else {
            message.success(info.file.response.msg);
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <Modal
        style={{top: 20}}
        title="添加"
        cancelText="关闭"
        okText="立即保存"
        visible={this.props.visible}
        onOk={this.handleSubmitForm}
        onCancel={this.handleCancel}
        maskClosable={false}
        confirmLoading={this.state.loading}
      >
        <Form ref={this.formRef} {...formItemLayout} onFinish={this.handleSubmit}>
          <FormItem
            name="nickname"
            label="昵称"
            rules={[
              {required: true, message: '必须填写昵称'},
              {max: 30, message: '网站名称不能超过30个字'},
            ]}
          >
            <Input placeholder="请输入用户昵称"/>
          </FormItem>
          <FormItem
            name="email"
            label="邮箱"
            rules={[
              {type: 'email', message: '请输入正确的邮箱地址'},
            ]}
          >
            <Input placeholder="请输入邮箱地址"/>
          </FormItem>
          <FormItem
            name="avatar"
            label="用户头像"
          >
            <Upload {...upload_props}>
              {this.state.avatarKey ? <img src={this.state.avatar} width='32' alt=""/> :
                <img className={styleCss.addFormAvatar} src="https://img.bidianer.com/image/user/avatar.svg" width='32'
                     alt=""/>}
            </Upload>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

AddUser.propTypes = {
  onRef: PropTypes.func,
  onReload: PropTypes.func,
};

export default AddUser;
