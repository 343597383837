import React, {Component} from 'react';
import {Button, Switch, Tabs, Tag, Tooltip, Select, InputNumber, Form, message, Upload, Input, Modal} from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import PropTypes from 'prop-types'

const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;

class Add extends Component {
  componentDidMount() {
    this.props.onRef(this)
  }

  state = {
    visible: false,
    thumb_pic: '',
    save_pic: '',
    skin_id: null,
    lists: [],
    activeId: '',
    iconLoading: false,
    tags: [],
    inputVisible: false,
    inputValue: '',
  }

  showModal(category, cur_category, skin) {
    this.setState({
      visible: true,
      activeId: cur_category,
      lists: category,
      skin_id: null,
      thumb_pic: '',
      save_pic: '',
      tags: []
    });

    if (skin) {
      this.setState({
        skin_id: skin.skin_id,
        thumb_pic: skin.thumb_pic,
        save_pic: skin.img_url,
        tags: skin.tags.split(','),
        category: skin.category,
        theme: parseInt(skin.theme, 10) === 1,
        opacity: parseInt(skin.opacity),
        color: skin.color ? skin.color : '',
        attachment: skin.attachment,
        position: skin.position,
        repeat: skin.repeat,
        source_url: skin.source_url,
        source_name: skin.source_name
      })
    }
  }

  handleSubmit = (values) => {
    values.theme = values.theme ? 1 : 0;
    values.image = this.state.save_pic;
    values.skin_id = this.state.skin_id;
    values.tags = this.state.tags.join(',');

    this.setState({iconLoading: true});
    axios.post('/skin/add', qs.stringify(values))
      .then((response) => {
        if (response.data.code === 1) {
          this.props.reloadLists();
          this.setState({visible: false, iconLoading: false});
        } else {
          message.error(response.data.msg);
        }
      }).catch((error) => {
      this.setState({iconLoading: false, tags: []});
      message.error(error);
    });
  }

  handleCancel = () => {
    this.setState({visible: false});
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.setState({tags});
  };

  showInput = () => {
    this.setState({inputVisible: true}, () => this.input.focus());
  }

  handleInputChange = (e) => {
    this.setState({inputValue: e.target.value});
  }

  handleInputConfirm = () => {
    const state = this.state;
    const inputValue = state.inputValue;
    let tags = state.tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  }

  saveInputRef = input => this.input = input;

  render() {
    const upload_props = {
      name: 'UploadSkin[imageFiles]',
      action: '/skin/upload',
      multiple: false,
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          if (info.file.response.code > 0) {
            this.setState({
              thumb_pic: info.file.response.thumb,
              save_pic: info.file.response.save,
            });
            message.success(`上传成功`);
          } else {
            message.error(info.file.response.msg);
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
      },
    };

    const {tags, inputVisible, inputValue} = this.state;

    return (
      <div>
        <Modal
          title="添加"
          cancelText="关闭"
          okText="立即保存"
          style={{top: 20}}
          visible={this.state.visible}
          onOk={this.handleSubmit}
          confirmLoading={this.state.iconLoading}
          onCancel={this.handleCancel}
        >
          <Form onSubmit={this.handleSubmit}>
            <Tabs type="card">
              <TabPane tab="必填信息" key="1">
                <FormItem {...formItemLayout} label="上传皮肤">
                  <div>
                    <Upload {...upload_props}>
                      <Button>
                         上传皮肤
                      </Button>
                    </Upload>
                    <div className="mt-15" style={{display: this.state.thumb_pic.length > 0 ? 'block' : 'none'}}>
                      <img width="200" src={this.state.thumb_pic} alt=""/>
                    </div>
                  </div>
                </FormItem>
                <FormItem {...formItemLayout} name="category" label="皮肤分类" rules={[
                  {required: true, message: '请选择分类'},
                ]}>
                  <Select style={{width: 200}} placeholder="请选择皮肤分类">
                    {
                      this.state.lists.map(item => {
                        return <Option key={item.cate_id} value={item.cate_id}>{item.name}</Option>
                      })
                    }
                  </Select>
                </FormItem>
                <FormItem {...formItemLayout} label="标签">
                  <div>
                    {tags.map((tag) => {
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag key={tag} closable={true} afterClose={() => this.handleClose(tag)}>
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                      );
                      return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
                    })}
                    {inputVisible && (
                      <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{width: 78}}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                      />
                    )}
                    {!inputVisible && (
                      <Tag
                        onClick={this.showInput}
                        style={{background: '#fff', borderStyle: 'dashed'}}
                      >
                         New Tag
                      </Tag>
                    )}
                  </div>
                </FormItem>
                <FormItem {...formItemLayout} name="source_url" label="来源网址" rules={[
                  {required: true, message: '必须填写来源网址'},
                ]}>
                  <Input placeholder="请输入来源网址"/>
                </FormItem>
                <FormItem {...formItemLayout} name="source_name" label="来源网站">
                  <Select style={{width: 200}}>
                    <Option value="Pexels.com">Pexels.com</Option>
                  </Select>
                </FormItem>
              </TabPane>
              <TabPane tab="高级设置" key="2">
                <FormItem {...formItemLayout} name="theme" label="主题色">
                  <Switch checkedChildren="深色" unCheckedChildren="浅色"/>
                </FormItem>
                <FormItem {...formItemLayout} name="opacity" label="不透明度">
                  <InputNumber min={1} max={100}/>
                </FormItem>
                <FormItem {...formItemLayout} name="cover" label="图片覆盖方式">
                  <Select style={{width: 200}}>
                    <Option value="cover">拉伸铺满（不变形）</Option>
                    <Option value="auto">不处理</Option>
                  </Select>
                </FormItem>
              </TabPane>
            </Tabs>
          </Form>
        </Modal>
      </div>
    );
  }
}

Add.propTypes = {
  onRef: PropTypes.func,
  reloadLists:PropTypes.func,
}

export default Add