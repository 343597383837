import React from 'react';
import { Route, Switch } from 'react-router-dom';

// 主页

import Category from '@/pages/Category/Index';
import CategoryBag from '@/pages/Category/CategoryBag';
import BookmarkInBag from "@/pages/Category/BookmarkInBag";

import LoginForm from '@/pages/Login';
// 网址
import SourceBase from '@/pages/Source/Index'; // 基础网址
import SourceReview from '@/pages/Source/Review'; // 审核网址
import SourceManage from '@/pages/Source/Manage'; // 常用网址、导航模块的分类管理
import SourceTags from '@/pages/Source/tags/Index'; // 标签管理

import SourceIcon from '@/pages/Source/icon/Index';
import LogFeedBack from '@/pages/Log/FeedBack';
import Member from '@/pages/Member';
import Skin from '@/pages/Skin/Index';
import NoticeContent from '@/pages/Notice/Content';
import NoticeIndex from '@/pages/Notice/Index';

import Engine from '@/pages/Engine';
import EngineShortcut from '@/pages/Engine/Shortcut';

// 发现
import CoolList from '@/pages/Cool';
import CoolRank from '@/pages/Cool/CoolRank';
import CoolSpecial from '@/pages/Cool/Special';
import CoolSpecialDetail from '@/pages/Cool/SpecialDetail'
import CoolSpecialCategory from '@/pages/Cool/SpecialCategory'


import CoolEditor from '@/pages/Cool/Editor';

// 主页
import Homepage from './module/homepage';

// 文章
import PostList from '@/pages/post';

// 帮助中心
import Help from '@/pages/Help';
import HelpWrite from '@/pages/Help/Write';

// 首页导航条
import NavBar from '@/pages/Other/NavBar';
import NavItem from '@/pages/Other/NavItem';

import UploadCdn from '@/pages/Other/UploadCdn';
import ItemLog from '@/pages/Log/ItemLog';

const SourceRoute = () => (
    <Switch>
        <Route path="/source/index" key="SourceBase" component={ SourceBase }/>
        <Route path="/source/star" key="SourceStar" component={ CoolList }/>
        <Route path="/source/manage" key="SourceManage" component={ SourceManage }/>
        <Route path="/source/icon" key="7-3" component={ SourceIcon }/>
        <Route path="/source/review" key="SourceReview" component={ SourceReview }/>
    </Switch>
);

const BasicRoute = () => (
    <React.Fragment>
        <SourceRoute/>
        <Homepage/>
        <Switch>
            <Route path="/log/feedback" key="3" component={ LogFeedBack }/>
            <Route path="/log/item-log" key="item-log" component={ ItemLog }/>
            <Route path="/login" key="8" component={ LoginForm }/>
            <Route path="/member/index" key="9" component={ Member }/>
            <Route exact path="/category/parent/:id?" key="Category" component={ Category }/>
            <Route path="/category/bag" key="CategoryBag" component={ CategoryBag }/>
            <Route path="/category/bookmarkInBag" key="bookmarkInBag" component={ BookmarkInBag }/>
            <Route path="/skin/index" key="skinIndex" component={ Skin }/>
            <Route path="/help/write" key="helpWrite" component={ HelpWrite }/>
            <Route exact path="/help" key="help" component={ Help }/>
            <Route
                path="/notice/content"
                key="NoticeContent"
                component={ NoticeContent }
            />
            <Route path="/notice/index" key="NoticeIndex" component={ NoticeIndex }/>
            <Route path="/engine/index" key="EngineIndex" component={ Engine }/>
            <Route
                path="/engine/shortcut"
                key="EngineShortcut"
                component={ EngineShortcut }
            />
            <Route path="/source/tags" key="SourceTags" component={ SourceTags }/>
            <Route path="/cool/coolRank" key="coolRank" component={ CoolRank }/>
            <Route path="/cool/list" key="CoolList" component={ CoolList }/>
            
            <Route path="/cool/specialCategory" key="CoolSpecialCategory" component={ CoolSpecialCategory }/>
            <Route path="/cool/special-detail" key="CoolSpecialDetail" component={ CoolSpecialDetail }/>
            <Route path="/cool/special" key="CoolSpecial" component={ CoolSpecial }/>
            <Route path="/other/editor" key="CoolEditor" component={ CoolEditor }/>
            <Route path="/other/nav-bar" key="NavBar" component={ NavBar }/>
            <Route path="/other/nav-item" key="NavItem" component={ NavItem }/>
            <Route path="/other/upload-cdn" key="UploadCdn" component={ UploadCdn }/>

            {/*文章*/}
            <Route path="/post/list" key="PostList" component={ PostList }/>
        </Switch>
    </React.Fragment>
);

export default BasicRoute;
