import request from "@/api/request";

// 获取基础网址列表
export const GET_BASE_LIST = (data) => {
    return request.get("/source/get-base-list", data);
};

// 获取笔袋内的网址
export const GET_BOOKMARK_IN_BAG = (bid) => {
    return request.get("/source/get-bag-source", { bid });
};

// 获取等待审核的基础网址列表
export const GET_REVIEW_LIST = (data) => {
    return request.get("/source/review-list", data);
};

// 添加收藏
export const ADD_COLLECT = (sourceId, bid) => {
    return request.post("/source/collect", { sourceId, bid });
};

// 保存、添加分类
export const GET_CATEGORY = (module, sourceId) => {
    return request.get("/source/get-category", { module, id: sourceId });
};

// 获取分类下的网址
export const GET_CATEGORY_SOURCE = (bid, name = "", url = "") => {
    return request.get("/source/get-category-source", { bid, name, url });
};

// 网址排序
export const SORT_SOURCE = (sort) => {
    return request.post("/source/sort", sort);
};

// 获取网址详细信息
export const GET_SOURCE_INFO = (sourceId) => {
    return request.get("/source/get-info", { sourceId });
};

// 添加、编辑网址信息
export const ADD_SOURCE = (data) => {
    return request.post("/source/add", data);
};

// 抓取远程url详细信息
export const CRAWLER_SOURCE_INFO = (url) => {
    return request.get("/source/crawler-web", { url });
};

// 单个网址加入、取消黑名单
export const JOIN_SINGLE_BLACKLIST = (sourceId) => {
    return request.post("/source/join-single-blacklist", { sourceId });
};

// 审核单个网址
export const REVIEW_SOURCE = (sourceId, isAll = null) => {
    return request.post("/source/review-source", { sourceId, isAll });
};

// 备注分类下的网址
export const REMARK_CATEGORY_SOURCE = (id, remark, desc) => {
    return request.post("/source/remark-category-source", { id, remark, desc });
};

// 移除分类下的网址
export const REMOVE_CATEGORY_SOURCE = (id) => {
    return request.post("/source/remove-category-source", { id });
};

// 获取网址下的子网址
export const GET_SOURCE_CHILDREN = ({ parentId }) => {
    return request.get("/source/get-children", { parentId });
};

// 给网址添加、选择子网址
export const CHOOSE_SOURCE_CHILDREN = ({ parentId, sourceId }) => {
    return request.post("/source/choose-children", { parentId, sourceId });
};

// 移除子网址
export const REMOVE_SOURCE_CHILDREN = ({ childrenId }) => {
    return request.post("/source/remove-children", { childrenId });
};

// 切换星标状态
export const TOGGLE_STAR = (sourceId) => {
    return request.post("/source/toggle-star", { sourceId });
};

// 从笔袋中移除书签
export const REMOVE_SOURCE_IN_BAG = (id) => {
    return request.post("/bag/remove-source", { id });
};

// 加入白名单
export const JOIN_WHITE_LIST = (iconId) => {
    return request.post("/source/join-white", { iconId });
};

// 获取用户访问日志
export const GET_ITEM_LOG = () => {
    return request.get("/log/get-item-log", {});
};

// 将网址加入到酷站
export const JOIN_COOL = (id, status) => {
    return request.post("/log/join-cool", { id, status });
};
