const menu = [
    {
        name: "member",
        icon: "UserOutlined",
        content: "用户",
        route: [
            {
                name: "用户列表",
                path: "member/index",
            },
        ],
    },
    {
        name: "homepage",
        icon: "HomeOutlined",
        content: "主页",
        route: [
            {
                name: "主页列表",
                path: "homepage/index",
            },
            {
                name: "停用词列表",
                path: "homepage/stop-words",
            },
        ],
    },
    {
        name: "category",
        icon: "AppstoreOutlined",
        content: "分类",
        route: [
            {
                name: "顶级分类",
                path: "category/parent",
            },
        ],
    },
    {
        name: "source",
        icon: "LinkOutlined",
        content: "网址",
        route: [
            {
                name: "基础网址",
                path: "source/index",
            },
            {
                name: "待审核",
                path: "source/review",
            },
            {
                name: "精选",
                path: "source/star",
            },
            {
                name: "图标ICON",
                path: "source/icon",
            },
            {
                name: "标签",
                path: "source/tags",
            },
            {
                name: "常用网址",
                path: "source/manage?module=website",
                reg: /^\/source\/manage\?module=website/i,
            },
            {
                name: "分类导航",
                path: "source/manage?module=nav",
                reg: /^\/source\/manage\?module=nav/i,
            },
            {
                name: "其他分类",
                path: "source/manage?module=other",
                reg: /^\/source\/manage\?module=other/i,
            },
        ],
    },
    {
        name: "cool",
        icon: "CompassOutlined",
        content: "酷站",
        route: [
            {
                name: "专题列表",
                path: "cool/special",
                Highlight: ["/cool/special-detail", "/cool/special",'/cool/special-category-map'],
            },
            {
                name: "专题分类库",
                path: "cool/specialCategory",
            },
            {
                name: "酷站榜单",
                path: "cool/coolRank",
            },
        ],
    },
    /*{
        name: 'post',
        icon: 'FileOutlined',
        content: '文章',
        route: [
            {
                name: '全部列表',
                path: 'post/list',
            }
        ],
    },*/
    {
        name: "help",
        icon: "QuestionCircleOutlined",
        content: "帮助中心",
        route: [
            {
                name: "全部列表",
                path: "help",
            },
        ],
    },
    {
        name: "engine",
        icon: "SearchOutlined",
        content: "搜索引擎",
        route: [
            {
                name: "常用搜索引擎",
                path: "engine/shortcut",
            },
            {
                name: "全部",
                path: "engine/index",
            },
        ],
    },
    {
        name: "skin",
        icon: "PictureOutlined",
        content: "皮肤",
        route: [
            {
                name: "皮肤列表",
                path: "skin/index",
            },
        ],
    },
    {
        name: "notice",
        icon: "BellOutlined",
        content: "消息通知",
        route: [
            {
                name: "消息内容",
                path: "notice/content",
            },
            {
                name: "全部",
                path: "notice/index",
            },
        ],
    },
    {
        name: "log",
        icon: "FileOutlined",
        content: "日志管理",
        route: [
            {
                name: "详情页访问",
                path: "log/item-log",
            },
            {
                name: "用户反馈",
                path: "log/feedback",
            },
        ],
    },
    {
        name: "other",
        icon: "AppstoreOutlined",
        content: "其它",
        route: [
            {
                name: "首页导航条",
                path: "other/nav-bar",
            },
            {
                name: "上传静态资源",
                path: "other/upload-cdn",
            },
            {
                name: "隐私和条款",
                path: "other/editor",
            },
        ],
    },
];

export default menu;
