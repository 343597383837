import React, { useEffect } from "react";
import { Form, Input, Modal, message } from "antd";
import { SAVE_STOP_WORD } from "@/api/homepage";

const AddStopWords = ({ visible, word, remark, onCancel, onFinish }) => {
  const [ form ] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        word: word || '',
        remark: remark || ''
      })
    }
  }, [ visible ]);

  function onSubmit(values) {
    SAVE_STOP_WORD(values).then(rsp => {
      if (rsp.data.code === 1) {
        message.success('添加、编辑成功');
        form.resetFields();
        onCancel();
        onFinish();
      } else {
        message.error(rsp.data.msg);
      }
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <Modal
      visible={visible}
      title="添加、编辑停用词"
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name="word"
          label="停用词"
          rules={[
            { required: true, message: '必须输入停用词' },
            { max: 20, message: '停用词不能超过20个字符' },
          ]}
        >
          <Input placeholder="请输入停用词"/>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          rules={[
            { required: true, message: '请输入备注' },
            { max: 30, message: '备注最多30个字' },
          ]}
        >
          <Input placeholder="请输入备注"/>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddStopWords;
