import React, {Component} from 'react';
import {Table, Button, Form, message, Input, Checkbox, Tag} from 'antd';
import AddForm from './Add';
import {SearchOutlined, PlusOutlined} from '@ant-design/icons';
import {GET_TAG_LIST, SET_SELECTED} from '@/api/tags';
import {IMG_HOST} from "@/config/config";

const FormItem = Form.Item;

/**
 * 全站ICON管理列表
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
  state = {
    lists: [],
    pagination: {
      current: 1,
      pageSize: 20,
      showQuickJumper: true,
    },
    search: {},
    selectedStatus: false,
    iconStatus: false,
    // 添加、编辑
    editVisible: false,
    editId: '',
  };

  componentDidMount() {
    this.getListsData();
  }

  // 获取列表
  getListsData = () => {
    let data = this.state.search;
    data.page = this.state.pagination.current;
    data.size = this.state.pagination.pageSize;
    data.selected = this.state.selectedStatus ? 1 : null;
    data.icon = this.state.iconStatus ? 1 : null;

    GET_TAG_LIST(data).then((response) => {
      let data = response.data;
      let pager = this.state.pagination;
      pager.total = data.total;
      pager.pageSize = data.size;
      const list = response.data.list.map(item => {
        item.cover = item.cover ? IMG_HOST + '/' + item.cover : '';
        return item;
      })
      this.setState({
        lists: list,
        pagination: pager,
      });
    });
  };

  // 搜索
  handleSearch = (values) => {
    const pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({search: values, pagination}, () => {
      this.getListsData();
    });
  };

  // 分页变化
  handleTableChange = (pagination) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({pagination: pager}, () => {
      window.scrollTo(0, 0);
      this.getListsData();
    });
  };

  // 精选复选框变化
  onCheckBoxChange = (e) => {
    this.setState({
      selectedStatus: e.target.checked,
    });
  }

  // 是否有icon
  onIconCheckBoxChange = (e) => {
    this.setState({
      iconStatus: e.target.checked
    });
  }

  // 添加icon
  handleAdd = () => {
    this.handelEdit('');
  };

  // 编辑icon
  handelEdit = (id) => {
    this.setState({editVisible: true, editId: id});
  };

  // 设置精选、取消精选
  handleSetSelected = (tagId) => {
    SET_SELECTED({id: tagId}).then(rsp => {
      this.getListsData();
    });
  }

  // 关闭添加、编辑弹框
  handleAddClose = () => {
    this.setState({editVisible: false});
  };

  render() {
    const columns = [
      {title: 'ID', dataIndex: 'id', width: 80},
      {
        title: 'Icon',
        dataIndex: 'cover',
        render: (src) => <img width="32" src={src} alt=""/>,
      },
      {title: 'title', dataIndex: 'name'},
      {
        title: '网址数',
        dataIndex: 'sourceAmount',
      },
      {
        title: '精选',
        dataIndex: 'selected',
        render: (val) => {
          return (val ? <Tag color="red">精选</Tag> : <Tag>无</Tag>)
        }
      },
      {
        title: '操作',
        render: (record) => {
          return (
            <div className="table-action-field">
              <Button
                type="link"
                onClick={() => this.handelEdit(record.id)}
              >
                编辑
              </Button>
              <Button
                type="link"
                onClick={() => this.handleSetSelected(record.id)}
              >
                设为精选
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <AddForm
          id={this.state.editId}
          visible={this.state.editVisible}
          onReload={this.getListsData}
          onClose={this.handleAddClose}
        />
        <Form layout="inline" className="mb-15" onFinish={this.handleSearch}>
          <FormItem name="wd">
            <Input placeholder="输入标签"/>
          </FormItem>
          <FormItem>
            <Checkbox onChange={this.onCheckBoxChange}>精选</Checkbox>
          </FormItem>
          <FormItem>
            <Checkbox onChange={this.onIconCheckBoxChange}>有Icon</Checkbox>
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
              搜索
            </Button>
          </FormItem>
          <FormItem>
            <Button icon={<PlusOutlined/>} onClick={this.handleAdd}>
              添加
            </Button>
          </FormItem>
        </Form>
        <div className="bg-white pd-10">
          <Table
            rowKey="icon_id"
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            columns={columns}
            dataSource={this.state.lists}
            bordered
          />
        </div>
      </div>
    );
  }
}

export default Index;
