import React, { Component } from "react";
import { Table, Button, Form, message, Input, Tooltip, Modal } from "antd";
import {
    SearchOutlined,
    PlusOutlined,
    ClockCircleOutlined,
    CheckCircleOutlined,
    StarOutlined
} from "@ant-design/icons";
import { GET_CATEGORY_LIST } from "@/api/category";
import {
    GET_SOURCE_LIST,
    COLLECT_SPECIAL_BOOKMARK,
} from "@/api/cool";
import moduleCss from "@/pages/Source/style/index.module.css";
import coolCss from "@/pages/Cool/style/index.module.css";
import { getQiNiuAvatar, getQiNiuIconUrl } from "@/utils";
import RecommendCool from "@/pages/Source/components/RecommendCool";

const FormItem = Form.Item;

/**
 * 全部酷站网址列表
 */
class Index extends Component {
    state = {
        category: [],
        activeCategory: 0,
        subMenus: [],
        activeSubCategory: 0,
        sourceList: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
            size: "default",
        },
        search: {},
        loading: false,
        activeSubId: 0,

        // 审核列表弹框
        reviewModalVisible: false,
        collectMediaVisible: false, // 收藏到自媒体
        collectMediaType: "wx", // 收藏到自媒体类别
        collectMediaSid: 0, // 当前点击的记录网址id，用于查询收藏在哪些笔袋
        // 移动
        coolVisible: false,
        coolSourceId: "",
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            this.getCategoryData();
        }
    }

    // 获取一级分类列表
    getCategoryData = () => {
        this.setState({ loading: true });
        GET_CATEGORY_LIST({ alias: "cool-common" })
            .then((rsp) => {
                let subItems = [{ cid: "", name: "全部", child: [] }];
                rsp.data.list.forEach((item) => {
                    const child = item.child.map((item) => {
                        return {
                            cid: item.cate_id,
                            name: item.name,
                            child: item.child,
                        };
                    });
                    subItems = [...subItems, ...child];
                });
                this.setState(
                    {
                        category: subItems,
                    },
                    () => {
                        this.handleMenuChange(subItems[0].cid, subItems[0].child);
                    }
                );
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 切换一级分类
    handleMenuChange = (pid, child = []) => {
        const items = child.map((item) => {
            return {
                cid: item.cate_id,
                name: item.name,
            };
        });
        this.setState(
            {
                activeCategory: pid,
                activeSubCategory: "",
                subMenus: pid ? [...[{ cid: "", name: "全部" }], ...items] : [],
            },
            () => {
                this.handleSubMenuChange("");
            }
        );
    };

    // 当改变二级分类
    handleSubMenuChange = (cid) => {
        const pagination = this.state.pagination;
        pagination.current = 1;

        const state = { activeSubId: cid, pagination };

        this.setState(state, () => {
            this.getSourceListData();
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getSourceListData();
    };

    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState(
            {
                search: values,
                pagination: pager,
            },
            () => this.getSourceListData()
        );
    };

    // 获取分类下的网址数据
    getSourceListData = () => {
        this.setState({ loading: true });
        const data = {
            wd: this.state.search.wd,
            page: this.state.pagination.current,
            pid: this.state.activeCategory,
            cid: this.state.activeSubId,
            targetCid: this.props.cid
        };

        GET_SOURCE_LIST(data)
            .then((rsp) => {
                const pagination = this.state.pagination;
                if (!rsp.data.list.length) {
                    // 检查如果页码大于1，则自动加载前一页的数据
                    if (pagination.current > 1) {
                        pagination.current--;
                        this.getSourceListData();
                        return false;
                    }
                }

                pagination.pageSize = rsp.data.size;
                pagination.total = rsp.data.total;
                const list = rsp.data.list.map((item, index) => {
                    item.idx = (pagination.current - 1) * pagination.pageSize + 1 + index;
                    item.renderIcon = getQiNiuIconUrl(item.renderIcon);
                    item.avatar = getQiNiuAvatar(item.avatar);
                    return item;
                });

                this.setState({
                    sourceList: list,
                    pagination,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    getSourceStatusLabel = (record) => {
        if (record.csReview) return <CheckCircleOutlined style={{ color: "#52c41a", paddingRight: "5px" }} />;
        return (
            <Tooltip title="待审核">
                <ClockCircleOutlined style={{ color: "#666", paddingRight: "5px" }} />
            </Tooltip>
        );
    };

    handleCancel = () => {
        this.props.onClose();
    };

    // 添加到当前分类
    addSourceToCategory = (sid) => {
        COLLECT_SPECIAL_BOOKMARK(sid, this.props.cid).then(() => {
            message.success("操作成功").then(() => {});
            this.getSourceListData();
            this.props.onReload();
        });
    };

    // 打开酷站收藏弹框
    handleCoolOpen = (sourceId) => {
        this.setState({ coolVisible: true, coolSourceId: sourceId });
    };

    // 关闭酷站弹框
    handleCoolClose = () => {
        this.setState({ coolVisible: false });
    };

    render() {
        const columns = [
            {
                title: "图标",
                dataIndex: "renderIcon",
                width: 70,
                render: (src, record) => {
                    return (
                        <Tooltip title={`SID: ${record.sid}`}>
                            <div className="table-cover" title="更换图标">
                                <img width="32" src={src} alt="" />
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                render: (name, record) => {
                    return (
                        <div>
                            <p>
                                <span>{this.getSourceStatusLabel(record)}</span>
                                {name}
                            </p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}

                            <p style={{ marginBottom: 0, color: "#666" }}>{record.createTime}</p>
                        </div>
                    );
                },
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.originSummary}</p>
                            <p style={{ marginBottom: 0 }}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            { title: "点击", dataIndex: "baseClickAmount", width: 120 },
            {
                title: "酷站",
                render: (record) => {
                    return record.parentCategoryName ? (
                        <Button type="text" onClick={() => this.handleCoolOpen(record.sid)}>
                            <i className={`${moduleCss.coolCategoryIcon} iconfont ${record.parentCategoryIcon}`} />{" "}
                            {record.parentCategoryName} - {record.categoryName}
                        </Button>
                    ) : (
                        <Button type="link" onClick={() => this.handleCoolOpen(record.sid)}>
                            <StarOutlined />
                            收藏
                        </Button>
                    );
                },
            },
            {
                title: "操作",
                width: 150,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.addSourceToCategory(record.sid)}>
                                {record.inSpecial ? (
                                    <CheckCircleOutlined style={{ color: "#52c41a" }} />
                                ) : (
                                    <PlusOutlined />
                                )}
                                {record.inSpecial ? "已添加" : "添加"}
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <RecommendCool
                    visible={this.state.coolVisible}
                    ids={this.state.coolSourceId}
                    onClose={this.handleCoolClose}
                    onReload={this.getSourceListData}
                />
                <Modal
                    style={{ top: 0 }}
                    width="100%"
                    title="选择网址"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    confirmLoading={this.state.loading}
                >
                    <Form layout="inline" onFinish={this.handleSearch} className="mb-15 search-form-fixed">
                        <FormItem name="wd">
                            <Input placeholder="请输入名称 / URL" />
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                搜索
                            </Button>
                        </FormItem>
                    </Form>

                    <div className={coolCss["category-bar"]}>
                        <ul className={coolCss["category-bar-content"]}>
                            {this.state.category.map((item) => {
                                return (
                                    <li
                                        className={`${
                                            item.cid === this.state.activeCategory ? coolCss.categoryBarItemActive : ""
                                        } ${coolCss["category-bar-item"]}`}
                                        onClick={() => this.handleMenuChange(item.cid, item.child)}
                                    >
                                        {item.name}
                                    </li>
                                );
                            })}
                        </ul>
                        {this.state.subMenus.length ? (
                            <ul className={coolCss.subMenuWrapper}>
                                {this.state.subMenus.map((item) => {
                                    return (
                                        <li
                                            className={`${coolCss.subMenuItem} ${
                                                item.cid === this.state.activeSubId ? coolCss.subMenuItemActive : ""
                                            }`}
                                            onClick={() => this.handleSubMenuChange(item.cid)}
                                        >
                                            {item.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </div>

                    <Table
                        size="small"
                        rowKey={(record) => record.sid}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.sourceList}
                        bordered
                        loading={this.state.loading}
                    />
                </Modal>
            </div>
        );
    }
}

export default Index;
