import axios from 'axios';
import QS from 'qs';
import {message} from 'antd';


let config = {
  baseURL:'/api',
  //baseURL: process.env.baseURL || process.env.apiUrl || "http://www.bidianer.loc",
  // timeout: 60 * 1000, // Timeout
  //withCredentials: true, // Check cross-site Access-Control
  transformRequest: [function (data) {
    // Do whatever you want to transform the data
    if (data && (typeof data) === 'object') {
      data = QS.stringify(data);
    }
    return data;
  }],
};

const instance = axios.create(config);

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);

  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          message.error('请先登录');
          localStorage.clear();
          window.location.href = '/';
          return Promise.reject(error);
        default:
          message.error('服务器错误');
          return Promise.reject(error);
      }
    }
  }
);

export default instance
