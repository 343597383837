import React, {Component} from 'react';
import {Table, Button, Form, message, Input, Row, Col, List, InputNumber, Tag} from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import AddEngine from './components/AddEngine'
import {SortAscendingOutlined, DeleteOutlined, SearchOutlined, PlusOutlined} from '@ant-design/icons';
import {ADD_SHORTCUT} from '@/api/engine';
import {GET_CATEGORY_LIST} from "@/api/category";

const FormItem = Form.Item;

class Index extends Component {
  searchForm = React.createRef();
  state = {
    lists: [],
    selectedRowKeys: [],
    pagination: {
      current: 1,
      showQuickJumper: true
    },
    iconLoading: false,
    search: {},
    checkbox: {
      user_create: false,
      official_create: true
    },
    dropdown_index: '',
    alias: '',
    loading: false,
    sub_category: [],
    activeCid: 0,
    hasUpdateSort: false,
    addVisible: false,
    addInfo: {}
  }

  componentDidMount() {
    this.getCategory();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({selectedRowKeys});
  }

  getCategory() {
    this.setState({loading: true});
    GET_CATEGORY_LIST({alias: 'engine'}).then((response) => {
      let data = response.data;
      let activeCid = data.list.length > 0 ? data.list[0].cate_id : null;
      this.setState({
        sub_category: data.list,
        activeCid: activeCid
      }, () => {
        this.getLists();
        return true;
      });
    });
  }

  changeCategory(cid) {
    this.searchForm.current.resetFields();
    this.setState({activeCid: cid, search: {}}, () => this.getLists());
  }

  getLists = () => {
    let data = this.state.search;
    data.page = this.state.pagination.current;
    data.cate_id = this.state.activeCid;
    this.setState({loading: true, activeCid: this.state.activeCid});

    axios.post('/engine/index', qs.stringify(data))
      .then((response) => {
        let data = response.data;
        let pager = this.state.pagination;
        pager.total = data.total;
        pager.pageSize = data.page_size;
        this.setState({
          lists: response.data.lists,
          pagination: pager,
          loading: false
        });
      });
  };

  handleTableChange = (pagination) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedRowKeys: []
    });

    window.scrollTo(0, 0);
    this.getLists();
  };

  handleSearch = (values) => {
    const pager = this.state.pagination;
    pager.current = 1;
    this.setState({
      search: values,
      pagination: pager,
      selectedRowKeys: []
    }, () => this.getLists());
  }

  deleteItems = () => {
    const engine_ids = this.state.selectedRowKeys;
    if (!engine_ids.length) {
      message.warning('请选择需要删除的列表');
      return false;
    }

    axios.post('/engine/delete', qs.stringify({engine_ids: JSON.stringify(engine_ids)}))
      .then((response) => {
        if (response.data.code === 1) {
          message.success('删除成功');
          this.getLists();
          this.setState({
            selectedRowKeys: []
          })
        } else {
          message.error(response.data.msg);
        }
      });
  };

  handleEdit = (engine) => {
    this.setState({addVisible: true, addInfo: engine});
  }

  remarkShortcut = (record) => {
    ADD_SHORTCUT({engine_id: record.engine_id}).then(rsp => {
      if(rsp.data.code===1) {
        this.getLists();
      } else {
        message.error(rsp.data.msg);
      }
    })
  }

  updateSort = () => {
    const data = [];
    this.state.lists.map((item) => {
      data.push({engine_id: item.engine_id, sort: item.sort});
      return true;
    });
    axios.post('/engine/sort', qs.stringify(data))
      .then((response) => {
        if (response.data.code === 1) {
          this.getLists();
          this.setState({
            hasUpdateSort: false
          });
        }
      });
  };

  handleCloseAdd = () => {
    this.setState({addVisible: false});
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: 'ICON', dataIndex: 'picture', render: (icon) => {
          return <img width={32} src={icon} alt=""/>
        }
      },
      {title: '名称', dataIndex: 'name'},
      {title: 'URL', dataIndex: 'url', width: '30%'},
      {
        title: '快捷状态', dataIndex: 'shortcut',
        render: (shortcut) => (shortcut? <Tag color="green">快捷</Tag>:<Tag>默认</Tag>)
      },
      {
        title: '排序',
        dataIndex: 'sort',
        render: (txt, record) => <InputNumber key={record.engine_id} min={0} defaultValue={txt} onChange={(e) => {
          let lists = this.state.lists;
          lists.forEach((item, index) => {
            if (parseInt(item.engine_id, 10) === parseInt(record.engine_id, 10)) {
              lists[index].sort = e;
              this.setState({
                lists: lists,
                hasUpdateSort: true
              });
            }
          });
        }}/>
      },
      {
        title: '操作', render: (txt, record) => {
          return (<div className="table-action-field">
            <Button type="link" onClick={() => this.handleEdit(record)}>编辑</Button>
            {
              record.shortcut?'':<Button type="link" onClick={()=>{this.remarkShortcut(record)}}>标记常用</Button>
            }
          </div>);
        }
      }
    ];

    return (
      <div>
        <AddEngine visible={this.state.addVisible}
                   categoryOption={this.state.sub_category}
                   activeCid={this.state.activeCid}
                   data={this.state.addInfo}
                   reload={this.getLists}
                   onCancel={this.handleCloseAdd}/>
        <Row gutter={16}>
          <Col span={3}>
            <div className="bg-white pd-10">
              <List
                size="small"
                className="sub-category"
                bordered
                dataSource={this.state.sub_category}
                renderItem={item => {
                  return (<List.Item onClick={() => this.changeCategory(item.cate_id)}
                                     className={item.cate_id === this.state.activeCid ? 'active' : ''}>
                    {item.name}
                  </List.Item>)
                }}
              />
            </div>
          </Col>
          <Col span={21}>
            <Form layout="inline" ref={this.searchForm} className="mb-15" onFinish={this.handleSearch}>
              <FormItem name="name">
                <Input placeholder="请输入搜索引擎名称"/>
              </FormItem>
              <FormItem name="url">
                <Input placeholder="请输入搜索引擎URL"/>
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined/>}
                >
                  搜索
                </Button>
              </FormItem>
              <FormItem>
                <Button icon={<PlusOutlined/>} onClick={() => this.handleEdit(null)}>添加</Button>
              </FormItem>
              <FormItem>
                <Button
                  onClick={this.updateSort}
                  disabled={!this.state.hasUpdateSort}
                  icon={<SortAscendingOutlined/>}>更新排序</Button>
              </FormItem>
              <FormItem>
                <Button icon={<DeleteOutlined/>}
                        disabled={this.state.selectedRowKeys.length <= 0}
                        onClick={this.deleteItems} type="danger">删除</Button>
              </FormItem>
            </Form>
            <div className="bg-white pd-10">
              <Table
                rowKey={record => record.engine_id}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.state.lists}
                bordered
                loading={this.state.loading}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Index;
