import React, {Component} from 'react';
import {Table, Button, Tag, Form, Input} from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import {SearchOutlined,DeleteOutlined} from '@ant-design/icons'

const FormItem = Form.Item;

class Index extends Component {
  state = {
    lists: [],
    selectedRowKeys: [],
    pagination: {
      current: 1,
      showQuickJumper: true
    },
    search: {},
    loading: false
  };

  componentDidMount() {
    this.getLists();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({selectedRowKeys});
  }

  getLists = () => {
    let data = this.state.search;
    data.page = this.state.pagination.current;
    this.setState({loading: true});
    axios.post('/notice/index', qs.stringify(data))
      .then((response) => {
        let data = response.data;
        let pager = this.state.pagination;
        pager.total = data.total;
        pager.pageSize = data.page_size;
        this.setState({
          lists: response.data.lists,
          pagination: pager,
          loading: false
        });
      });
  };

  handleTableChange = (pagination) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedRowKeys: []
    });

    window.scrollTo(0, 0);
    this.getLists();
  };

  onRef = (ref) => {
    this.child = ref;
  };

  handleAdd = () => {
    this.child.showModal(this.state.sub_category, this.state.activeCid, null);
  };

  handleSubmit = (values) => {
    const pager = this.state.pagination;
    pager.current = 1;
    this.setState({
      search: values,
      pagination: pager,
      selectedRowKeys: []
    }, () => this.getLists());
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {title: '消息ID', dataIndex: 'notice_id', width: 100},
      {
        title: '发送者', dataIndex: 'user_nickname', render: (nickname, record) => {
          return <div>
            <span><img src={record.user_headimg} width={20} alt=""/></span>
            <span>{nickname ? nickname : '未登录'}</span>
            <span>({record.send_uid ? record.send_uid : '未登录'})</span>
          </div>
        }
      },
      {
        title: '接收用户', dataIndex: 'user.user_nickname', render: (nickname, record) => {
          return <div>
            <span><img src={record.user.user_headimg} width={20} alt=""/></span>
            <span>{nickname ? nickname : '未登录'}</span>
            <span>({record.user.user_id ? record.user.user_id : '未登录'})</span>
          </div>
        }
      },
      {
        title: '内容', width: 400, dataIndex: 'content', render: (content) => {
          return <p dangerouslySetInnerHTML={{__html: content}}/>
        }
      },
      {
        title: '类型', dataIndex: 'type', render: (type) => {
          return parseInt(type, 10) === 1 ? <Tag color="magenta">系统消息</Tag> : <Tag>吐个槽</Tag>
        }
      },
      {
        title: '是否已读', dataIndex: 'status', render: (status) => {
          let state = parseInt(status, 10);
          if (state === 1) {
            return <Tag>已读</Tag>
          } else if (state === 0) {
            return <Tag color="red">未读</Tag>
          } else {
            return <Tag>已删除</Tag>
          }
        }
      },
      {title: '发送时间', dataIndex: 'create_time'},
    ];

    return (
      <div>
        <Form layout="inline" className="mb-15" onFinish={this.handleSubmit}>
          <FormItem name="user_id">
            <Input type="number" placeholder="请输入接收者用户ID"/>
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              搜索
            </Button>
          </FormItem>
          <FormItem>
            <Button icon={<DeleteOutlined />}
                    disabled={this.state.selectedRowKeys.length <= 0}
                    onClick={this.deleteItems} type="danger">删除</Button>
          </FormItem>
        </Form>
        <Table
          rowKey={record => record.notice_id}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={this.state.lists}
          bordered
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default Index