import React, { Component } from "react";
import { Table, Button, Form, message, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DELETE_SPECIAL_CATEGORY, GET_SPECIAL_CATEGORY } from "@/api/cool";
import AddSpecialCategory from "@/pages/Cool/components/AddSpecialCategory";
import SpecialCategoryBookmarkModal from "./components/SpecialCategoryBookmarkModal";
import style from "./style/index.module.css";

const FormItem = Form.Item;
const { confirm } = Modal;

/**
 * 专题下的分类汇总
 */
class Index extends Component {
    state = {
        list: [],
        loading: false,
        addCategoryVisible: false,
        editCategoryId: "",
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
            size: "default",
        },
        viewBookmarkVisible: false,
        viewBookmarkCid: "",
    };

    componentDidMount() {
        this.getSpecialCategoryListData();
    }

    // 获取专题列表
    getSpecialCategoryListData = () => {
        this.setState({ loading: true });
        const { current, pageSize } = this.state.pagination;
        const pagination = this.state.pagination;
        GET_SPECIAL_CATEGORY(current, pageSize)
            .then((rsp) => {
                pagination.total=rsp.data.total;
                this.setState({
                    list: rsp.data.list,
                    pagination
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 删除专题
    handleDelete = (id) => {
        confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否删除当前分类？",
            onOk: () => {
                DELETE_SPECIAL_CATEGORY(id).then((response) => {
                    if (response.data.code === 1) {
                        this.getSpecialCategoryListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    showAddCategoryModal = () => {
        this.setState({
            editCategoryId: "",
            addCategoryVisible: true,
        });
    };

    showEditCategoryModal = (id) => {
        this.setState({
            editCategoryId: id,
            addCategoryVisible: true,
        });
    };

    handleAddCategoryClose = () => {
        this.setState({
            addCategoryVisible: false,
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getSpecialCategoryListData();
    };

    showCategoryBookmarkModal = (cid) => {
        this.setState({
            viewBookmarkVisible: true,
            viewBookmarkCid: cid,
        });
    };

    hideCategoryBookmarkModal = () => {
        this.setState({
            viewBookmarkVisible: false,
        });
    };

    render() {
        const columns = [
            { title: "分类ID", dataIndex: "id", width: 100 },
            {
                title: "名称",
                dataIndex: "title",
                render: (title, record) => {
                    return (
                        <span
                            className={style.tableCategoryName}
                            onClick={() => this.showCategoryBookmarkModal(record.id)}
                        >
                            {title}
                        </span>
                    );
                },
            },
            {
                title: "图标",
                dataIndex: "icon",
            },
            {
                title: "操作",
                width: 60,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.showEditCategoryModal(record.id)}>
                                编辑
                            </Button>
                            <Button type="link" onClick={() => this.handleDelete(record.id)} danger>
                                删除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <SpecialCategoryBookmarkModal
                    visible={this.state.viewBookmarkVisible}
                    id={this.state.viewBookmarkCid}
                    onClose={this.hideCategoryBookmarkModal}
                />
                <AddSpecialCategory
                    visible={this.state.addCategoryVisible}
                    id={this.state.editCategoryId}
                    onReload={this.getSpecialCategoryListData}
                    onClose={this.handleAddCategoryClose}
                />
                <Form layout="inline" className="mb-15 search-form-fixed">
                    <FormItem>
                        <Button type="primary" onClick={this.showAddCategoryModal} icon={<PlusOutlined />}>
                            添加分类
                        </Button>
                    </FormItem>
                </Form>

                <Table
                    rowKey={(record) => record.id}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default Index;
