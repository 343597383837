import React, {Component, Fragment} from "react";
import {Button, Form, message, Modal, Table} from "antd";
import PropTypes from "prop-types";
import {
    JOIN_WHITE_LIST,
    GET_REVIEW_LIST,
    JOIN_SINGLE_BLACKLIST,
    REVIEW_SOURCE,
    TOGGLE_STAR,
} from "@/api/source";
import {REVIEW_HOST} from '@/api/icon';
import {CloseOutlined, CheckOutlined, StarOutlined, EyeInvisibleOutlined, EditOutlined} from "@ant-design/icons";
import {getQiNiuIconUrl} from "@/utils";
import moduleCss from "../style/index.module.css";
import AddSource from "@/pages/Source/components/AddSource";
import IconAdd from "@/pages/Source/icon/Add";
import RecommendCool from "@/pages/Source/components/RecommendCool";

const FormItem = Form.Item;

/**
 * 审核网址
 * @class Add
 * @extends {Component}
 */
class ReviewModal extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 50,
            total: 0,
            showQuickJumper: true,
            showTotal: (total) => `共计 ${total} 条`,
            size: "default",
        },
        search: {
            page: 1,
            size: 100,
        },
        // 酷站精选
        coolVisible: false,
        coolSourceId: "",
        // 编辑网址
        editSourceId: "", // 编辑网址id
        editSourceVisible: false, // 编辑网址弹框是否显示
        // icon
        editIconId: "",
        editIconVisible: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            let pagination = this.state.pagination;
            pagination.current = 1;
            this.setState({pagination}, () => {
                this.getListData();
            })
        }
    }

    // 获取网址列表
    getListData = () => {
        this.setState({loading: true});
        const searchData = this.state.search;
        searchData.page = this.state.pagination.current;
        searchData.size = this.state.pagination.pageSize;
        searchData.iconId = this.props.iconId;

        return GET_REVIEW_LIST(searchData)
            .then((response) => {
                let data = response.data;
                let pagination = this.state.pagination;
                pagination.total = data.total;
                this.setState({
                    list: data.list.map((item) => {
                        item.icon = getQiNiuIconUrl(item.icon);
                        return item;
                    }),
                    pagination,
                    loading: false,
                });
                return data.total;
            })
            .finally(() => {
                this.setState({loading: false});
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onReload();
        this.props.onClose();
    };

    // 搜索
    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;

        this.setState({search: values, pagination: pager}, () => {
            this.getListData();
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys: [],
        });

        window.scrollTo(0, 0);
        this.getListData();
    };

    handleHostReviewPass = () => {
        this.setState({loading: true});
        REVIEW_HOST(this.props.iconId, 'join_cool').then(() => {
            this.handleCancel();
        })
    };

    handleHostReviewPassOut = () => {
        this.setState({loading: true});
        REVIEW_HOST(this.props.iconId, 'out_cool').then(() => {
            this.handleCancel();
        })
    };

    // 过滤列表
    handleFilterList = (sid) => {
        if (!Array.isArray(sid)) {
            sid = [sid];
        }
        const list = this.state.list.filter((f) => !sid.includes(f.sid));
        this.setState({selectedRowKeys: []});
        if (!list.length) {
            this.getListData().then((amount) => {
                if (amount <= 0) {
                    this.handleCancel();
                }
            });
            return;
        }
        const pagination = this.state.pagination;
        pagination.total = pagination.total - sid.length;
        this.setState({list, pagination, loading: false});
    };

    handleReviewLose = () => {
        REVIEW_HOST(this.props.iconId, 'lose').then(() => {
            this.handleCancel();
        })
    };

    toggleStar = (sourceId) => {
        this.setState({loading: true});
        TOGGLE_STAR(sourceId).then(() => {
            this.handleFilterList(sourceId);
        });
    };

    // 加入黑名单
    handleJoinBlackList = () => {
        REVIEW_HOST(this.props.iconId, 'blacklist').then(() => {
            this.handleCancel();
        })
    };

    // 单个网址加入黑名单
    handleJoinSingleBlacklist = (sourceId) => {
        JOIN_SINGLE_BLACKLIST(sourceId).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                message.success("操作成功").then((r) => {
                });
            } else {
                message.error(rsp.data.msg).then((r) => {
                });
            }
        });
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    // 编辑网址
    // 打开添加、编辑网址弹框
    handleAddSourceOpen = (sourceId) => {
        this.setState({editSourceVisible: true, editSourceId: sourceId});
    };

    // 关闭添加、编辑网址弹框
    handleAddSourceClose = () => {
        this.setState({editSourceVisible: false});
    };

    // 编辑Icon
    handleIconEdit = (id) => {
        this.setState({editIconVisible: true, editIconId: id});
    };
    // 关闭icon编辑弹框
    handleIconClose = () => {
        this.setState({editIconVisible: false});
    };

    // 打开酷站收藏弹框
    handleCoolOpen = (sourceId) => {
        this.setState({ coolVisible: true, coolSourceId: sourceId });
    };

    // 关闭酷站弹框
    handleCoolClose = () => {
        this.setState({ coolVisible: false });
    };

    handleRecommendReload=()=>{
        // 当前host审核为在酷站
        this.handleHostReviewPass();
        this.handleCancel();
        this.getListData();
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {title: "ID", dataIndex: "sid", width: 100},
            {
                title: "图标",
                dataIndex: "icon",
                width: 70,
                render: (src, record) => {
                    return (
                        <div
                            className="table-cover"
                            title="更换图标"
                            onClick={() => this.handleIconEdit(record.iconId)}
                        >
                            <img width="32" src={src} alt=""/>
                        </div>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                width: 300,
                render: (name, record) => {
                    return (
                        <Fragment>
                            <p>
                                <span>{name}</span>
                                <span
                                    className={moduleCss.nameEditBtn}
                                    onClick={() => this.handleAddSourceOpen(record.sid)}
                                >
                                    &nbsp;
                                    <EditOutlined/>
                                </span>
                            </p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}
                            <p style={{color: "#999", marginBottom: 0}}>{record.createTime}</p>
                        </Fragment>
                    );
                },
            },
            {
                title: "操作",
                width: 100,
                render: (record) => {
                    return (
                        <div>
                            <Button type="primary" className={moduleCss.actionBtnPass}
                                    onClick={() => this.handleCoolOpen(record.sid)}>
                                <StarOutlined/>
                                精选
                            </Button>
                        </div>
                    );
                },
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.summary}</p>
                            <p style={{marginBottom: 0}}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            {
                title: "点击",
                width: 120,
                dataIndex: "clickAmount",
            },
            {
                title: "操作",
                width: 130,
                render: (record) => {
                    return (
                        <div>
                            <Button
                                type="dashed"
                                danger
                                className={moduleCss.actionBtn}
                                onClick={() => this.handleJoinSingleBlacklist(record.sid)}
                            >
                                单个黑名单
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <IconAdd
                    id={this.state.editIconId}
                    visible={this.state.editIconVisible}
                    onReload={this.getListData}
                    onClose={this.handleIconClose}
                />
                <AddSource
                    visible={this.state.editSourceVisible}
                    sid={this.state.editSourceId}
                    onReload={this.getListData}
                    onClose={this.handleAddSourceClose}
                />
                <RecommendCool
                    visible={this.state.coolVisible}
                    ids={this.state.coolSourceId}
                    onClose={this.handleCoolClose}
                    onReload={this.handleRecommendReload}
                />
                <Modal
                    style={{top: 0}}
                    width="90%"
                    title="查找同域名网站"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    confirmLoading={this.state.loading}
                >
                    <Form layout="inline" className="search-form-fixed" onFinish={this.handleSearch}>
                        <FormItem>
                            <Button
                                type="primary"
                                loading={this.state.loading}
                                onClick={this.handleHostReviewPass}
                                icon={<CheckOutlined/>}
                            >
                                在酷站
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button
                                loading={this.state.loading}
                                onClick={this.handleHostReviewPassOut}
                                icon={<EyeInvisibleOutlined />}
                            >
                                不在酷站
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button
                                danger
                                loading={this.state.loading}
                                onClick={this.handleJoinBlackList}
                            >
                                加入黑名单
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button loading={this.state.loading} onClick={this.handleReviewLose}>
                                <CloseOutlined/>
                                失效链接
                            </Button>
                        </FormItem>
                        <FormItem>
                            还剩<span className={moduleCss.surplusTotal}>{this.state.pagination.total}</span>条未审核
                        </FormItem>
                    </Form>
                    <Table
                        size="small"
                        rowKey="sid"
                        rowSelection={rowSelection}
                        loading={this.state.loading}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                    />
                </Modal>
            </div>
        );
    }
}

ReviewModal.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default ReviewModal;
