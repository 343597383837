import React from 'react';
import { Layout } from 'antd';
import { HashRouter, Route, Switch } from "react-router-dom";

import styles from '@/assets/css/layout.module.css';
import Logo from '@/assets/img/logo.svg';

import BasicRoute from "@/router";
import SidebarNav from "@/components/SidebarNav";
import LoginForm from '@/pages/Login';

const { Header, Content, Footer, Sider } = Layout;

class Index extends React.Component {
    state = {
        collapsed: false,
    }
    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        const isGuest = localStorage.getItem('isGuest');
        console.log(isGuest, 'is-guest');
        return (
            <HashRouter>
                <Switch>
                    {
                        isGuest !== 'user' ? <LoginForm/>
                            : <Route path="/" key="mainContent">
                                <Layout>
                                    <Sider collapsible className={ styles.sidebar } collapsed={ collapsed }
                                           onCollapse={ this.onCollapse }>
                                        <div className={ styles.logo }>
                                            <img width={ 32 } src={ Logo } alt=""/>
                                            {
                                                collapsed ? '' : <span>笔点后台管理</span>
                                            }
                                        </div>
                                        <SidebarNav/>
                                    </Sider>
                                    <Layout className="site-layout" style={ { marginLeft: collapsed ? 80 : 200 } }>
                                        {/*<Header className={ styles.header } style={ { padding: 0 } }/>*/}
                                        <Content className={ styles.content } style={ { overflow: 'initial' } }>
                                            <div className={styles.siteLayoutBackground}>
                                                <BasicRoute/>
                                            </div>
                                        </Content>
                                        <Footer style={ { textAlign: 'center' } }>南京笔点信息技术有限公司 ©2021</Footer>
                                    </Layout>
                                </Layout>
                            </Route>
                    }
                </Switch>

            </HashRouter>
        )
    }
}

export default Index;
