import request from '@/api/request';

// 获取停用词列表
export const GET_STOP_WORDS_LIST = ({ word, page = 1 }) => {
  return request.get('/homepage/get-stop-words-list', { word, page });
};

// 添加、编辑停用词
export const SAVE_STOP_WORD = ({ word, remark='' }) => {
  return request.post('/homepage/add', { word, remark });
};

