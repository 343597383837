import React, {Fragment} from 'react';
import {Form, message, Modal, Button, Table, Tag} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {COLLECT_SOURCE_TO_BAG, GET_CATEGORY_BAG_ALIAS} from "@/api/category";
import AddCategoryBag from "@/pages/Category/AddCategoryBag";
import WxIcon from '@/assets/img/wx.svg';
import XhsIcon from '@/assets/img/xhs.svg';
import style from './style.module.css';
import {GET_BOOKMARK_IN_BAG, REMOVE_SOURCE_IN_BAG} from "@/api/source";

const FormItem = Form.Item;

// 查询笔袋内的网址
class Collect extends React.PureComponent {
    state = {
        list: [],
        pagination: {
            current: 1,
            pageSize: 10,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`,
            size: 'default'
        },
    };

    componentDidUpdate(prevPros) {
        if (prevPros.visible !== this.props.visible) {
            if (this.props.visible) {
                this.getSourceListData();
            }
        }
    }

    getSourceListData = () => {
        GET_BOOKMARK_IN_BAG(this.props.bid).then(rsp => rsp.data).then(data => {
            this.setState({
                list: data.list
            })
        })
    }

    handleSubmit = (bid) => {
        COLLECT_SOURCE_TO_BAG(this.props.sid, bid).then(() => {
            message.success('操作成功').then(() => {
            });
            this.props.onReload();
            this.getBagListData();
        })
    };

    handleCancel = () => {
        this.props.onClose();
        this.props.onReload();
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });

        this.getBagListData();
    };

    handleItemRemove = (id) => {
        REMOVE_SOURCE_IN_BAG(id).then(rsp=>{
            this.getSourceListData();
        })
    }

    render() {
        const columns = [
            {title: 'SID', dataIndex: 'sid', width: 100},
            {
                title: '图标',
                dataIndex: 'icon',
                width: 70,
                render: (src) => {
                    return (
                        <div>
                            <img width="32" src={src} alt=""/>
                        </div>
                    );
                },
            },
            {title: '名称', dataIndex: 'name'},
            {
                title: '名称', dataIndex: 'url', render: (url) => {
                    return <a href={url} target="_blank">{url}</a>
                }
            },
            {
                title: '操作',
                width: 170,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" danger onClick={() => this.handleItemRemove(record.id)}>
                                移除
                            </Button>
                        </div>
                    );
                },
            }
        ];

        return (
            <div>
                <Modal
                    title="查看网址"
                    footer={null}
                    width="800px"
                    style={{top: 20}}
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.submitLoading}
                >
                    <Table
                        rowKey="bid"
                        size="small"
                        columns={columns}
                        pagination={this.state.pagination}
                        dataSource={this.state.list}
                        onChange={this.handleTableChange}
                        bordered
                        loading={this.state.loading}
                    />
                </Modal>
            </div>
        );
    }
}

Collect.propTypes = {
    onReload: PropTypes.func,
    visible: PropTypes.bool,
    type: PropTypes.string,
    bid: PropTypes.number
};

export default Collect;
