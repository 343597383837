import React, { Component } from "react";
import { Table, Button, Form, message, Input, Tooltip, Modal } from "antd";
import {
    ExclamationCircleOutlined,
    SearchOutlined,
    ArrowRightOutlined,
    EditOutlined,
    EyeOutlined,
    StarFilled,
    StarOutlined,
    ClockCircleOutlined,
    CheckCircleOutlined,
    EyeInvisibleOutlined,
} from "@ant-design/icons";
import AddSource from "../Source/components/AddSource";
import RecommendCool from "../Source/components/RecommendCool";
import IconAdd from "../Source/icon/Add";
import { GET_CATEGORY_LIST } from "@/api/category";
import { GET_SOURCE_LIST, REMOVE_COOL_SOURCE, TOGGLE_VISIBLE } from "@/api/cool";
import moduleCss from "@/pages/Source/style/index.module.css";
import coolCss from "./style/index.module.css";
import { getQiNiuAvatar, getQiNiuIconUrl } from "@/utils";
import AddTags from "@/pages/Source/components/AddTags";
import ReviewListModal from "@/pages/Cool/components/ReviewListModal";
import CollectMediaModal from "@/pages/Source/components/CollectMediaModal";

const FormItem = Form.Item;
const { confirm } = Modal;

/**
 * 全部酷站网址列表
 */
class Index extends Component {
    state = {
        category: [],
        activeCategory: 0,
        subMenus: [],
        activeSubCategory: 0,
        sourceList: [],
        lists: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
            size: "default",
        },
        iconLoading: false,
        search: {},
        loading: false,
        activeSubId: 0,
        hasUpdateSort: false,
        editSourceVisible: false,
        editSourceId: "",
        // icon
        editIconId: "",
        editIconVisible: false,
        // 编辑
        editVisible: false,
        editId: 0,
        // 移动
        coolVisible: false,
        coolSourceId: "",
        // 标签
        // 编辑标签
        tagsVisible: false,
        tagsSourceId: "",
        // 审核列表弹框
        reviewModalVisible: false,
        collectMediaVisible: false, // 收藏到自媒体
        collectMediaType: "wx", // 收藏到自媒体类别
        collectMediaSid: 0, // 当前点击的记录网址id，用于查询收藏在哪些笔袋

        collectToSpecialVisible: false, // 收录到专题导航
        collectToSpecialId: "",
    };

    componentDidMount() {
        this.getCategoryData();
    }

    // 获取一级分类列表
    getCategoryData = () => {
        this.setState({ loading: true });
        GET_CATEGORY_LIST({ alias: "cool-common" })
            .then((rsp) => {
                let subItems = [{ cid: "", name: "全部", child: [] }];
                rsp.data.list.forEach((item) => {
                    const child = item.child.map((item) => {
                        return {
                            cid: item.cate_id,
                            name: item.name,
                            child: item.child,
                        };
                    });
                    subItems = [...subItems, ...child];
                });
                this.setState(
                    {
                        category: subItems,
                    },
                    () => {
                        this.handleMenuChange(subItems[0].cid, subItems[0].child);
                    }
                );
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 切换一级分类
    handleMenuChange = (pid, child = []) => {
        const items = child.map((item) => {
            return {
                cid: item.cate_id,
                name: item.name,
            };
        });
        this.setState(
            {
                activeCategory: pid,
                activeSubCategory: "",
                subMenus: pid ? [...[{ cid: "", name: "全部" }], ...items] : [],
            },
            () => {
                this.handleSubMenuChange("");
            }
        );
    };

    // 当改变二级分类
    handleSubMenuChange = (cid) => {
        const pagination = this.state.pagination;
        pagination.current = 1;

        const state = { activeSubId: cid, pagination };

        this.setState(state, () => {
            this.getSourceListData();
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getSourceListData();
    };

    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState(
            {
                search: values,
                pagination: pager,
            },
            () => this.getSourceListData()
        );
    };

    // 从酷站中移除该网址
    handleRemove = (id) => {
        confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否从酷站中移除该网址？此操作不会删除基础网址！",
            onOk: () => {
                REMOVE_COOL_SOURCE({ id }).then((response) => {
                    if (response.data.code === 1) {
                        this.getSourceListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    // 当用户修改sort时候，重新设置列表的sort
    handleSortChange = (id, sort) => {
        const sourceList = this.state.sourceList;
        sourceList.forEach((item, index) => {
            if (Number(item.id) === Number(id)) {
                sourceList[index].sort = sort;
                this.setState({
                    sourceList,
                    hasUpdateSort: true,
                });
            }
        });
    };

    // 获取分类下的网址数据
    getSourceListData = () => {
        this.setState({ loading: true, selectedRowKeys: [] });
        const data = {
            wd: this.state.search.wd,
            page: this.state.pagination.current,
            pid: this.state.activeCategory,
            cid: this.state.activeSubId,
        };

        GET_SOURCE_LIST(data)
            .then((rsp) => {
                const pagination = this.state.pagination;
                if (!rsp.data.list.length) {
                    // 检查如果页码大于1，则自动加载前一页的数据
                    if (pagination.current > 1) {
                        pagination.current--;
                        this.getSourceListData();
                        return false;
                    }
                }

                pagination.pageSize = rsp.data.size;
                pagination.total = rsp.data.total;
                const list = rsp.data.list.map((item, index) => {
                    item.idx = (pagination.current - 1) * pagination.pageSize + 1 + index;
                    item.renderIcon = getQiNiuIconUrl(item.renderIcon);
                    item.avatar = getQiNiuAvatar(item.avatar);
                    return item;
                });

                this.setState({
                    sourceList: list,
                    pagination,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 打开编辑网址弹框
    handleAddSourceOpen = (sid) => {
        this.setState({ editVisible: true, editId: sid });
    };

    // 关闭添加、编辑网址弹框
    handleAddSourceClose = () => {
        this.setState({ editVisible: false });
    };

    // 打开酷站收藏弹框
    handleCoolOpen = (sourceId) => {
        this.setState({ coolVisible: true, coolSourceId: sourceId });
    };

    handleBatchMove = () => {
        this.handleCoolOpen(this.state.selectedRowKeys);
    };

    // 关闭酷站弹框
    handleCoolClose = () => {
        this.setState({ coolVisible: false });
    };

    // 编辑Icon
    handleIconEdit = (id) => {
        this.setState({ editIconVisible: true, editIconId: id });
    };
    // 关闭icon编辑弹框
    handleIconClose = () => {
        this.setState({ editIconVisible: false });
    };

    // 关闭酷站弹框
    handleTagsClose = () => {
        this.setState({ tagsVisible: false });
    };

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    // 开始审核，打开弹框
    handleReviewModalShow = () => {
        this.setState({ reviewModalVisible: true });
    };

    // 关闭审核列表弹框
    handleReviewModalClose = () => {
        this.setState({ reviewModalVisible: false });
    };

    // 打开自媒体收藏弹框
    handleMediaModalShow = (type, sid) => {
        this.setState({
            collectMediaVisible: true,
            collectMediaType: type,
            collectMediaSid: sid,
        });
    };

    // 关闭自媒体收藏弹框
    handleCollectMediaClose = () => {
        this.setState({ collectMediaVisible: false });
    };

    getSourceStatusLabel = (record) => {
        if (record.csReview) return <CheckCircleOutlined style={{ color: "#52c41a", paddingRight: "5px" }} />;
        return (
            <Tooltip title="待审核">
                <ClockCircleOutlined style={{ color: "#666", paddingRight: "5px" }} />
            </Tooltip>
        );
    };

    changeStatus = (csId, status) => {
        TOGGLE_VISIBLE(csId, status).then((rsp) => {
            if (rsp.data.code === -1) {
                message.error(rsp.data.msg);
                return;
            }
            this.getSourceListData();
        });
    };

    showCollectToSpecial = (sid) => {
        this.setState({
            collectToSpecialId: sid,
            collectToSpecialVisible: true,
        });
    };

    handleCollectSpecialClose = () => {
        this.setState({
            collectToSpecialVisible: false,
        });
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            {
                title: "图标",
                dataIndex: "renderIcon",
                width: 70,
                render: (src, record) => {
                    return (
                        <Tooltip title={`SID: ${record.sid}`}>
                            <div
                                className="table-cover"
                                title="更换图标"
                                onClick={() => this.handleIconEdit(record.iconId)}
                            >
                                <img width="32" src={src} alt="" />
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                render: (name, record) => {
                    return (
                        <div>
                            <p>
                                <span>{this.getSourceStatusLabel(record)}</span>
                                <span
                                    className={moduleCss.eyeBtn}
                                    onClick={() => this.changeStatus(record.id, record.csReview === 2 ? 1 : 2)}
                                >
                                    {record.csReview === 2 ? (
                                        <EyeInvisibleOutlined title="不显示在列表中" />
                                    ) : (
                                        <EyeOutlined title="显示在列表中" />
                                    )}
                                </span>
                                {name}
                                <span
                                    className={moduleCss.nameEditBtn}
                                    onClick={() => this.handleAddSourceOpen(record.sid)}
                                >
                                    &nbsp;
                                    <EditOutlined />
                                </span>
                            </p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}

                            <p style={{ marginBottom: 0, color: "#666" }}>{record.createTime}</p>
                        </div>
                    );
                },
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.originSummary}</p>
                            <p style={{ marginBottom: 0 }}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            { title: "点击", dataIndex: "baseClickAmount", width: 120 },
            {
                title: "酷站",
                render: (record) => {
                    return record.parentCategoryName ? (
                        <Button type="text" onClick={() => this.handleCoolOpen(record.sid)}>
                            <i className={`${moduleCss.coolCategoryIcon} iconfont ${record.parentCategoryIcon}`} />{" "}
                            {record.parentCategoryName} - {record.categoryName}
                        </Button>
                    ) : (
                        <Button type="link" onClick={() => this.handleCoolOpen(record.sid)}>
                            <StarOutlined />
                            收藏
                        </Button>
                    );
                },
            },
            {
                title: "公众号",
                dataIndex: "sid",
                render: (sid, record) => {
                    return (
                        <Button type="link" onClick={() => this.handleMediaModalShow("wx", sid)}>
                            {record.media.includes("media_wx") ? (
                                <span style={{ color: "orange" }}>
                                    <StarFilled /> 已收藏
                                </span>
                            ) : (
                                <span>
                                    <StarOutlined /> 收藏
                                </span>
                            )}
                        </Button>
                    );
                },
            },
            {
                title: "小红书",
                dataIndex: "sid",
                render: (sid, record) => {
                    return (
                        <Button type="link" onClick={() => this.handleMediaModalShow("xhs", sid)}>
                            {record.media.includes("media_xhs") ? (
                                <span style={{ color: "orange" }}>
                                    <StarFilled /> 已收藏
                                </span>
                            ) : (
                                <span>
                                    <StarOutlined /> 收藏
                                </span>
                            )}
                        </Button>
                    );
                },
            },
            /*{
                title: '排序',
                dataIndex: 'sort',
                render: (txt, record) => (
                    <InputNumber
                        key={record.csId}
                        min={0}
                        defaultValue={txt}
                        onChange={(e) => this.handleSortChange(record.csId, e)}
                    />
                ),
            },*/
            {
                title: "操作",
                width: 80,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleRemove(record.csId)} danger>
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <CollectMediaModal
                    visible={this.state.collectMediaVisible}
                    type={this.state.collectMediaType}
                    sid={this.state.collectMediaSid}
                    onReload={this.getSourceListData}
                    onClose={this.handleCollectMediaClose}
                />
                <AddTags
                    visible={this.state.tagsVisible}
                    sourceId={this.state.tagsSourceId}
                    onClose={this.handleTagsClose}
                    onReload={this.getSourceListData}
                />
                <IconAdd
                    id={this.state.editIconId}
                    visible={this.state.editIconVisible}
                    onReload={this.getSourceListData}
                    onClose={this.handleIconClose}
                />
                <AddSource
                    visible={this.state.editVisible}
                    sid={this.state.editId}
                    onReload={this.getSourceListData}
                    onClose={this.handleAddSourceClose}
                />
                <RecommendCool
                    visible={this.state.coolVisible}
                    ids={this.state.coolSourceId}
                    onClose={this.handleCoolClose}
                    onReload={this.getSourceListData}
                />

                <ReviewListModal visible={this.state.reviewModalVisible} onClose={this.handleReviewModalClose} />

                <Form layout="inline" onFinish={this.handleSearch} className="mb-15 search-form-fixed">
                    <FormItem name="wd">
                        <Input placeholder="请输入名称 / URL" />
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                            搜索
                        </Button>
                    </FormItem>
                    {/*<FormItem>
                        <Button
                            type="primary"
                            onClick={this.handleSelectedSort}
                            disabled={!this.state.hasUpdateSort}
                            icon={<SortAscendingOutlined/>}
                        >
                            更新排序
                        </Button>
                    </FormItem>*/}
                    <FormItem>
                        <Button
                            type="default"
                            onClick={this.handleBatchMove}
                            disabled={!this.state.selectedRowKeys.length}
                            icon={<ArrowRightOutlined />}
                        >
                            批量移动
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" onClick={this.handleReviewModalShow} icon={<EyeOutlined />}>
                            开始审核
                        </Button>
                    </FormItem>
                </Form>

                <div className={coolCss["category-bar"]}>
                    <ul className={coolCss["category-bar-content"]}>
                        {this.state.category.map((item) => {
                            return (
                                <li
                                    className={`${
                                        item.cid === this.state.activeCategory ? coolCss.categoryBarItemActive : ""
                                    } ${coolCss["category-bar-item"]}`}
                                    onClick={() => this.handleMenuChange(item.cid, item.child)}
                                >
                                    {item.name}
                                </li>
                            );
                        })}
                    </ul>
                    {this.state.subMenus.length ? (
                        <ul className={coolCss.subMenuWrapper}>
                            {this.state.subMenus.map((item) => {
                                return (
                                    <li
                                        className={`${coolCss.subMenuItem} ${
                                            item.cid === this.state.activeSubId ? coolCss.subMenuItemActive : ""
                                        }`}
                                        onClick={() => this.handleSubMenuChange(item.cid)}
                                    >
                                        {item.name}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </div>

                <Table
                    size="small"
                    rowKey={(record) => record.sid}
                    rowSelection={rowSelection}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.sourceList}
                    bordered
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default Index;
