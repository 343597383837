import React, {useState, useEffect} from 'react'
import {Editor, Toolbar} from '@wangeditor/editor-for-react';
import {Button, Input, Radio, message} from 'antd';
import {POST_SAVE, GET_POST_DETAIL} from '@/api/cool';

// import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'

function MyEditor() {
    // editor 实例
    const [id, setId] = useState(0);
    // const [editor, setEditor] = useState<IDomEditor | null>(null)   // TS 语法
    const [editor, setEditor] = useState(null)                   // JS 语法
    const [title, setTitle] = useState('');
    // 编辑器内容
    const [html, setHtml] = useState('')
    const [loading, setLoading] = useState(false);
    const [postType, setPostType] = useState('privacy');

    // 模拟 ajax 请求，异步设置 html
    useEffect(() => {
        getPostContent(postType)
    }, []);

    function getPostContent(type) {
        GET_POST_DETAIL(type).then(rsp => {
            const {id, title, content} = rsp.data;
            setId(id);
            setTitle(title);
            setHtml(content);
        });
    }

    const onSubmit = () => {
        setLoading(true)
        POST_SAVE(id, title, html).then((rsp) => {
            if (rsp.data.code===-1) {
                message.error(rsp.data.msg);
            } else {
                message.success('保存成功');
            }
        }).finally(()=>{
            setLoading(false);
        })
    }

    // 工具栏配置
    // const toolbarConfig: Partial<IToolbarConfig> = { }  // TS 语法
    const toolbarConfig = {}                        // JS 语法

    // 编辑器配置
    // const editorConfig: Partial<IEditorConfig> = {    // TS 语法
    const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    function handleTitleChange(val) {
        setTitle(val.target.value);
    }

    function onPostTypeChange(val) {
        setPostType(val.target.value);
        getPostContent(val.target.value);
    }

    return (
        <>
            <Radio.Group onChange={onPostTypeChange} value={postType} style={{marginBottom: 10}}>
                <Radio value='privacy'>隐私政策</Radio>
                <Radio value='terms'>服务条款</Radio>
            </Radio.Group>
            <Input placeholder="请输入标题" value={title} onChange={handleTitleChange} style={{marginBottom: 10}}/>
            <div style={{border: '1px solid #ccc', zIndex: 100}}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{borderBottom: '1px solid #ccc'}}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => setHtml(editor.getHtml())}
                    mode="default"
                    style={{height: '500px', overflowY: 'hidden'}}
                />
            </div>
            <Button type="primary" style={{marginTop: 10}} loading={loading} onClick={onSubmit}>保存</Button>

            {/*<div style={{marginTop: '15px'}}>
                {html}
            </div>*/}
        </>
    )
}

export default MyEditor
