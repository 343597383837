import React from 'react';
import {Form, message, Modal, Button, Table, Tag} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {COLLECT_SOURCE_TO_BAG, GET_CATEGORY_BAG_ALIAS} from "@/api/category";
import AddCategoryBag from "@/pages/Category/AddCategoryBag";
import WxIcon from '@/assets/img/wx.svg';
import XhsIcon from '@/assets/img/xhs.svg';
import style from './style.module.css';
import BookmarkInBagModal from "@/pages/Source/components/BookmarkInBagModal";

const FormItem = Form.Item;

// 收藏到自媒体分类
class Collect extends React.PureComponent {
    state = {
        activeModule: 'website',
        list: [],
        sour_id: '',
        loading: false,
        submitLoading: false,
        addCategoryBagVisible: false,
        addCategoryBagCid: 0, // 当前类别对应的分类id
        editCategoryBagId: 0, // 当前编辑的笔袋id
        editCategoryBagName: '', // 当前编辑的笔袋名称
        pagination: {
            current: 1,
            pageSize: 10,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`,
            size: 'default'
        },
        bookmarkInBagVisible: false,
        bookmarkInBagId: 0
    };

    componentDidUpdate(prevPros) {
        if (prevPros.visible !== this.props.visible) {
            if (this.props.visible) {
                this.getBagListData();
            }
        }
    }

    getBagListData = () => {
        GET_CATEGORY_BAG_ALIAS(`media_${this.props.type}`, this.props.sid).then(rsp => {
            const pagination = this.state.pagination;
            pagination.total = rsp.data.total;
            this.setState({addCategoryBagCid: rsp.data.cid, pagination, list: rsp.data.list});
        })
    }

    handleSubmit = (bid) => {
        COLLECT_SOURCE_TO_BAG(this.props.sid, bid).then(() => {
            message.success('操作成功').then(() => {
            });
            this.props.onReload();
            this.getBagListData();
        })
    };

    handleCancel = () => {
        this.props.onClose();
        this.props.onReload();
    };

    // 打开添加分类弹框
    handleAddModalShow = (bid = null, name = null) => {
        this.setState({addCategoryBagVisible: true, editCategoryBagId: bid, editCategoryBagName: name})
    }

    // 关闭添加分类下笔袋弹框
    handleAddModalClose = () => {
        this.setState({addCategoryBagVisible: false})
    }

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });

        this.getBagListData();
    };

    handleBookmarkInBagShow = (bid) => {
        this.setState({
            bookmarkInBagVisible: true,
            bookmarkInBagId: bid
        })
    }

    handleBookmarkInBagClose = () => {
        this.setState({
            bookmarkInBagVisible: false
        })
    }

    render() {
        const columns = [
            {title: 'BagID', dataIndex: 'bid', width: 100},
            {
                title: '名称', dataIndex: 'name', render: (name, record) => {
                    return <span className={style.bookmarkInBagName}
                                onClick={() => this.handleBookmarkInBagShow(record.bid)}>{name}</span>
                }
            },
            {
                title: '是否收藏',
                dataIndex: 'collect',
                width: 100,
                render(collect) {
                    return collect ? <Tag color="red">已收藏</Tag> : <Tag>否</Tag>;
                },
            },
            {
                title: '操作',
                width: 170,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link"
                                    onClick={() => this.handleAddModalShow(record.bid, record.name)}>
                                编辑
                            </Button>
                            <Button
                                type="link"
                                danger={!!record.collect}
                                onClick={() => this.handleSubmit(record.bid)}
                            >
                                {record.collect ? '取消收藏' : '收藏'}
                            </Button>
                        </div>
                    );
                },
            }
        ];

        const mediaType = {
            wx: '公众号',
            xhs: '小红书'
        }

        return (
            <div>
                <BookmarkInBagModal
                    visible={this.state.bookmarkInBagVisible}
                    bid={this.state.bookmarkInBagId}
                    onClose={this.handleBookmarkInBagClose}
                    onReload={this.getBagListData}
                />
                <AddCategoryBag
                    visible={this.state.addCategoryBagVisible}
                    bid={this.state.editCategoryBagId}
                    bagName={this.state.editCategoryBagName}
                    cid={this.state.addCategoryBagCid}
                    onReload={this.getBagListData}
                    onClose={this.handleAddModalClose}
                />
                <Modal
                    title={
                        <div className={style.moduleTitle}>
                            <img className={style.modalTitleIcon} src={this.props.type === 'wx' ? WxIcon : XhsIcon}
                                 alt=""/>{`收藏到${mediaType[this.props.type]}`}
                        </div>}
                    footer={null}
                    width="800px"
                    style={{top: 20}}
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.submitLoading}
                >
                    <Form layout="inline" className="mb-15">
                        <FormItem>
                            <Button
                                loading={this.state.submitLoading}
                                type="primary"
                                onClick={() => this.handleAddModalShow()}
                                icon={<PlusOutlined/>}
                            >
                                添加分类
                            </Button>
                        </FormItem>
                    </Form>
                    <Table
                        rowKey="bid"
                        size="small"
                        columns={columns}
                        pagination={this.state.pagination}
                        dataSource={this.state.list}
                        onChange={this.handleTableChange}
                        bordered
                        loading={this.state.loading}
                    />
                </Modal>
            </div>
        );
    }
}

Collect.propTypes = {
    onReload: PropTypes.func,
    visible: PropTypes.bool,
    type: PropTypes.string,
    sid: PropTypes.number
};

export default Collect;
