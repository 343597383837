import React, {Component} from 'react';
import {Table, Select, Button, Form, message, Input, InputNumber, Tag, Modal, Dropdown} from 'antd';
import {
    SearchOutlined,
} from '@ant-design/icons';
import {GET_POST_DETAIL, GET_POST_LIST, REVIEW_POST} from "@/api/post";
import style from './style.module.css';

const FormItem = Form.Item;

/**
 * 基础网址管理列表
 */
class Index extends Component {
    state = {
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 50,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`
        },
        search: {
            id: '',
            title: '',
            page: 1,
            size: 50,
            status: ''
        },
        loading: false,
        detailVisible: false,
        detailTitle: '',
        detailContent: '',
        detailPostId: '',
    };

    componentDidMount() {
        this.getListData();
    }

    // 获取文章列表
    getListData = () => {
        this.setState({loading: true});
        const searchData = this.state.search;
        searchData.page = this.state.pagination.current;
        searchData.size = this.state.pagination.pageSize;

        GET_POST_LIST(searchData).then((response) => {
            let data = response.data;
            let pagination = this.state.pagination;
            pagination.total = data.total;
            this.setState({
                list: data.list,
                pagination,
                loading: false,
            });
        });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
            selectedRowKeys: [],
        }, () => {
            this.getListData()
        });

        window.scrollTo(0, 0);
    };

    // 搜索
    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState({search: values, pagination: pager}, () => {
            this.getListData();
        });
    };

    // 审核文章详情
    handleReview = (status) => {
        REVIEW_POST(this.state.detailPostId, status).then(() => {
            this.getListData();
            this.setState({
                detailVisible: false
            })
        });
    }

    showPostDetailModal = (title, postId) => {
        this.setState({
            detailVisible: true,
            detailTitle: title,
            detailPostId: postId
        });
        GET_POST_DETAIL(postId).then(rsp => {
            this.setState({detailContent: rsp.data.content});
        })
    }

    // 关闭弹框
    handleCancel = () => {
        this.setState({detailVisible: false});
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {title: 'ID', dataIndex: 'id', width: 100},
            {
                title: '标题', dataIndex: 'title', render: (title, record) => {
                    return <span className={style.title}
                                 onClick={() => this.showPostDetailModal(title, record.postId)}>{title}</span>
                }
            },
            {
                title: '创建人',
                dataIndex: 'nickname',
            },
            {title: '创建时间', dataIndex: 'formatTime',},
            {
                title: '状态', dataIndex: 'status', width: 120, render: (status, record) => {
                    if (!status) return <Tag>未审核</Tag>
                    // 审核中
                    if (status === 1) return <Tag color='green'>已审核</Tag>
                    if (status === -1) return <Tag color='error'>黑名单</Tag>
                    if (status===-2) return <Tag color='error'>黑名单 ( 修改 )</Tag>
                }
            },
        ];

        return (
            <div>
                <Modal
                    style={{top: 20}}
                    title={this.state.detailTitle}
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.state.detailVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    width={800}
                    footer={null}
                >
                    <div dangerouslySetInnerHTML={{__html: this.state.detailContent}} className={style.richContent}/>
                    <div className={style.footer}>
                        <Button className={style.footerBtn} onClick={this.handleCancel}>
                            关闭
                        </Button>
                        <Button className={style.footerBtn} type="primary" danger onClick={() => {
                            this.handleReview(-1)
                        }}>
                            不通过
                        </Button>
                        <Button className={style.footerBtn} type="primary" onClick={() => this.handleReview(1)}>
                            审核通过
                        </Button>
                    </div>
                </Modal>
                <Form layout="inline" className="search-form-fixed" onFinish={this.handleSearch}>
                    <FormItem name="id">
                        <InputNumber placeholder="书签ID"/>
                    </FormItem>
                    <FormItem name="title">
                        <Input placeholder="文章标题" style={{width: 150}} allowClear/>
                    </FormItem>

                    <FormItem name="status">
                        <Select
                            style={{width: 150}}
                            defaultValue=""
                            options={[
                                {
                                    value: '',
                                    label: '全部列表',
                                },
                                {
                                    value: '0',
                                    label: '未审核',
                                },
                                {
                                    value: '1',
                                    label: '已审核',
                                },
                                {
                                    value: '-1',
                                    label: '审核不通过',
                                },
                                {
                                    value: '-2',
                                    label: '黑名单（待审核）',
                                }
                            ]}
                        />
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                            搜索
                        </Button>
                    </FormItem>
                </Form>
                <div className="bg-white pd-10">
                    <Table
                        rowKey="id"
                        rowSelection={rowSelection}
                        loading={this.state.loading}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                    />
                </div>
            </div>
        );
    }
}

export default Index;
