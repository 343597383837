import React, {Component, Fragment} from 'react';
import {Button, message, Modal, Table} from 'antd';
import PropTypes from 'prop-types';
import {GET_SAME_HOST, REMOVE_COOL_SOURCE} from "@/api/cool";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {confirm} = Modal;
/**
 * 查询重复网址
 * @class Add
 * @extends {Component}
 */
class RepeatModal extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        list: [],
        pagination: {
            current: 1,
            pageSize: 50,
            total: 0,
            showQuickJumper: true,
            showTotal: total => `共计 ${total} 条`,
            size: 'default'
        },
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && (prevProps.visible !== this.props.visible)) {
            this.getListData();
        }
    }

    // 获取网址列表
    getListData = () => {
        GET_SAME_HOST(this.props.url).then(rsp => rsp.data).then(data => {
            this.setState({list: data.list});
        })
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onReload();
        this.props.onClose();
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });

        this.getListData();
    };

    // 从酷站中移除该网址
    handleRemoveItem = (id) => {
        confirm({
            title: '提醒',
            icon: <ExclamationCircleOutlined/>,
            content: '是否从酷站中移除该网址？此操作不会删除基础网址！',
            onOk: () => {
                REMOVE_COOL_SOURCE({id}).then((response) => {
                    if (response.data.code === 1) {
                        this.getListData();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    render() {
        const columns = [
            {
                title: '图标',
                dataIndex: 'icon',
                width: 70,
                render: (src) => {
                    return (
                        <div className="table-cover">
                            <img width="32" src={src} alt=""/>
                        </div>
                    );
                },
            },
            {
                title: '名称',
                dataIndex: 'baseName',
                width: 300,
                render: (name, record) => {
                    return <Fragment>
                        <p>
                            <span>{name}</span>
                        </p>
                        <p style={{color: '#999', marginBottom: 0}}>{record.createTime}</p>
                    </Fragment>
                }
            },
            {
                title: 'URL',
                dataIndex: 'url',
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.baseSummary}</p>
                            <p style={{marginBottom: 0}}>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            {
                title: '点击',
                width: 120,
                dataIndex: 'clickAmount'
            },
            {
                title: '操作',
                width: 320,
                render: (record) => {
                    return <div>
                        <Button type='link' danger
                                onClick={() => this.handleRemoveItem(record.csId)}>
                            移除
                        </Button>
                    </div>
                },
            },
        ];

        return (
            <div>
                <Modal
                    width='90%'
                    title="查找同域名网站"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Table
                        size="small"
                        rowKey="csId"
                        loading={this.state.loading}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                    />
                </Modal>
            </div>
        );
    }
}

RepeatModal.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default RepeatModal;
