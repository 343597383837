import React, {Component} from 'react';
import {Button, Form, Input, Modal} from 'antd';
import PropTypes from 'prop-types';
import {CRAWLER_SOURCE_INFO, GET_SOURCE_INFO} from '@/api/source';
import {ADD_SOURCE} from '@/api/source';
import {DownloadOutlined} from "@ant-design/icons";
import styleAddSource from './add-source.module.css';

const FormItem = Form.Item;
const {TextArea} = Input;

/**
 * 编辑、添加网址
 * @class Add
 * @extends {Component}
 */
class AddSource extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        crawlerLoading: false,
        crawlerInfo: {
            name: '',
            summary: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && (prevProps.visible !== this.props.visible)) {
            if (this.props.sid) {
                GET_SOURCE_INFO(this.props.sid).then((rsp) => {
                    this.formRef.current.setFieldsValue(rsp.data);
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    }

    // 提交保存数据
    handleSubmit = (values) => {
        values.sour_id = this.props.sid;
        this.setState({loading: true});
        ADD_SOURCE(values).then(rsp => {
            this.props.onReload();
            this.props.onClose();
        }).finally(() => {
            this.setState({
                loading: false
            })
        });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    handleEnterKeyUp = (e) => {
        // Enter
        if (e.keyCode === 13) {
            this.formRef.current.submit();
        }
    }

    crawlerWebInfo = () => {
        this.setState({crawlerLoading: true});
        const url = this.formRef.current.getFieldValue('sour_url');
        CRAWLER_SOURCE_INFO(url).then(rsp => {
            this.setState({
                crawlerInfo: {
                    name: rsp.data.name,
                    summary: rsp.data.description
                }
            })
        }).finally(() => {
            this.setState({crawlerLoading: false})
        })
    }

    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        return (
            <div>
                <Modal
                    style={{top: 20}}
                    title="添加"
                    cancelText="关闭"
                    okText="立即保存"
                    visible={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onKeyUp={this.handleEnterKeyUp} onFinish={this.handleSubmit}>
                        <FormItem
                            name="name"
                            label="网站名称"
                            rules={[
                                {required: true, message: '必须填写名称'},
                                {max: 50, message: '网站名称不能超过50个字'},
                            ]}
                        >
                            <Input placeholder="网站名称" allowClear/>
                        </FormItem>
                        <FormItem
                            name="slogan"
                            label="slogan"
                            rules={[{max: 100, message: 'Slogan不能超过100个字'}]}
                        >
                            <Input placeholder="网站Slogan" allowClear/>
                        </FormItem>
                        <FormItem
                            name="summary"
                            label="网站描述"
                            rules={[{max: 200, message: '网站描述不能超过200个字'}]}
                        >
                            <TextArea rows={3} allowClear placeholder="网站描述"/>
                        </FormItem>
                        <FormItem
                            name="sour_url"
                            label="网站URL"
                            rules={[
                                {required: true, message: '请输入URL'},
                                {type: 'url', message: '请输入正确的URL'},
                            ]}
                        >
                            <TextArea rows={4} placeholder="网站URL"/>
                        </FormItem>
                        <FormItem
                            name="referral_link"
                            label="推广链接"
                            rules={[{type: 'url', message: '请输入正确的URL'}]}
                        >
                            <TextArea rows={4} placeholder="请输入推广链接"/>
                        </FormItem>
                    </Form>
                    <div className={styleAddSource.crawlerWeb}>
                        <Button type="primary" onClick={this.crawlerWebInfo} loading={this.state.crawlerLoading}
                                icon={<DownloadOutlined/>}>抓取信息</Button>
                        <div>
                            <p>名称: {this.state.crawlerInfo.name}</p>
                            <p>简介: {this.state.crawlerInfo.summary}</p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

AddSource.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default AddSource;
