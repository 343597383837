import React, { Component } from "react";
import { Button, Form, Upload, Modal, message, Input } from "antd";
import PropTypes from "prop-types";
import { GET_NAV_GROUP, DELETE_GROUP, SORT_GROUP } from "@/api/common";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const FormItem = Form.Item;
/**
 * 审核网址
 * @class Add
 * @extends {Component}
 */
class Index extends Component {
    formRef = React.createRef();

    state = {
        name: "",
        staticSrc:''
    };

    componentDidMount() {
        this.getListData();
    }

    // 获取网址列表
    getListData = () => {
        this.setState({ loading: true });
        GET_NAV_GROUP()
            .then((response) => {
                let data = response.data;
                this.setState({
                    list: data.list,
                    loading: false,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    showAddGroupModal = (id) => {
        let data = {};
        if (id) {
            data = this.state.list.find((f) => f.id === id);
        }
        this.setState({
            editGroupVisible: true,
            editGroupId: id,
            editGroupData: data,
        });
    };

    deleteGroup = (id) => {
        Modal.confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否删除当前分组？删除后将无法恢复",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                DELETE_GROUP(id).then(() => {
                    this.getListData();
                });
            },
        });
    };

    hideAddGroupModal = () => {
        this.setState({
            editGroupVisible: false,
        });
    };

    // 处理修改sort输入框数字操作
    handleSortChange = (sort, id) => {
        const list = this.state.list;
        list.forEach((item, index) => {
            if (parseInt(item.id, 10) === parseInt(id, 10)) {
                list[index].sort = sort;
                this.setState({
                    list,
                    hasUpdateSort: true,
                });
            }
        });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SORT_GROUP(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                this.setState({
                    hasUpdateSort: false,
                });
            }
        });
    };

    handleNameInputChange=(e)=>{
        this.setState({
            name: e.target.value
        })
    }

    render() {
        const upload_props = {
            name: "UploadStatic[imageFiles]",
            action: "/api/upload/static",
            multiple: false,
            data: { name: this.state.name },
            showUploadList: false,
            onChange: (info) => {
                if (info.file.status !== "uploading") {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === "done") {
                    if (info.file.response.code === 1) {
                        this.setState({
                            staticSrc: info.file.response.info,
                        });
                        message.success(`上传成功`);
                    } else {
                        message.success(info.file.response.msg);
                    }
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <Form layout="inline" className="search-form-fixed">
                    <FormItem name="name">
                        <Input placeholder="资源名称" style={{ width: 300 }} onChange={this.handleNameInputChange} allowClear />
                    </FormItem>
                    <FormItem>
                        <Upload {...upload_props}>
                            <Button type="primary" disabled={!this.state.name}>
                                上传ICON
                            </Button>
                        </Upload>
                    </FormItem>
                </Form>

                <div>{this.state.staticSrc}</div>
            </div>
        );
    }
}

Index.propTypes = {
    onClose: PropTypes.func,
    onReload: PropTypes.func,
};

export default Index;
