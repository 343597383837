import React from 'react';
import { Form, message, Input, Upload, Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { GET_CATEGORY_DETAIL, ADD_CATEGORY } from '@/api/category';

const FormItem = Form.Item;

class AddForm extends React.PureComponent {
  formRef = React.createRef();
  state = {
    icon: '',
    host: '',
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.visible !== this.props.visible) {
      if (!this.props.visible) return false;
      if (!this.props.cid) {
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          parent_id: this.props.pid,
        });
        this.setState({
          icon: '',
        });
        return false;
      }
      GET_CATEGORY_DETAIL(this.props.cid).then((rsp) => {
        const data = rsp.data.info;
        this.setState({
          host: rsp.data.host,
          icon: data.icon,
        });
        this.formRef.current.setFieldsValue({
          name: data.name,
          alias_name: data.alias_name,
            tags: data.tags,
          iconfont: data.iconfont,
          parent_id: data.parent_id,
          sort: data.sort,
        });
      });
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.formRef.current.submit();
  };

  handleSubmit = (values) => {
    values.icon = this.state.icon;
    values.cid = this.props.cid;
    ADD_CATEGORY(values).then(() => {
      message.success('保存成功');
      this.props.reloadLists();
      this.handleCancel();
    });
  };

  deletePic = () => {
    this.setState({
      icon: '',
    });
  };

    handleEnterKeyUp=(e)=>{
        // Enter
        if (e.keyCode === 13) {
            this.formRef.current.submit();
        }
    }

  render() {
    const upload_props = {
      name: 'UploadIcon[imageFiles]',
      action: '/api/upload/icon',
      multiple: false,
      data: { module: 'category' },
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
          // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          this.setState({
            icon: info.file.response.save,
            host: info.file.response.host,
          });
          message.success(`上传成功`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
    };

    const tailLayout = {
      wrapperCol: {
        offset: 4,
        span: 20,
      },
    };

    return (
      <Modal
        title="编辑分类"
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Form ref={this.formRef} onKeyUp={this.handleEnterKeyUp} onFinish={this.handleSubmit} {...layout}>
          <FormItem
            name="name"
            label="分类名称"
            rules={[{ required: true, message: '分类名称必须填写' }]}
          >
            <Input placeholder="分类名称" />
          </FormItem>
            <FormItem
                name="tags"
                label="搜索标签"
            >
                <Input placeholder="搜索标签" />
            </FormItem>
          <FormItem name="sort" label="排序">
            <Input placeholder="排序" />
          </FormItem>
          <FormItem name="alias_name" label="别名">
            <Input placeholder="别名" />
          </FormItem>
          <FormItem name="iconfont" label="iconfont">
            <Input placeholder="iconfont" />
          </FormItem>
          <FormItem name="parent_id" label="父级ID">
            <Input placeholder="请输入父级ID" />
          </FormItem>
          <FormItem label="上传图片">
            <div>
              <Upload {...upload_props}>
                <Button>
                  <UploadOutlined /> 上传ICON
                </Button>
              </Upload>
              {this.state.icon ? (
                <div
                  className="mt-15"
                  style={{
                    display: this.state.icon.length > 0 ? 'block' : 'none',
                  }}
                >
                  <img
                    width="120"
                    src={this.state.host + this.state.icon}
                    alt=""
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </FormItem>

          <FormItem label="删除图片">
            <Button onClick={this.deletePic}>删除图片</Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

AddForm.propTypes = {
  reloadLists: PropTypes.func,
};
export default AddForm;
