import request from "@/api/request";

// 添加ICON
export const ADD_ICON = (data) => {
    return request.post("/icon/add", data);
};

// 获取ICON列表
export const GET_LIST = (data) => {
    return request.get("/icon/index", data);
};

// 获取ICON详细信息
export const GET_INFO = (id) => {
    return request.get("/icon/get-info", { id });
};

// 复用ICON
export const REPEAT_ICON = (data) => {
    return request.post("/icon/repeat", data);
};

// 审核host信息
export const REVIEW_HOST = (iconId, reviewStatus) => {
    return request.post("/icon/review", { iconId, reviewStatus });
};
