import React, {useEffect} from 'react';
import {Form, Modal, Input, message} from 'antd';
import {REMARK_CATEGORY_SOURCE} from '@/api/source';

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const CategorySourceRemark = ({visible, id, remark, desc, onReload, onCancel}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({remark, desc});
        }
    }, [visible]);

    // 处理点击弹框的确认按钮
    const handleOk = () => {
        form.submit();
    };

    // 提交表单
    const handleSubmit = ({remark, desc}) => {
        REMARK_CATEGORY_SOURCE(id, remark, desc).then((rsp) => {
            if (rsp.data.code === 1) {
                onReload();
                onCancel();
            } else {
                message.error(rsp.data.msg);
            }
        });
    };

    return (
        <Modal title="备注" visible={visible} onOk={handleOk} onCancel={onCancel}>
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="remark"
                    label="备注"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="desc"
                    label="网址描述"
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CategorySourceRemark;
