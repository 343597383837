import React, { Component } from "react";
import { Table, Button, Form, message, InputNumber, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, SortAscendingOutlined, CheckOutlined } from "@ant-design/icons";
import { REMOVE_SPECIAL_CATEGORY_MAP, GET_SPECIAL_CATEGORY_MAP, SPECIAL_CATEGORY_SORT } from "@/api/cool";
import AddSpecialCategory from "@/pages/Cool/components/AddSpecialCategory";
import SpecialCategorySelectorModal from "@/pages/Cool/components/SpecialCategorySelectorModal";
import RemarkSpecialCategoryMap from "@/pages/Cool/components/RemarkSpecialCategoryMap";

const FormItem = Form.Item;
const { confirm } = Modal;

/**
 * 发现 - 专题分类
 */
class Index extends Component {
    state = {
        list: [],
        loading: false,
        hasUpdateSort: false,
        // 添加分类弹框
        addCategoryVisible: false,
        editCategoryId: "",

        viewCategoryVisible: false,

        remarkModalVisible: false,
        remarkModalId: "",
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            this.getSpecialCategoryListData();
        }
    }

    // 获取专题列表
    getSpecialCategoryListData = () => {
        this.setState({ loading: true });
        GET_SPECIAL_CATEGORY_MAP(this.props.specialId)
            .then((rsp) => {
                this.setState({
                    list: rsp.data.list,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // 删除分类
    handleDelete = (id) => {
        confirm({
            title: "提醒",
            icon: <ExclamationCircleOutlined />,
            content: "是否从专题中移除当前分类？",
            onOk: () => {
                REMOVE_SPECIAL_CATEGORY_MAP(id).then((response) => {
                    if (response.data.code === 1) {
                        this.getSpecialCategoryListData();
                        this.props.onReload();
                    } else {
                        message.error(response.data.msg);
                    }
                });
            },
        });
    };

    // 处理修改sort输入框数字操作
    handleSortChange = (sort, id) => {
        const list = this.state.list;
        const item = list.find((f) => f.id === id);
        item.sort = sort;
        this.setState({
            list,
            hasUpdateSort: true,
        });
    };

    // 更新分类下的网址排序
    handleUpdateSort = () => {
        const data = this.state.list.map((item, index) => {
            return {
                id: item.id,
                sort: this.state.hasUpdateSort ? item.sort : index,
            };
        });

        SPECIAL_CATEGORY_SORT(data).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getSpecialCategoryListData();
                this.setState({
                    hasUpdateSort: false,
                });
                this.props.onReload();
            }
        });
    };

    showAddCategoryModal = () => {
        this.setState({
            editCategoryId: "",
            addCategoryVisible: true,
        });
    };

    showEditCategoryModal = (id) => {
        this.setState({
            editCategoryId: id,
            addCategoryVisible: true,
        });
    };

    handleAddCategoryClose = () => {
        this.setState({
            addCategoryVisible: false,
        });
    };

    showCategoryViewModal = () => {
        this.setState({
            viewCategoryVisible: true,
        });
    };

    hideCategoryViewModal = () => {
        this.setState({
            viewCategoryVisible: false,
        });
    };

    showRemarkModal = (id) => {
        this.setState({
            remarkModalVisible: true,
            remarkModalId: id,
        });
    };

    hideRemarkModal = () => {
        this.setState({
            remarkModalVisible: false,
        });
    };

    handleCancel = () => {
        this.props.onClose();
    };

    handleReload = () => {
        // 刷新弹框列表，同时刷新上层列表
        this.getSpecialCategoryListData();
        this.props.onReload();
    };

    render() {
        const columns = [
            { title: "分类ID", dataIndex: "id", width: 100 },
            {
                title: "名称",
                dataIndex: "title",
                render: (title, record) => {
                    return (
                        <span>
                            {title}
                            {record.remark ? ` ( ${record.remark} )` : ""}
                        </span>
                    );
                },
            },
            {
                title: "图标",
                dataIndex: "icon",
            },
            {
                title: "排序",
                dataIndex: "sort",
                render: (sort, record) => (
                    <InputNumber
                        key={record.id}
                        min={0}
                        value={sort}
                        onChange={(e) => this.handleSortChange(e, record.id)}
                    />
                ),
            },
            {
                title: "操作",
                width: 60,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.showEditCategoryModal(record.categoryId)}>
                                编辑
                            </Button>
                            <Button type="link" onClick={() => this.showRemarkModal(record.id)}>
                                备注
                            </Button>
                            <Button type="link" onClick={() => this.handleDelete(record.id)} danger>
                                移除
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <div>
                <AddSpecialCategory
                    visible={this.state.addCategoryVisible}
                    id={this.state.editCategoryId}
                    specialId={this.props.specialId}
                    onReload={this.handleReload}
                    onClose={this.handleAddCategoryClose}
                />
                <RemarkSpecialCategoryMap
                    visible={this.state.remarkModalVisible}
                    id={this.state.remarkModalId}
                    onClose={this.hideRemarkModal}
                    onReload={this.handleReload}
                />
                <SpecialCategorySelectorModal
                    visible={this.state.viewCategoryVisible}
                    specialId={this.props.specialId}
                    onClose={this.hideCategoryViewModal}
                    onReload={this.handleReload}
                />
                <Modal
                    style={{ top: 0 }}
                    width="80%"
                    title="分类管理"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    confirmLoading={this.state.loading}
                >
                    <Form layout="inline" className="mb-15 search-form-fixed">
                        <FormItem>
                            <Button type="primary" onClick={this.showAddCategoryModal} icon={<PlusOutlined />}>
                                添加分类
                            </Button>
                        </FormItem>
                        <FormItem>
                            <Button onClick={this.showCategoryViewModal} icon={<CheckOutlined />}>
                                从分类库选择
                            </Button>
                        </FormItem>

                        <FormItem>
                            <Button onClick={this.handleUpdateSort} icon={<SortAscendingOutlined />}>
                                {this.state.hasUpdateSort ? "更新排序" : "整理排序"}
                            </Button>
                        </FormItem>
                    </Form>

                    <Table
                        rowKey={(record) => record.id}
                        pagination={false}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                        loading={this.state.loading}
                    />
                </Modal>
            </div>
        );
    }
}

export default Index;
