import React, { Component } from "react";
import { Form, Input, message, Modal } from "antd";
import { GET_CATEGORY_MAP_DETAIL, EDIT_SPECIAL_CATEGORY_MAP } from "@/api/cool";

const FormItem = Form.Item;

class Index extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                GET_CATEGORY_MAP_DETAIL(this.props.id).then((rsp) => {
                    const { remark } = rsp.data;

                    this.formRef.current.setFieldsValue({
                        remark,
                    });
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    };

    // 提交保存数据
    handleSubmit = (values) => {
        const { remark } = values;
        this.setState({ loading: true });
        EDIT_SPECIAL_CATEGORY_MAP(this.props.id, remark)
            .then((rsp) => {
                if (rsp.data.code === 1) {
                    this.props.onReload();
                    this.props.onClose();
                } else {
                    message.error(rsp.data.msg);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        return (
            <div>
                <Modal
                    title="编辑分类备注"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onFinish={this.handleSubmit}>
                        <FormItem name="remark" label="分类备注" rules={[{ max: 20, message: "备注不能超过20个字" }]}>
                            <Input placeholder="分类备注" />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Index;
