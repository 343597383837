import React from 'react';
import { Form, message, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { REPEAT_ICON } from '../../../api/icon';

const FormItem = Form.Item;

/**
 * icon图标复用
 *
 * @class Repeat
 * @extends {Component}
 */
class Repeat extends React.PureComponent {
  formRef = React.createRef();

  handleSubmit = (values) => {
    values.icon_id = this.props.id; // 会被输入的id覆盖的icon
    REPEAT_ICON(values).then((response) => {
      if (response.data.code === 1) {
        this.props.onReload();
        this.props.onClose();
      } else {
        message.error(response.data.msg);
      }
    });
  };

  // 点击弹框确认按钮
  handleOk = () => {
    this.formRef.current.submit();
  };

  // 关闭弹框
  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
    };

    return (
      <Modal
        title="复用ICON"
        cancelText="关闭"
        okText="立即保存"
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Form {...layout} ref={this.formRef} onFinish={this.handleSubmit}>
          <FormItem
            name="input_id"
            label="input_id"
            rules={[{ required: true, message: '请输入需要复用的icon_id' }]}
          >
            <Input placeholder="请输入需要复用的icon_id" />
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

Repeat.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
};

export default Repeat;
