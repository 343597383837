import request from '@/api/request';

// 提交酷站审核结果
export const GET_TAG_LIST = ({wd = '', page = 1, size = 20, selected = null, icon = null}) => {
  return request.get('/tags/list', {wd, page, size, selected, icon});
};

// 设置精选
export const SET_SELECTED = ({id}) => {
  return request.post('/tags/set-selected', {id});
};

// 获取标签详情
export const GET_TAG_DETAIL = ({id = ''}) => {
  return request.get('/tags/detail', {id});
};

// 设置icon
export const SET_ICON = ({id, icon}) => {
  return request.post('/tags/set-icon', {id, icon});
};
