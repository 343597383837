import request from '@/api/request';

// 保存、添加分类
export const ADD_CATEGORY = (data) => {
    return request.post('/category/add', data);
};

// 删除分类
export const DELETE_CATEGORY = (cid) => {
    return request.post('/category/delete', {cid});
};

// 通过父级分类获取子级分类
export const GET_CATEGORY_LIST = (data) => {
    return request.get('/category/index', data);
};

// 获取分类详细信息
export const GET_CATEGORY_DETAIL = (cid) => {
    return request.get('/category/get-detail', {cid});
};

// 保存分类排序
export const SORT_CATEGORY = (data) => {
    return request.post('/category/sort', data);
};

// 根据别名获取分类下的笔袋
export const GET_CATEGORY_BAG_ALIAS = (alias, sid, cid) => {
    return request.get('/category/get-bag', {alias, sid, cid});
};

// 在分类下添加笔袋
export const ADD_CATEGORY_BAG = (cid, name, bid) => {
    return request.post('/category/add-category-bag', {cid, name, bid});
};

// 将网址收藏到分类下的笔袋中
export const COLLECT_SOURCE_TO_BAG = (sid, bid) => {
    return request.post('/category/collect', {sid, bid});
};
