import React, { Component } from "react";
import { Table, Button, Form, message, Input, Dropdown } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { JOIN_SINGLE_BLACKLIST, JOIN_COOL, GET_ITEM_LOG } from "@/api/source";
import moduleCss from "@/pages/Source/style/index.module.css";
import {REVIEW_HOST} from "@/api/icon";

const FormItem = Form.Item;

/**
 * 基础网址管理列表
 */
class Index extends Component {
    formRef = React.createRef();

    state = {
        list: [],
        selectedRowKeys: [],
        pagination: {
            current: 1,
            pageSize: 10,
            showQuickJumper: true,
            showTotal: (total) => `共计 ${total} 条`,
            size: "default",
        },
        search: {
            name: "",
            page: 1,
            sour_id: "",
            sour_url: "",
            summary: "",
        },
        endRecordId: null,
        sortMethod: "",
        loading: false,
        // 收藏网址
        collectVisible: false,
        collectSourceId: "",
        // 编辑网址
        editSourceId: "", // 编辑网址id
        editSourceVisible: false, // 编辑网址弹框是否显示
        // icon
        editIconId: "",
        editIconVisible: false,
        // 编辑标签
        tagsVisible: false,
        tagsSourceId: "",
        batchBlackListLoading: false, // 批量添加黑名单是否loading
        collectToSpecialVisible: false, // 收录到专题导航
        collectToSpecialId: "",
    };

    componentDidMount() {
        this.getListData();
    }

    // 获取网址列表
    getListData = () => {
        this.setState({ loading: true });

        GET_ITEM_LOG().then((response) => {
            let data = response.data;
            this.setState({
                list: data.list,
                loading: false,
            });
        });
    };

    handleTableChange = (pagination, filters, sorter, extra) => {
        const pager = this.state.pagination;
        pager.current = extra.action === "sort" ? 1 : pagination.current;
        this.setState(
            {
                pagination: pager,
                selectedRowKeys: [],
                sortMethod: sorter.order,
            },
            () => {
                this.getListData();
            }
        );

        window.scrollTo(0, 0);
    };

    // 搜索
    handleSearch = (values) => {
        const pager = this.state.pagination;
        pager.current = 1;
        this.setState({ search: values, pagination: pager }, () => {
            this.getListData();
        });
    };

    // 单个网址加入黑名单
    handleJoinSingleBlacklist = (sourceId) => {
        JOIN_SINGLE_BLACKLIST(sourceId).then((rsp) => {
            if (rsp.data.code === 1) {
                this.getListData();
                message.success("操作成功").then((r) => {});
            } else {
                message.error(rsp.data.msg).then((r) => {});
            }
        });
    };

    // 加入、取消黑名单
    handleJoinBlackList = (iconId) => {
        if (!Array.isArray(iconId)) {
            iconId = [iconId];
        }
        this.setState({ batchBlackListLoading: true });
        REVIEW_HOST(iconId,'blacklist').then((rsp) => {
                if (rsp.data.code === 1) {
                    this.getListData();
                    message.success("操作成功");
                } else {
                    message.error(rsp.data.msg);
                }
            })
            .finally(() => {
                this.setState({ batchBlackListLoading: false, selectedRowKeys: [] });
            });
    };

    joinCool = (id, status) => {
        JOIN_COOL(id, status).then(() => {
            this.getListData();
        });
    };

    render() {
        const columns = [
            { title: "ID", dataIndex: "sid", width: 100 },
            {
                title: "图标",
                dataIndex: "icon",
                width: 70,
                render: (src, record) => {
                    return (
                        <div className="table-cover">
                            <img width="32" src={src} alt="" />
                        </div>
                    );
                },
            },
            {
                title: "名称",
                dataIndex: "name",
                width: 300,
                render: (name, record) => {
                    return (
                        <div>
                            <p>{name}</p>
                            {record.slogan ? <p className={moduleCss.slogan}>{record.slogan}</p> : null}
                        </div>
                    );
                },
            },
            {
                title: "URL",
                dataIndex: "url",
                render: (txt, record) => {
                    return (
                        <div>
                            <p>{record.summary}</p>
                            <p>
                                <a target="_blank" href={txt} rel="noreferrer">
                                    {txt}
                                </a>
                            </p>
                        </div>
                    );
                },
            },
            {
                title: "精选",
                width: 200,
                render: (record) => {
                    return (
                        <div>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => this.joinCool(record.id, 1)}
                            >
                                加入酷站
                            </Button>
                            <Button onClick={() => this.joinCool(record.id, -1)}>忽略</Button>
                        </div>
                    );
                },
            },
            { title: "点击", sorter: true, dataIndex: "clickAmount", width: 120 },
            {
                title: "操作",
                width: 80,
                render: (record) => {
                    const items = [];
                    items.push({ label: "加入黑名单", key: "join-blacklist" });

                    items.push({
                        type: "divider",
                    });
                    if (record.oneBlacklist) {
                        items.push({ label: "取消单个黑名单", key: "join-single-blacklist" });
                    } else {
                        items.push({ label: "标记单个黑名单", key: "join-single-blacklist" });
                    }

                    const onClick = ({ key }) => {
                        switch (key) {
                            case "join-blacklist":
                                this.handleJoinBlackList(record.iconId);
                                break;
                            case "join-single-blacklist":
                                this.handleJoinSingleBlacklist(record.sid);
                                break;
                            default:
                                break;
                        }
                    };

                    return (
                        <Dropdown
                            menu={{
                                items,
                                onClick,
                            }}
                            placement="bottom"
                        >
                            <a onClick={(e) => e.preventDefault()}>操作</a>
                        </Dropdown>
                    );
                },
            },
        ];

        return (
            <div>
                <Form layout="inline" ref={this.formRef} className="search-form-fixed" onFinish={this.handleSearch}>
                    <FormItem name="sour_id">
                        <Input placeholder="网站ID" style={{ width: 100 }} allowClear />
                    </FormItem>
                    <FormItem name="name">
                        <Input placeholder="网站名称" style={{ width: 120 }} allowClear />
                    </FormItem>
                    <FormItem name="sour_url">
                        <Input placeholder="网站URL" allowClear style={{ width: 200 }} />
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                            搜索
                        </Button>
                    </FormItem>
                </Form>
                <Table
                    rowKey="sid"
                    size="small"
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                />
            </div>
        );
    }
}

export default Index;
