import React, { Component } from "react";
import { Table, Modal, Button, message } from "antd";
import { GET_SPECIAL_CATEGORY, CHOOSE_CATEGORY } from "@/api/cool";
import SpecialCategoryBookmarkModal from "@/pages/Cool/components/SpecialCategoryBookmarkModal";
import style from "@/pages/Cool/style/index.module.css";
/**
 * 分类库选择器
 */
class Index extends Component {
    state = {
        list: [],
        loading: false,
        hasUpdateSort: false,
        addCategoryVisible: false,
        editCategoryId: "",
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0,
            showTotal: (total) => `共计 ${total} 条`,
            showQuickJumper: true,
            size: "default",
        },
        viewBookmarkVisible: false,
        viewBookmarkCid: "",
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            this.getSpecialCategoryListData();
        }
    }

    // 获取专题列表
    getSpecialCategoryListData = () => {
        this.setState({ loading: true });
        const { current, pageSize } = this.state.pagination;
        const pagination = this.state.pagination;
        GET_SPECIAL_CATEGORY(current, pageSize)
            .then((rsp) => {
                pagination.total=rsp.data.total;
                this.setState({
                    list: rsp.data.list,
                    pagination,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    handleTableChange = (pagination) => {
        const pager = this.state.pagination;
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.getSpecialCategoryListData();
    };

    showCategoryBookmarkModal = (cid) => {
        this.setState({
            viewBookmarkVisible: true,
            viewBookmarkCid: cid,
        });
    };

    hideCategoryBookmarkModal = () => {
        this.setState({
            viewBookmarkVisible: false,
        });
    };

    handleCancel = () => {
        this.props.onClose();
    };

    handleChoose = (cid) => {
        CHOOSE_CATEGORY(cid, this.props.specialId).then(() => {
            message.success("添加成功");
            this.props.onReload();
        });
    };

    render() {
        const columns = [
            { title: "分类ID", dataIndex: "id", width: 100 },
            {
                title: "名称",
                dataIndex: "title",
                render: (title, record) => {
                    return (
                        <span
                            className={style.tableCategoryName}
                            onClick={() => this.showCategoryBookmarkModal(record.id)}
                        >
                            {title}
                        </span>
                    );
                },
            },
            {
                title: "图标",
                dataIndex: "icon",
            },
            {
                title: "操作",
                width: 60,
                render: (record) => {
                    return (
                        <div className="table-action-field">
                            <Button type="link" onClick={() => this.handleChoose(record.id)}>
                                添加
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return (
            <Modal
                style={{ top: 0 }}
                width="800px"
                title="选择分类"
                cancelText="关闭"
                okText="立即保存"
                open={this.props.visible}
                onCancel={this.handleCancel}
                footer={null}
                confirmLoading={this.state.loading}
            >
                <div>
                    <SpecialCategoryBookmarkModal
                        visible={this.state.viewBookmarkVisible}
                        id={this.state.viewBookmarkCid}
                        onClose={this.hideCategoryBookmarkModal}
                    />

                    <Table
                        rowKey={(record) => record.id}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        columns={columns}
                        dataSource={this.state.list}
                        bordered
                        loading={this.state.loading}
                    />
                </div>
            </Modal>
        );
    }
}

export default Index;
