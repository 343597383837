import React, {Component} from 'react';
import {Button, Form, message, Upload, Modal} from 'antd';
import PropTypes from 'prop-types';
import {ADD_ICON, GET_INFO} from '@/api/icon';
import {GET_TAG_DETAIL, SET_ICON} from '@/api/tags';
import {IMG_HOST} from "@/config/config";

const FormItem = Form.Item;

/**
 * 添加、编辑ICON
 * @class Add
 * @extends {Component}
 */
class Add extends Component {
  formRef = React.createRef();

  state = {
    icon: '',
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.visible && prevProps.visible !== this.props.visible) {
      if (this.props.id) {
        GET_TAG_DETAIL(this.props).then(rsp => {
          this.setState({icon: rsp.data.icon});
        });
      } else {
        this.formRef.current.resetFields();
      }
    }
  }

  handleSubmit = () => {
    this.setState({loading: true});

    SET_ICON({id: this.props.id, icon: this.state.icon}).then(()=>{
      this.props.onReload();
      this.props.onClose();
    }).finally(()=>{
      this.setState({loading: false});
    })
  };

  // 点击弹框OK
  handleOk = () => {
    this.formRef.current.submit();
  };
  // 关闭弹框
  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const upload_props = {
      name: 'UploadIcon[imageFiles]',
      action: '/api/upload/icon',
      multiple: false,
      data: {module: 'website'},
      showUploadList: false,
      onChange: (info) => {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          if (info.file.response.code === 1) {
            this.setState({
              icon: info.file.response.save
            });
            message.success(`上传成功`);
          } else {
            message.success(info.file.response.msg);
          }
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
    };

    return (
      <div>
        <Modal
          title="添加"
          cancelText="关闭"
          okText="立即保存"
          visible={this.props.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          confirmLoading={this.state.loading}
        >
          <Form ref={this.formRef} {...layout} onFinish={this.handleSubmit}>
            <FormItem label="上传ICON">
              <div>
                <Upload {...upload_props}>
                  <Button>上传ICON</Button>
                </Upload>
                {this.state.icon ? (
                  <div className="mt-15">
                    <img
                      width="32"
                      src={IMG_HOST + '/' +this.state.icon}
                      alt=""
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

Add.propTypes = {
  onReload: PropTypes.func,
};

export default Add;
