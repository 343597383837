import React, {Component} from 'react';
import {Table, Button, Tag, Form, Modal, Row, Col, List} from 'antd';
import axios from '@/plugins/axios'
import qs from 'qs'
import Add from './Add'

const FormItem = Form.Item;

class Index extends Component {
  state = {
    lists: [],
    selectedRowKeys: [],
    pagination: {
      current: 1,
      showQuickJumper: true
    },
    iconLoading: false,
    search: {},
    checkbox: {
      user_create: false,
      official_create: true
    },
    dropdown_index: '',
    alias: '',
    loading: false,
    sub_category: [],
    activeCid: 0,
    hasUpdateSort: false,
    originImg: '',
    visible: false
  };

  componentDidMount() {
    this.getCategory();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({selectedRowKeys});
  };

  getCategory() {
    this.setState({loading: true});
    axios.post('/category/index', qs.stringify({alias: 'skin'}))
      .then((response) => {
        let data = response.data;
        let activeCid = data.lists.length > 0 ? data.lists[0].cate_id : null;
        this.setState({
          sub_category: data.lists,
          activeCid: activeCid
        }, () => {
          this.getLists();
          return true;
        });
      });
  }

  changeCategory(cid) {
    this.setState({activeCid: cid}, () => this.getLists());
  }

  getLists = () => {
    let data = this.state.search;
    data.page = this.state.pagination.current;
    data.cate_id = this.state.activeCid;
    this.setState({loading: true, activeCid: this.state.activeCid});
    axios.post('/skin/index', qs.stringify(data))
      .then((response) => {
        let data = response.data;
        let pager = this.state.pagination;
        pager.total = data.total;
        pager.pageSize = data.page_size;
        this.setState({
          lists: response.data.lists,
          pagination: pager,
          loading: false
        });
      });
  };

  handleTableChange = (pagination) => {
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedRowKeys: []
    });

    window.scrollTo(0, 0);
    this.getLists();
  };

  onRef = (ref) => {
    this.child = ref;
  };

  handleAdd = () => {
    this.child.showModal(this.state.sub_category, this.state.activeCid, null);
  };

  handleEdit(skin) {
    this.child.showModal(this.state.sub_category, this.state.activeCid, skin);
  }

  // 查看原图
  viewOriginImg(origin) {
    this.setState({
      visible: true,
      originImg: origin
    })
  }

  handleCancel = () => {
    this.setState({visible: false});
  };

  updateSort = () => {
    let lists = this.state.lists;
    let data = [];
    lists.map((item) => {
      data.push({sb_id: item.sb_id, sort: item.sort});
      return true;
    });
    axios.post('/box/sort', qs.stringify(data))
      .then((response) => {
        if (response.data.code === 1) {
          this.getLists();
          this.setState({
            hasUpdateSort: false
          });
        }
      });
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {title: '皮肤ID', dataIndex: 'skin_id', width: 100},
      {
        title: '皮肤图片', dataIndex: 'image', render: (image, record) => {
          return <a onClick={() => this.viewOriginImg(record.origin)} href="Javascript:void(0);" rel="noreferrer">
            <img src={image} width={100} alt=""/>
          </a>
        }
      },
      {
        title: '主题', dataIndex: 'theme', render: (theme) => {
          return (parseInt(theme, 10) === 1 ? <Tag color="#108ee9">深色</Tag> : <Tag>浅色</Tag>)
        }
      },
      {
        title: '不透明度', dataIndex: 'opacity', render: (opacity) => {
          return (opacity + '%')
        }
      },
      {
        title: '参数', dataIndex: 'attachment', render: (attachment, record) => {
          return <p>
            <span>{attachment}</span>-<span>{record.position}</span>-<span>{record.repeat}</span>
          </p>
        }
      },
      {
        title: '来源网站', dataIndex: 'source_name', render: (txt, record) => {
          return <a href={record.source_url} target="_blank" rel="noreferrer">{txt}</a>
        }
      },
      {title: '创建时间', dataIndex: 'create_time'},
      {
        title: '操作', render: (txt, record) => {
          return (<div>
            <a href="Javascript:void(0);" onClick={() => this.handleEdit(record)} rel="noreferrer">编辑</a>
          </div>);
        }
      }
    ];

    return (
      <div>
        <Add reloadLists={this.getLists} onRef={this.onRef}/>
        <Row gutter={16}>
          <Col span={3}>
            <List
              className="sub-category"
              bordered
              dataSource={this.state.sub_category}
              renderItem={item => {
                return (<List.Item onClick={() => this.changeCategory(item.cate_id)}
                                   className={item.cate_id === this.state.activeCid ? 'active' : ''}>
                  {item.name}
                </List.Item>)
              }}
            />
          </Col>
          <Col span={21}>
            <div className="lists-toolbar">
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <FormItem>
                  <Button icon="plus" onClick={this.handleAdd}>添加</Button>
                </FormItem>
                <FormItem>
                  <Button icon="delete"
                          disabled={this.state.selectedRowKeys.length <= 0}
                          onClick={this.deleteItems} type="danger">删除</Button>
                </FormItem>
                <FormItem>
                  <Button
                    onClick={this.updateSort}
                    disabled={!this.state.hasUpdateSort}
                    icon="upload">更新排序</Button>
                </FormItem>
              </Form>
            </div>
            <Table
              rowKey={record => record.skin_id}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={this.state.lists}
              bordered
              loading={this.state.loading}
            />
          </Col>
        </Row>
        <Modal
          title="查看原图"
          width="90%"
          footer={null}
          style={{top: 20}}
          onCancel={this.handleCancel}
          visible={this.state.visible}
        >
          <img src={this.state.originImg} width="100%" alt=""/>
        </Modal>
      </div>
    );
  }
}

export default Index
