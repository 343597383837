import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Upload } from "antd";
import { ADD_SPECIAL, GET_SPECIAL_DETAIL } from "@/api/cool";

const FormItem = Form.Item;

class Index extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        key: "",
        image: "",
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible && prevProps.visible !== this.props.visible) {
            if (this.props.id) {
                GET_SPECIAL_DETAIL(this.props.id).then((rsp) => {
                    const { title, icon, color, image, key, domain } = rsp.data;

                    this.setState({
                        key,
                        image,
                    });
                    this.formRef.current.setFieldsValue({
                        title,
                        icon,
                        color,
                        domain,
                    });
                });
            } else {
                this.formRef.current.resetFields();
            }
        }
    }

    // 点击弹框确定按钮
    handleOk = () => {
        this.formRef.current.submit();
    };

    // 提交保存数据
    handleSubmit = (values) => {
        const { title, icon, color, domain } = values;
        this.setState({ loading: true });
        ADD_SPECIAL(this.props.id, title, icon, color, this.state.key, domain)
            .then((rsp) => {
                if (rsp.data.code === 1) {
                    this.props.onReload();
                    this.props.onClose();
                } else {
                    message.error(rsp.data.msg);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    // 关闭弹框
    handleCancel = () => {
        this.props.onClose();
    };

    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };

        const upload_props = {
            name: "UploadCommon[imageFiles]",
            action: "/api/special/upload",
            multiple: false,
            showUploadList: false,
            onChange: (info) => {
                if (info.file.status !== "uploading") {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === "done") {
                    if (info.file.response.code === 1) {
                        this.setState({
                            image: info.file.response.image,
                            key: info.file.response.key,
                        });
                        message.success(`上传成功`);
                    } else {
                        message.success(info.file.response.msg);
                    }
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <Modal
                    title="添加专题"
                    cancelText="关闭"
                    okText="立即保存"
                    open={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    confirmLoading={this.state.loading}
                >
                    <Form {...layout} ref={this.formRef} onFinish={this.handleSubmit}>
                        <FormItem
                            name="title"
                            label="专题名称"
                            rules={[
                                { required: true, message: "必须填写名称" },
                                { max: 50, message: "名称不能超过50个字" },
                            ]}
                        >
                            <Input placeholder="专题名称" />
                        </FormItem>
                        <FormItem name="domain" label="目录名">
                            <Input placeholder="目录名" />
                        </FormItem>
                        <FormItem name="icon" label="Iconfont">
                            <Input placeholder="iconfont图标" />
                        </FormItem>
                        <FormItem name="color" label="配色">
                            <Input placeholder="配色" />
                        </FormItem>
                        <FormItem label="封面图">
                            <div>
                                <Upload {...upload_props}>
                                    <Button>上传封面图</Button>
                                </Upload>
                                {this.state.image ? (
                                    <div className="mt-15">
                                        <img width="280" src={this.state.image} alt="" />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Index;
